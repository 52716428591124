.animatedCanvas {
  background-color: transparent;
  box-sizing: content-box;
  cursor: pointer;
  left: 50%;
  transform-origin: center;
  position: relative;
  transform-style: preserve-3d;
}
.envelope {
  overflow-y: hidden;
  position: absolute;
  top: 0;
}
.back {
  background-color: #F1EAE2;
  box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.1);
  transition: box-shadow 0.3s ease;
}
.front {
  z-index: 5;
}
.flapWrapper {
  position: absolute;
  top: 0;
  z-index: 6;
}
.flap {
  transform-origin: top;
  pointer-events: none;
}
.cardWrapper {
  position: absolute;
  overflow: hidden;
  z-index: 3;
}
.cardElement {
  background-color: #ffffff;
  box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.1);
  box-sizing: content-box;
  left: 50%;
  transform-origin: center;
  position: absolute;
  top: 12px;
}
.clickToOpen {
  font-size: 14px;
  position: absolute;
  z-index: 7;
  left: 50%;
  top: 45%;
  transform: translateX(-50%);
}
.hearts {
  position: absolute;
  left: 0;
  right: 0;
  z-index: 4;
}
.heart {
  position: absolute;
  bottom: 0;
  right: 10%;
  pointer-events: none;
  opacity: 0;
}
.heart:before,
.heart:after {
  position: absolute;
  content: "";
  left: 50px;
  top: 0;
  width: 50px;
  height: 80px;
  background: #D00000;
  border-radius: 50px 50px 0 0;
  transform: rotate(-45deg);
  transform-origin: 0 100%;
  pointer-events: none;
}
.heart:after {
  left: 0;
  transform: rotate(45deg);
  transform-origin: 100% 100%;
}
.close .heart {
  opacity: 0;
  animation: none;
}
.hover .clickToOpen {
  color: #0075ae;
}
.hover .back {
  box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.3);
  transition: box-shadow 0.3s ease;
}
.open {
  pointer-events: none;
}
.open .envelope {
  transform: translateY(300%);
  transition: transform 2s ease-out 0.7s;
}
.open .flap {
  transform: rotateX(180deg);
  transition: transform 0.5s ease-in-out;
  perspective: 800px;
}
.open .flapWrapper {
  transform: translateY(300%);
  z-index: 1;
  transition: transform 2s ease-out 0.7s, z-index 0s ease 0.4s;
}
.open .cardElement {
  top: 0;
  transition: top 1s ease 0.7s;
}
.open .clickToOpen {
  opacity: 0;
  transition: opacity 0.2s ease;
}
.open .heart {
  opacity: 1;
}
.open .heart1 {
  left: 20%;
  transform: scale(0.6);
  opacity: 1;
  animation: slideUp 2s linear 1, sideSway 1s ease-in-out 4 alternate;
  animation-fill-mode: forwards;
  animation-delay: 0.2s;
}
.open .heart2 {
  left: 55%;
  transform: scale(1);
  opacity: 1;
  animation: slideUp 3s linear 1, sideSway 2s ease-in-out 2 alternate;
  animation-fill-mode: forwards;
  animation-delay: 0.2s;
}
.open .heart3 {
  left: 10%;
  transform: scale(0.8);
  opacity: 1;
  animation: slideUp 4s linear 1, sideSway 1s ease-in-out 6 alternate;
  animation-fill-mode: forwards;
  animation-delay: 0.2s;
}
@keyframes slideUp {
  0% {
    top: 0;
  }
  100% {
    top: -1000px;
  }
}
@keyframes sideSway {
  0% {
    margin-left: 0px;
  }
  100% {
    margin-left: 50px;
  }
}
