.letterpressTag {
  background-color: #f5f9fc !important;
}
.letterpressTag > div {
  background-color: #0075ae;
  color: transparent;
  -webkit-background-clip: text;
  -moz-background-clip: text;
  background-clip: text;
  text-shadow: -0.5px 0.5px 1px rgba(255, 255, 255, 0.7);
}
:global(.plpRedesignCardListing) {
  /* TODO: Remove this once zola-ui is updated. Current zola tag styles are overwritten so we'll overwrite them again for now until the actual zola-ui update is in place */
}
:global(.plpRedesignCardListing) .letterpressTag {
  background-color: #55a1bf33 !important;
  box-shadow: none;
  padding: 0 8px;
}
:global(.plpRedesignCardListing) .letterpressTag > div {
  color: #55a1bf;
  font-size: 12px;
  font-weight: 400;
  line-height: 20px;
  text-shadow: none;
}
