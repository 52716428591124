.bannerPromoModule {
  align-items: center;
  background-color: #c5e3ea;
  bottom: 0;
  display: flex;
  flex-direction: row;
  float: none;
  justify-content: center;
  min-width: 100%;
  padding: 9px;
  position: absolute;
  transform: translateY(100%);
  z-index: 10;
}
.bannerPromoModule a {
  color: #000;
  font-family: 'circular', serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 1.38;
  text-decoration: none;
}
@media screen and (max-width: 767px) {
  .bannerPromoModule a {
    font-size: 14px;
  }
}
.bannerPromoModuleIsRedesign {
  background-color: #733658;
}
.bannerPromoModuleIsRedesign a,
.bannerPromoModuleIsRedesign a:hover {
  color: #fff;
}
