.dropdown {
  display: flex;
  font-size: 16px;
  margin-left: 8px;
}
.dropdown :global(.input-field__container) {
  flex-grow: 1;
}
.sortByDropdown {
  width: 280px;
}
.sortByDropdown :global(.dropdown-v2__selection-box-label) {
  line-height: 22px;
}
