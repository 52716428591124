/* stylelint-disable selector-pseudo-class-no-unknown, declaration-no-important */
.listingFilterContainer {
  padding-top: 13px;
}
@media screen and (max-width: 991px) {
  .listingFilterContainer {
    padding-top: 0;
  }
}
.stickyFilters {
  height: 0 !important;
}
.stickyFiltersContent {
  display: flex;
  justify-content: center;
  padding-bottom: 20px;
}
.topFiltersActive :global(.sticky-inner-wrapper) {
  transition: all 300ms linear;
}
.filterBy {
  color: #21201f;
  font-weight: 600;
  margin-bottom: 8px;
}
.filterBar {
  align-items: center;
  display: flex;
}
.filterContainer {
  align-items: center;
  display: flex;
  margin-left: -4px;
}
.listingFilter {
  padding: 16px 0 8px 0;
}
@media screen and (max-width: 991px) {
  .listingFilter {
    padding: 16px 0 12px 0;
  }
}
.listingFilterActive {
  background-color: #f8f8f8;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.1);
  margin-top: -30px;
  padding-top: 46px;
}
.listingFilterHidden {
  display: none;
}
.multiSampleBox {
  display: flex;
  justify-content: flex-end;
  min-width: 260px;
}
.rightPushedContainer {
  display: flex;
  flex: 1 0 auto;
  justify-content: flex-end;
}
.viewDrafts {
  border: 2px solid #d9d9d9 !important;
  box-shadow: 0 0 8px rgba(0, 59, 88, 0.1) !important;
}
.filtersList {
  display: flex;
  margin-left: -4px;
}
.filtersList .maxSamplesHint {
  margin: auto 0;
}
.showTags {
  color: #0075ae;
  cursor: pointer;
  font-family: 'circular', serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 20px;
  margin: 0 6px 8px 6px;
  padding: 0 4px;
}
.showTags:hover {
  text-decoration: underline;
}
@media (max-width: 767px) {
  .tagContainerMobileReset {
    padding: 0 !important;
  }
}
.plpRedesignListingFilterContainer {
  padding-top: 0;
}
@media (max-width: 991px) {
  .plpRedesignListingFilterContainer {
    margin-bottom: 16px;
  }
}
.plpRedesignListingFilterContainer .filterContainer {
  flex-wrap: wrap;
  width: 100%;
}
.plpRedesignListingFilterContainer .filterContainerWithToggle {
  width: calc(100% - 435px);
}
@media (max-width: 991px) {
  .plpRedesignListingFilterContainer .filterContainerWithToggle {
    flex-wrap: nowrap;
    width: calc(100% - 260px);
  }
}
@media (max-width: 767px) {
  .plpRedesignListingFilterContainer .filterContainerWithToggle {
    width: 100%;
  }
}
.plpRedesignListingFilterContainer .filterBy {
  width: 100%;
}
.plpRedesignListingFilterContainer .filterBar {
  align-items: flex-start;
}
@media (max-width: 991px) {
  .plpRedesignListingFilterContainer .filterBar {
    align-items: center;
    overflow-x: auto;
  }
}
@media (max-width: 767px) {
  .plpRedesignListingFilterContainer .filterBar {
    flex-direction: column;
  }
}
.plpRedesignListingFilterContainer .viewDrafts {
  margin-top: 34px;
}
@media (max-width: 991px) {
  .plpRedesignListingFilterContainer .viewDrafts {
    margin-top: 12px;
  }
}
.plpRedesignListingFilterContainer div[class*='sortByDropdown'] {
  align-items: flex-start;
  flex-direction: column;
  justify-content: center;
  min-width: 165px;
  width: fit-content;
}
.plpRedesignListingFilterContainer div[class*='sortByDropdown'] label {
  margin-bottom: 8px !important;
}
@media (max-width: 991px) {
  .plpRedesignListingFilterContainer div[class*='sortByDropdown'] label {
    display: none;
  }
}
.plpRedesignListingFilterContainer div[class*='sortByDropdown'] :global(.input-field__container) {
  min-width: 165px;
}
.plpRedesignListingFilterContainer .listingFilter,
.plpRedesignListingFilterContainer .plpRedesignFilterTagsContainer {
  padding: 0 32px;
}
@media (max-width: 991px) {
  .plpRedesignListingFilterContainer .listingFilter,
  .plpRedesignListingFilterContainer .plpRedesignFilterTagsContainer {
    padding: 16px 10px 6px;
  }
}
@media (max-width: 767px) {
  .plpRedesignListingFilterContainer .listingFilter,
  .plpRedesignListingFilterContainer .plpRedesignFilterTagsContainer {
    padding: 16px 0 6px;
  }
}
.plpRedesignListingFilterContainer .listingFilterActive {
  padding: 46px 16px 4px !important;
}
.plpRedesignListingFilterContainer .listingFilterActive div[class*='sortByDropdown'] label {
  display: none;
}
.plpRedesignListingFilterContainer .listingFilterActive .filterContainer {
  max-width: 100%;
  overflow-x: auto;
}
.plpRedesignListingFilterContainer .listingFilterActive .filterContaineWithToggler {
  max-width: calc(100% - 435px);
}
@media (max-width: 991px) {
  .plpRedesignListingFilterContainer .listingFilterActive .filterContaineWithToggler {
    max-width: calc(100% - 260px);
  }
}
@media (max-width: 767px) {
  .plpRedesignListingFilterContainer .listingFilterActive .filterContaineWithToggler {
    max-width: 100%;
  }
}
.plpRedesignListingFilterContainer .rightPushedContainer {
  align-items: flex-end;
}
@media (max-width: 767px) {
  .plpRedesignListingFilterContainer .rightPushedContainer {
    width: 100%;
  }
}
.plpRedesignListingFilterContainer .multiSampleBox {
  margin-bottom: 8px;
}
@media (max-width: 991px) {
  .plpRedesignListingFilterContainer .multiSampleBox {
    margin-bottom: 6px;
  }
}
@media (max-width: 767px) {
  .plpRedesignListingFilterContainer .multiSampleBox {
    flex-wrap: wrap;
    margin-top: 14px;
  }
  .plpRedesignListingFilterContainer .multiSampleBox:not(.multiSampleBoxSticky) {
    width: 100%;
  }
}
.plpRedesignListingFilterContainer .multiSampleBoxSticky {
  margin-bottom: 10px;
}
@media (max-width: 767px) {
  .plpRedesignListingFilterContainer .multiSampleBoxSticky {
    flex-wrap: nowrap;
    margin-top: 4px;
  }
}
.plpRedesignListingFilterContainer .filterBarWithSampleSelection .filterContainer {
  width: calc(100% - 170px);
}
@media (max-width: 991px) {
  .plpRedesignListingFilterContainer .filterBarWithSampleSelection {
    align-items: center;
  }
  .plpRedesignListingFilterContainer .filterBarWithSampleSelection .filterContainer {
    width: 100%;
  }
}
.multiSampleEnabledRow {
  align-items: center;
  display: flex;
  justify-content: flex-end;
  padding: 0 20px;
}
@media (min-width: 992px) {
  .multiSampleEnabledRow {
    padding: 0 40px;
  }
}
@media (min-width: 768px) {
  .multiSampleEnabledRow {
    padding-bottom: 20px;
  }
}
