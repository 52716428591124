.hoverOptionsWrapper {
  background-color: rgba(255, 255, 255, 0.5);
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: flex-end;
  left: 0;
  padding: 20px 0;
  position: absolute;
  right: 0;
  top: 0;
}
.hoverOptionsWrapper :global(.v2-button) {
  width: 100%;
}
.hoverOptionsCTA {
  margin-bottom: 8px;
}
