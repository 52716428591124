.container {
  background-repeat: no-repeat;
  background-size: cover;
  box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  padding: 28px;
  margin-bottom: 90px;
  display: flex;
  justify-content: center;
}
.bgBlue {
  background-image: url('../../../../../../../assets/images/invitations/single-sample-modal/bg-wave-blue.svg');
}
.bgPink {
  background-image: url('../../../../../../../assets/images/invitations/single-sample-modal/bg-wave-pink.svg');
}
.bgPlum {
  background: #733658;
}
.bgPlum :global button.link {
  color: #ffffff !important;
}
.bgPlum .title {
  color: #ffffff;
}
.bgPlum .subtitle {
  color: #ffffff;
}
.bgPlum .ctaLink {
  color: #ffffff;
}
.bgLightBlue {
  background-image: url('../../../../../../../assets/images/invitations/single-sample-modal/bg-wave-light-blue.svg');
}
.containerResponsiveStyling {
  margin-bottom: 28px;
}
.containerResponsiveStyling .innerContainer {
  display: flex;
  flex-direction: column-reverse;
  align-items: center;
}
.containerResponsiveStyling .textContainer {
  display: flex;
  flex-direction: column;
  margin-right: 0;
}
.containerResponsiveStyling .colorPickerContainer {
  margin-bottom: 70px;
}
.containerResponsiveStyling .colorPickerContainer::after {
  background-image: url('../../../../../../../assets/images/invitations/single-sample-modal/ap-edit-web-arrow-short.svg');
  height: 55px;
  left: -70px;
  top: 60%;
}
.containerResponsiveStyling .colorPickerContainer--1 {
  margin-bottom: 32px;
}
.containerResponsiveStyling .colorPickerBottomSpacing {
  margin-bottom: 20px;
}
@media (min-width: 992px) and (max-width: 1199px) {
  .containerResponsiveStylingDesktop {
    margin-bottom: 28px;
  }
  .containerResponsiveStylingDesktop .innerContainer {
    display: flex;
    flex-direction: column-reverse;
    align-items: center;
  }
  .containerResponsiveStylingDesktop .textContainer {
    display: flex;
    flex-direction: column;
    margin-right: 0;
  }
  .containerResponsiveStylingDesktop .colorPickerContainer {
    margin-bottom: 70px;
  }
  .containerResponsiveStylingDesktop .colorPickerContainer::after {
    background-image: url('../../../../../../../assets/images/invitations/single-sample-modal/ap-edit-web-arrow-short.svg');
    height: 55px;
    left: -70px;
    top: 60%;
  }
  .containerResponsiveStylingDesktop .colorPickerContainer--1 {
    margin-bottom: 32px;
  }
  .containerResponsiveStylingDesktop .colorPickerBottomSpacing {
    margin-bottom: 20px;
  }
}
@media (min-width: 992px) and (max-width: 1270px) {
  .containerResponsiveStylingDesktop {
    margin-bottom: 28px;
  }
  .containerResponsiveStylingDesktop .innerContainer {
    display: flex;
    flex-direction: column-reverse;
    align-items: center;
  }
  .containerResponsiveStylingDesktop .textContainer {
    display: flex;
    flex-direction: column;
    margin-right: 0;
  }
  .containerResponsiveStylingDesktop .colorPickerContainer {
    margin-bottom: 70px;
  }
  .containerResponsiveStylingDesktop .colorPickerContainer::after {
    background-image: url('../../../../../../../assets/images/invitations/single-sample-modal/ap-edit-web-arrow-short.svg');
    height: 55px;
    left: -70px;
    top: 60%;
  }
  .containerResponsiveStylingDesktop .colorPickerContainer--1 {
    margin-bottom: 32px;
  }
  .containerResponsiveStylingDesktop .colorPickerBottomSpacing {
    margin-bottom: 20px;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .containerResponsiveStylingTablet {
    margin-bottom: 28px;
  }
  .containerResponsiveStylingTablet .innerContainer {
    display: flex;
    flex-direction: column-reverse;
    align-items: center;
  }
  .containerResponsiveStylingTablet .textContainer {
    display: flex;
    flex-direction: column;
    margin-right: 0;
  }
  .containerResponsiveStylingTablet .colorPickerContainer {
    margin-bottom: 70px;
  }
  .containerResponsiveStylingTablet .colorPickerContainer::after {
    background-image: url('../../../../../../../assets/images/invitations/single-sample-modal/ap-edit-web-arrow-short.svg');
    height: 55px;
    left: -70px;
    top: 60%;
  }
  .containerResponsiveStylingTablet .colorPickerContainer--1 {
    margin-bottom: 32px;
  }
  .containerResponsiveStylingTablet .colorPickerBottomSpacing {
    margin-bottom: 20px;
  }
}
@media (max-width: 480px) {
  .containerResponsiveStylingMobile {
    margin-bottom: 28px;
  }
  .containerResponsiveStylingMobile .innerContainer {
    display: flex;
    flex-direction: column-reverse;
    align-items: center;
  }
  .containerResponsiveStylingMobile .textContainer {
    display: flex;
    flex-direction: column;
    margin-right: 0;
  }
  .containerResponsiveStylingMobile .colorPickerContainer {
    margin-bottom: 70px;
  }
  .containerResponsiveStylingMobile .colorPickerContainer::after {
    background-image: url('../../../../../../../assets/images/invitations/single-sample-modal/ap-edit-web-arrow-short.svg');
    height: 55px;
    left: -70px;
    top: 60%;
  }
  .containerResponsiveStylingMobile .colorPickerContainer--1 {
    margin-bottom: 32px;
  }
  .containerResponsiveStylingMobile .colorPickerBottomSpacing {
    margin-bottom: 20px;
  }
}
.innerContainer {
  display: flex;
}
.textContainer {
  margin-right: 44px;
  align-self: center;
  max-width: 364px;
}
.title {
  font-family: 'new-spirit', serif;
  font-weight: 500;
  font-size: 24px;
  line-height: 28px;
  margin: 0;
}
.subtitle {
  font-size: 16px;
  line-height: 24px;
  color: #505050;
  margin: 16px 0;
}
.samplePreviewCustomContainer {
  position: relative;
  z-index: 0;
}
.matchesTag {
  position: absolute;
  z-index: 10;
  left: -20px;
  white-space: nowrap;
}
.apEditWebsitePreviewContainer {
  align-items: center;
  display: flex;
  flex-direction: column;
}
.colorPickerTopSpacing {
  margin-top: 16px;
}
.colorPickerBottomSpacing {
  margin-bottom: 0;
}
.colorPickerContainer {
  position: relative;
  display: flex;
}
.colorPickerContainer::after {
  content: ' ';
  position: absolute;
  background-image: url('../../../../../../../assets/images/invitations/single-sample-modal/ap-edit-web-arrow.svg');
  background-repeat: no-repeat;
  background-size: contain;
  width: 190px;
  height: 90px;
  display: block;
  top: 44px;
  left: -70px;
}
.colorPickerContainer--1::after {
  content: none;
}
.colorPickerContainer--2::after {
  left: -90px;
}
.qrCodeBubble {
  position: absolute;
  z-index: 10;
  top: 8px;
  right: -24px;
}
.arrowAsset {
  position: absolute;
  top: 42px;
  right: 14px;
}
