.item {
  align-items: center;
  border-bottom: 1px solid #d9d9d9;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 12px 0;
}
.itemTitle {
  color: #21201f;
  font-family: 'circular', serif;
  font-size: 20px;
  font-weight: 600;
  line-height: 1.42857143;
  margin-left: 12px;
}
.itemToggleWrapper {
  justify-self: flex-end;
}
