.container {
  margin: 0;
  display: flex;
  flex-direction: column;
  padding: 40px;
}
.circleIcon {
  align-self: flex-start;
}
.body {
  text-align: left;
  font-weight: 600;
  line-height: 28px;
  margin-top: 32px;
  margin-bottom: 40px;
}
.buttonContainer {
  text-align: right;
}
