.suites {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
}
@media (max-width: 767px) {
  .suites {
    padding: 0;
  }
}
.loaderContainer {
  align-content: flex-start;
  display: flex;
  flex-wrap: wrap;
  min-height: calc(100vh - 164px);
  position: relative;
}
@media (max-width: 480px) {
  .loaderContainer {
    min-height: calc(100vh - 142px);
  }
}
.suitesActions {
  display: flex;
  align-items: center;
  justify-content: center;
}
.divider {
  background-color: #d9d9d9;
  height: 2px;
  margin-bottom: 20px;
  width: 100%;
}
.blueBg {
  background-color: #F2F5FB;
}
.lowercase {
  text-transform: none !important;
}
.loaderAnchor {
  align-content: flex-start;
  background-color: #fff;
  display: block;
  flex-wrap: wrap;
  min-height: calc(100vh - 164px);
  position: relative;
}
@media (max-width: 480px) {
  .loaderAnchor {
    min-height: calc(100vh - 142px);
  }
}
.loaderContainer {
  align-items: center;
  bottom: -2px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  left: 0;
  position: absolute;
  right: 0;
  top: -16px;
  z-index: 2;
}
.cardListingFilterContainer {
  min-height: 140px;
}
@media (max-width: 991px) {
  .cardListingFilterContainer {
    min-height: 100px;
  }
}
