.container {
  display: flex;
  margin-bottom: 60px;
}
.loadingContainer {
  height: 100vh;
}
.column {
  flex: 1 0 50%;
}
.column:first-child {
  padding-right: 10px;
}
@media (min-width: 1200px) {
  .column:first-child {
    padding-right: 55px;
  }
}
@media (min-width: 1440px) {
  .column:first-child {
    padding-right: 65px;
  }
}
.column:last-child {
  padding-left: 10px;
}
@media (min-width: 1200px) {
  .column:last-child {
    padding-left: 55px;
  }
}
@media (min-width: 1440px) {
  .column:last-child {
    padding-left: 65px;
  }
}
.title {
  font-family: 'circular', serif;
  font-size: 24px;
  font-weight: 600;
  margin-bottom: 20px;
  margin-top: 0;
}
.fieldMarginBottom {
  margin-bottom: 20px;
}
.img {
  width: 100%;
}
.back {
  display: block;
  margin-top: 20px;
}
.attireFieldGroup > label {
  width: 100%;
}
.attireFieldGroup :global(.label__text) {
  display: flex;
  justify-content: space-between;
  width: 100%;
}
