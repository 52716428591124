@media screen and (max-width: 767px) {
}
@media (min-width: 768px) {
}
@media (min-width: 768px) {
}
@media (max-width: 767px) {
}
@media (min-width: 768px) {
}
@media (min-width: 768px) {
}
@media (max-width: 767px) {
}
@media (max-width: 767px) {
}
.heroWrapper {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
  margin-top: 20px;
}
@media screen and (max-width: 991px) {
  .heroWrapper {
    display: block;
    margin-bottom: 32px;
  }
}
.heroTile {
  flex-grow: 1;
  position: relative;
}
@media screen and (max-width: 767px) {
  .heroTile {
    position: static;
  }
}
.heroImgContainer,
.heroImgContainerWide {
  border-radius: 8px;
  height: 280px;
  max-width: 862px;
}
@media screen and (max-width: 991px) {
  .heroImgContainer,
  .heroImgContainerWide {
    margin-bottom: 20px;
  }
}
@media screen and (max-width: 767px) {
  .heroImgContainer,
  .heroImgContainerWide {
    height: 180px;
    margin-bottom: 0;
  }
}
.heroImgContainerWide {
  background: #005759;
  height: 300px;
  max-width: none;
  position: relative;
}
@media screen and (max-width: 767px) {
  .heroImgContainerWide {
    height: 458px;
  }
}
.heroImg {
  background-size: cover;
  border-radius: 8px;
  object-fit: cover;
  object-position: left top;
}
@media screen and (min-width: 992px) and (max-width: 1199px) {
  .heroImg:not(.heroImgIsLeftAligned) {
    object-position: -50px top;
  }
}
.heroImg.heroImgDigital {
  object-position: center center;
}
.heroContent,
.heroContentWide {
  left: 0;
  padding: 48px 30px;
  position: absolute;
  top: 0;
}
@media screen and (max-width: 767px) {
  .heroContent,
  .heroContentWide {
    padding: 20px 0;
    position: static;
  }
}
.heroContentWide {
  bottom: 0;
  right: 0;
  text-align: center;
}
@media screen and (max-width: 991px) {
  .heroContentWide {
    padding: 65px 30px 48px;
  }
}
@media screen and (max-width: 767px) {
  .heroContentWide {
    left: 21px;
    padding: 129px 0 0;
    position: absolute;
    right: 21px;
  }
}
.heroPreTitle {
  color: #d7f5d9;
  display: block;
  font-family: 'Circular', sans-serif;
  font-size: 36px;
  font-weight: 500;
  line-height: 43px;
  margin: 0 0 7px;
}
@media screen and (max-width: 991px) {
  .heroPreTitle {
    font-size: 28px;
  }
}
@media screen and (max-width: 767px) {
  .heroPreTitle {
    font-size: 26px;
    line-height: 32px;
  }
}
.heroTitle {
  color: #21201f;
  line-height: 36px;
  margin-bottom: 12px;
}
.heroTitleIsSale {
  color: #d7f5d9;
  font-size: 55px;
  font-weight: 400;
  line-height: 64px;
}
.heroTitleIsSale strong sup {
  font-size: 35px;
  font-weight: 400;
}
@media screen and (max-width: 767px) {
  .heroTitleIsSale strong sup {
    font-size: 25px;
  }
}
@media screen and (max-width: 991px) {
  .heroTitleIsSale {
    font-size: 45px;
    line-height: 53px;
    margin: 5px auto 12px;
  }
}
@media screen and (max-width: 767px) {
  .heroTitleIsSale {
    font-size: 41px;
    line-height: 53px;
  }
  .heroTitleIsSale strong {
    display: block;
  }
}
.heroTitleIsSaleIsSmaller {
  display: block;
  font-size: 45px;
  line-height: 56px;
  margin: 17px 0 0 0;
}
@media screen and (max-width: 991px) {
  .heroTitleIsSaleIsSmaller {
    font-size: 34px;
    line-height: 42px;
  }
}
@media screen and (max-width: 767px) {
  .heroTitleIsSaleIsSmaller {
    margin: 20px 0 0 0;
  }
  .heroTitleIsSaleIsSmaller span {
    display: block;
  }
  .heroTitleIsSaleIsSmaller strong {
    display: inline;
  }
}
.heroText {
  max-width: 360px;
}
.heroListItem {
  display: flex;
  line-height: 16px;
  margin-bottom: 8px;
}
.heroListText {
  line-height: 24px;
  margin-left: 8px;
}
.heroCTA {
  margin-top: 0;
}
.heroTextContentIsSale,
.heroLegalContentIsSale {
  color: #d7f5d9;
  font-size: 28px;
  font-weight: 400;
  line-height: 30px;
  margin: 0 auto;
}
@media screen and (max-width: 767px) {
  .heroTextContentIsSale,
  .heroLegalContentIsSale {
    font-size: 24px;
    max-width: 260px;
  }
}
.heroLegalContentIsSale {
  bottom: 10px;
  font-family: 'Circular', sans-serif;
  font-size: 12px;
  left: 10px;
  line-height: 17px;
  opacity: 0.7;
  position: absolute;
  right: 10px;
}
@media screen and (max-width: 991px) {
  .heroLegalContentIsSale {
    font-size: 11px;
    line-height: 16px;
    max-width: none;
  }
}
@media screen and (max-width: 767px) {
  .heroLegalContentIsSale {
    font-size: 10px;
    line-height: 15px;
    max-width: none;
  }
}
.heroBox {
  overflow: hidden;
  position: relative;
}
.heroTextBox {
  left: 6%;
  position: absolute;
  top: 50%;
  transform: translate(0, -50%);
  width: 100%;
  z-index: 1;
}
@media (min-width: 767px) and (max-width: 768px) {
  .heroTextBox {
    left: 50%;
    margin: 0;
    text-align: center;
    top: 6%;
    -webkit-transform: translate(-50%, 0);
    -ms-transform: translate(-50%, 0);
    -o-transform: translate(-50%, 0);
    transform: translate(-50%, 0);
  }
}
@media (min-width: 480px) and (max-width: 767px) {
  .heroTextBox {
    left: 50%;
    margin: 0 auto;
    text-align: center;
    top: 15%;
    -webkit-transform: translate(-50%, 0);
    -ms-transform: translate(-50%, 0);
    -o-transform: translate(-50%, 0);
    transform: translate(-50%, 0);
  }
}
@media (max-width: 480px) {
  .heroTextBox {
    left: 50%;
    margin: 0 auto;
    text-align: center;
    top: 9%;
    -webkit-transform: translate(-50%, 0);
    -ms-transform: translate(-50%, 0);
    -o-transform: translate(-50%, 0);
    transform: translate(-50%, 0);
  }
}
.heroHeadline {
  color: #21201f;
  font-family: Georgia, "Times New Roman", Times, serif;
  font-size: 32px;
  font-weight: 500;
  margin: 0;
}
@media (max-width: 991px) {
  .heroHeadline {
    font-size: 40px;
    margin: 0 auto;
  }
}
.heroDescription {
  color: #21201f;
  font-family: 'circular', serif;
  font-size: 44px;
  font-weight: 400;
  margin-top: 10px;
}
@media (min-width: 480px) and (max-width: 991px) {
  .heroDescription {
    font-size: 40px;
  }
}
@media (max-width: 480px) {
  .heroDescription {
    font-size: 32px;
  }
}
.heroP {
  color: #21201f;
  font-family: 'circular', serif;
  font-size: 20px;
  font-weight: 400;
  margin: 0;
}
@media (max-width: 768px) {
  .heroP {
    margin: 0 auto;
  }
}
@media (max-width: 480px) {
  .heroP {
    max-width: 75%;
  }
}
@media (min-width: 480px) {
  .heroP {
    width: 420px;
  }
}
.checkmarkIcon {
  color: #01b4c0;
  margin-top: 3px;
}
.heroSaleBadge {
  align-items: center;
  display: flex;
  height: 105px;
  justify-content: center;
  position: absolute;
  right: 30px;
  text-align: center;
  top: 0;
  width: 157px;
}
@media screen and (max-width: 991px) {
  .heroSaleBadge {
    height: 88px;
    width: 131px;
  }
}
@media (max-width: 480px) {
  .heroSaleBadge {
    height: 97px;
    width: 123px;
  }
}
.heroSaleBadgeImg {
  bottom: 0;
  left: 0;
  position: absolute;
  z-index: 0;
}
.heroSaleBadgeContent {
  color: #005759;
  font-family: 'Circular', sans-serif;
  font-size: 24px;
  font-weight: 700;
  line-height: 28px;
  margin: -30px 0 0 0;
  transform: rotate(-15deg);
  z-index: 1;
}
@media screen and (max-width: 767px) {
  .heroSaleBadgeContent {
    font-size: 18px;
    line-height: 23px;
    margin: -20px 0 0 0;
  }
}
