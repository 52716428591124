@media screen and (max-width: 767px) {
}
@media (min-width: 768px) {
}
@media (min-width: 768px) {
}
@media (max-width: 767px) {
}
@media (min-width: 768px) {
}
@media (min-width: 768px) {
}
@media (max-width: 767px) {
}
@media (max-width: 767px) {
}
.customization-preview {
  position: relative;
  margin: 0 auto;
  filter: drop-shadow(0px 4px 8px rgba(0, 0, 0, 0.5));
  will-change: filter;
}
.customization-preview .card {
  position: relative;
  background-color: white;
  transition: background-color 0.2s;
}
.customization-preview .card.no-print {
  background-color: white;
  display: flex;
  flex-direction: column;
  font-size: 16px;
  font-weight: 600;
  height: 100%;
  justify-content: center;
  text-align: center;
  width: 100%;
}
.customization-preview .card.rounded {
  border-radius: 52px;
}
.customization-preview .card.semi-disable {
  opacity: 0.4;
}
.customization-preview .card.box-shadow {
  -webkit-box-shadow: 0px 2px 10px 0px #d9d9d9;
  box-shadow: 0px 2px 10px 0px #d9d9d9;
}
@media (max-width: 992px) {
  .customization-container--recipients-disabled .customization-preview .card.envelope {
    margin-top: 0;
  }
}
.customization-preview .card.shimmer-silver {
  background: transparent url('../../../../assets/images/invitations/envelopes/shimmer-silver-overlay.jpg') no-repeat 0 0 / cover;
}
.customization-preview .card.shimmer-gold {
  background: transparent url('../../../../assets/images/invitations/envelopes/shimmer-gold-overlay.jpg') no-repeat 0 0 / cover;
}
.customization-preview .card.hover-outline .text.outline-on-hover:not(.text-active) {
  /* stylelint-disable-next-line declaration-no-important */
  border: 1px dashed transparent !important;
}
.customization-preview .card.hover-outline:hover .text-layer:not(.text-layer--blurred) .text.outline-on-hover:not(.text-active) {
  /* stylelint-disable-next-line declaration-no-important */
  border: 1px dashed #d9d9d9 !important;
}
.customization-preview .no-print-details {
  background-color: #f8f8f8;
  border-radius: 4px;
  font-size: 14px;
  font-weight: 400;
  margin: 12px auto 0;
  padding: 32px 24px;
  width: 296px;
}
.customization-preview .rotate-svg {
  transform: rotate(90deg);
  transform-box: fill-box;
  transform-origin: center;
}
.customization-preview .card-label-container {
  background: #8c8b8b;
  color: #fff;
  display: inline-block;
  padding: 0 20px;
  z-index: 9999;
  position: absolute;
  left: 0%;
  bottom: 0%;
}
.customization-preview .image-layer-wrapper {
  overflow: hidden;
  position: relative;
}
.customization-preview .fold {
  background-color: #f7f7f7;
  background-image: linear-gradient(170deg, rgba(135, 135, 135, 0.15), rgba(135, 135, 135, 0.02));
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2);
  height: 407.4px;
  position: absolute;
  right: -10px;
  top: 0;
  transform: skewX(3deg);
  width: 30px;
}
.card-label-redesign {
  background-color: #8c8b8b;
  color: #fff;
  width: fit-content;
  padding: 4px 8px;
  margin: 20px 0 40px;
}
.addressing-container .actions-layer {
  position: absolute;
  width: 100%;
  text-align: center;
  bottom: 25%;
  z-index: 100;
}
.addressing-container .actions-layer .action.action--edit {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.addressing-container .actions-layer .action.action--edit .action__icon {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 30px;
  height: 30px;
  background-color: #0075ae;
  color: #fff;
  border-radius: 100%;
}
.addressing-container .actions-layer .action.action--edit .action__icon .glyphicon-pencil {
  top: 0;
}
.addressing-container .info-layer {
  position: absolute;
  width: 100%;
  top: 80%;
  bottom: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  z-index: 100;
}
.addressing-container .info-layer .info-component-container {
  max-width: 350px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.addressing-container .info-component {
  display: flex;
  justify-content: center;
  align-items: center;
}
.addressing-container .info-component.info-component--error {
  width: 100%;
  height: 100%;
  background-color: #fee2e5;
}
.addressing-container .info-component .info-icon {
  width: 15px;
  height: 15px;
  margin-right: 10px;
  border-radius: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.addressing-container .info-component .info-icon.info-icon--warning {
  background-color: #f9ca66;
  color: #fff;
}
.addressing-container .info-component .info-icon.info-icon--error {
  background-color: #c7133e;
  color: #fff;
}
.addressing-container .info-component .info-message {
  font-size: 16px;
  text-transform: uppercase;
  font-weight: 400;
  letter-spacing: 0.1em;
}
.addressing-container .info-component .info-message.info-message--warning {
  color: #505050;
}
.addressing-container .card.hoverable {
  border: 1px solid transparent;
}
.addressing-container .card.hoverable:hover {
  border: 1px solid #0075ae;
  cursor: pointer;
}
