.heroPromoTile {
  align-items: center;
  text-align: center;
  background-color: #733658;
  border-radius: 8px;
  color: #ffffff;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-left: 20px;
  min-width: 274px;
  padding: 24px;
  width: 274px;
}
@media screen and (max-width: 991px) {
  .heroPromoTile {
    margin-left: 0;
    width: 100%;
    padding: 20px;
  }
}
.heroPromoTitle {
  font-size: 32px;
  font-family: 'new-spirit', serif;
  font-weight: 500;
  line-height: 36px;
}
@media screen and (max-width: 991px) {
  .heroPromoTitle {
    font-size: 24px;
    line-height: 28px;
  }
}
:local .heroPromoSubtitle {
  margin-top: 20px;
}
@media screen and (max-width: 991px) {
  :local .heroPromoSubtitle {
    margin-top: 8px;
  }
}
:local .heroPromoSubtitle.noSpacing {
  margin-top: 0;
}
:local .heroPromoSubtitle a {
  color: #ffffff;
  font-weight: 700;
  text-decoration: underline;
}
:local .heroPromoSubtitle :global(span.heroPromoTitle) {
  font-size: 32px;
  font-family: 'new-spirit', serif;
  font-weight: 500;
  line-height: 36px;
}
@media screen and (max-width: 991px) {
  :local .heroPromoSubtitle :global(span.heroPromoTitle) {
    font-size: 24px;
    line-height: 28px;
  }
}
