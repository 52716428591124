/* stylelint-disable selector-pseudo-class-no-unknown, declaration-no-important, function-calc-no-invalid */
.filterBtnContainer {
  position: relative;
}
.filterBtnContainer a {
  text-decoration: none;
}
.filterBtn {
  border: 1px solid #b7b7b7;
  box-shadow: none;
  color: #0e0e0e;
  margin: 4px;
  white-space: nowrap;
}
.filterBtn:focus {
  outline: 0 !important;
}
@media screen and (max-width: 991px) {
  .filterBtn {
    width: calc(100% - 8px) !important;
  }
}
.filterBtnRedesign {
  margin: 4px;
  white-space: nowrap;
}
.filterBtnRedesign svg {
  margin-left: 10px;
}
@media screen and (max-width: 991px) {
  .filterBtnRedesign {
    width: calc(100% - 8px) !important;
  }
}
.filterBtnOpenMenu {
  background-color: #f3f3f3 !important;
}
.filterBtnActive {
  background-color: #f3f3f3 !important;
  border: 1px solid #0e0e0e !important;
  color: #0e0e0e !important;
}
@media (min-width: 768px) {
  .newBadge {
    padding: 10px 16px !important;
  }
}
.newBadge::after {
  background: #15845d;
  border-radius: 5px;
  color: #ffffff;
  content: 'New';
  font-size: 12px;
  font-weight: 600;
  height: 20px;
  line-height: 14px;
  margin-left: 8px;
  padding: 3px 7px;
  position: relative;
}
.caret {
  color: #0075ae;
  font-size: 14px;
  margin-left: 6px;
  width: 14px !important;
}
.dropdownBgOverlay {
  background-color: #21201f;
  bottom: 0;
  height: 100%;
  left: 0;
  opacity: 0.5;
  position: fixed;
  right: 0;
  top: 0;
  width: 100%;
  z-index: 10;
}
.dropdownContainer {
  background: #ffffff;
  border-radius: 4px;
  box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.1);
  font-size: 14px;
  left: 4px;
  margin-top: 0;
  position: absolute;
  width: 280px;
  z-index: 10;
}
.dropdownContainerWide {
  width: 400px;
}
.dropdownMobileHeader {
  font-size: 22px;
  height: 30px;
  position: relative;
}
.dropdownMobileClose {
  position: absolute;
  right: 4px;
  top: 4px;
}
