@media screen and (max-width: 767px) {
}
@media (min-width: 768px) {
}
@media (min-width: 768px) {
}
@media (max-width: 767px) {
}
@media (min-width: 768px) {
}
@media (min-width: 768px) {
}
@media (max-width: 767px) {
}
@media (max-width: 767px) {
}
.text-layer-container .text-element-border {
  border: 1px dashed #01b4c0;
  bottom: -4px;
  left: -4px;
  position: absolute;
  right: -4px;
  top: -4px;
}
.text-layer-container .text-element-border.text-element-border--dragging {
  background-color: rgba(1, 180, 192, 0.2);
}
.text-layer-container .text-element-border.text-element-border--TOP_RESIZE {
  border-top: 2px solid #336081;
}
.text-layer-container .text-element-border.text-element-border--LEFT_RESIZE {
  border-left: 2px solid #336081;
}
.text-layer-container .text-element-border.text-element-border--BOTTOM_RESIZE {
  border-bottom: 2px solid #336081;
}
.text-layer-container .text-element-border.text-element-border--RIGHT_RESIZE {
  border-right: 2px solid #336081;
}
.text-layer-container .text-element-border.text-element-border--TOP_LEFT_RESIZE {
  border-left: 2px solid #336081;
  border-top: 2px solid #336081;
}
.text-layer-container .text-element-border.text-element-border--TOP_RIGHT_RESIZE {
  border-right: 2px solid #336081;
  border-top: 2px solid #336081;
}
.text-layer-container .text-element-border.text-element-border--BOTTOM_LEFT_RESIZE {
  border-bottom: 2px solid #336081;
  border-left: 2px solid #336081;
}
.text-layer-container .text-element-border.text-element-border--BOTTOM_RIGHT_RESIZE {
  border-bottom: 2px solid #336081;
  border-right: 2px solid #336081;
}
.text-layer-container .element-drag-handle .zolaicon-move {
  max-width: none;
}
.text-layer-container .element-resize-handle {
  background-color: #34ccd3;
  border: 2px solid #336081;
  border-radius: 100%;
  font-size: 16px;
  height: 10px;
  line-height: normal;
  position: absolute;
  width: 10px;
  z-index: 1000;
}
.text-layer-container .element-resize-handle:hover,
.text-layer-container .element-resize-handle.element-resize-handle--active {
  background-color: white;
}
.text-layer-container .element-resize-handle.element-resize-handle--active {
  background-color: white;
}
.text-layer-container .text-layer.text-layer--error ~ .element-resize-handle {
  background-color: #f5bfc5;
  border-color: #c7133e;
}
.text-layer-container .text-layer.text-layer--error ~ .element-resize-handle:hover,
.text-layer-container .text-layer.text-layer--error ~ .element-resize-handle.element-resize-handle--active {
  background-color: white;
}
.text-layer-container .coordinate-line {
  display: flex;
  position: absolute;
  z-index: 10;
}
.text-layer-container .coordinate-line .coordinate-line__value {
  align-items: center;
  background-color: white;
  background-color: rgba(51, 96, 129, 0.55);
  color: white;
  display: flex;
  font-family: 'circular', serif;
  font-size: 12px;
  height: 15px;
  letter-spacing: normal;
  line-height: normal;
  padding-left: 3px;
  padding-right: 3px;
}
.text-layer-container .coordinate-line.coordinate-line--vertical {
  align-items: center;
  border-left: 1px solid #336081;
  width: 1px;
}
.text-layer-container .coordinate-line.coordinate-line--horizontal {
  border-bottom: 1px solid #336081;
  height: 1px;
  justify-content: center;
}
.text-layer-container .coordinate-line.coordinate-line--horizontal .coordinate-line__value {
  bottom: 0;
  position: absolute;
}
.text-layer {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  position: absolute;
  user-select: none;
  width: 100%;
  z-index: 2;
}
.text-layer:not(.text-layer--error):not(.text-layer--blurred):hover .text.text--editable {
  outline: 1px dashed #01b4c0;
}
.text-layer textarea {
  background-color: transparent;
  border: none;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
  outline: none;
  overflow: visible;
  padding: 0;
  resize: none;
  /* remove the resize handle on the bottom right */
  white-space: pre;
}
.text-layer .text {
  bottom: 0;
  display: inline-block;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  white-space: pre;
}
.text-layer .text::after {
  bottom: 0;
  content: '';
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
}
.text-layer .text.text--active::after {
  border: 1px solid white;
}
.text-layer .text.text--dragging::after {
  border: none;
}
.text-layer .text.text--editing {
  z-index: -1;
}
.react-draggable-transparent-selection {
  overflow-y: hidden;
}
