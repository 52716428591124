.foilTag {
  position: relative;
}
.foilTag .foilBackground {
  border-radius: 4px;
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
}
.foilTag.gold .foilBackground {
  background-color: #ffb900;
}
.foilTag.copper .foilBackground {
  background-color: #dda99a;
}
.foilTag.silver .foilBackground {
  background-color: #c0bebe;
}
.foilTag .foilText {
  position: relative;
}
:global(.plpRedesignCardListing) {
  /* TODO: Remove this once zola-ui is updated. Current zola tag styles are overwritten so we'll overwrite them again for now until the actual zola-ui update is in place */
}
:global(.plpRedesignCardListing) .foilTag {
  box-shadow: none;
  margin-left: 8px;
  padding: 0 8px;
}
:global(.plpRedesignCardListing) .foilTag > div {
  color: #21201f;
  font-size: 12px;
  font-weight: 400;
  line-height: 20px;
  text-shadow: none;
}
