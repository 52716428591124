.cardModalRoot :global(.modal-auto) {
  width: auto;
}
@media (min-width: 768px) {
  .cardModalRoot :global(.modal-md) {
    width: 622px;
  }
}
.cardModalRoot .cardModalWrapper {
  align-items: flex-start;
  display: flex !important;
  justify-content: center;
  z-index: 1050;
}
.cardModal.bottomModalOnMobile {
  margin: 100px 0;
}
@media (max-width: 767px) {
  .cardModal.bottomModalOnMobile {
    margin: 0;
    position: absolute;
    bottom: 0;
    max-height: 100vh;
    overflow-y: scroll;
    width: 100%;
  }
}
@media (max-width: 767px) {
  .cardModal.bottomModalOnMobile .cardModalContent {
    border-radius: 0;
  }
}
.cardModal.modalTypeDrawer {
  height: 100vh;
  margin: 0;
  position: absolute;
  right: 0;
  top: 0;
}
.cardModal.modalTypeDrawer .cardModalContent {
  min-height: 100%;
}
.cardModal.fullScreenOnMobile {
  margin: 100px 0;
}
@media (max-width: 767px) {
  .cardModal.fullScreenOnMobile {
    margin: 0;
    position: absolute;
    bottom: 0;
    height: 100vh;
    top: 0;
    right: 0;
    left: 0;
  }
}
.closeButton:global(.modal-close) {
  right: 8px;
  top: 5px;
  z-index: 3000;
}
.backButton {
  color: #0075ae;
  cursor: pointer;
  font-size: 16px;
  font-weight: 600;
  left: 13px;
  line-height: 30px;
  position: absolute;
  top: 20px;
}
.backButton:hover span {
  text-decoration: underline;
}
.backButton :global(.zolaicon::before) {
  font-weight: 600;
}
.modalTopButtonPadding {
  padding-top: 30px;
}
.modalRoundCorners {
  border-radius: 4px !important;
}
