@media screen and (max-width: 767px) {
}
@media (min-width: 768px) {
}
@media (min-width: 768px) {
}
@media (max-width: 767px) {
}
@media (min-width: 768px) {
}
@media (min-width: 768px) {
}
@media (max-width: 767px) {
}
@media (max-width: 767px) {
}
.divider {
  background-color: #d9d9d9;
  height: 2px;
  margin-bottom: 20px;
  width: 100%;
}
.loadMore {
  padding: 0 100px !important;
}
.paginatedCardsContainer {
  min-height: 474px;
}
.loaderAnchor {
  align-content: flex-start;
  display: flex;
  flex-wrap: wrap;
  min-height: calc(100vh - 164px);
  position: relative;
}
@media (max-width: 480px) {
  .loaderAnchor {
    min-height: calc(100vh - 142px);
  }
}
.loaderContainer {
  align-items: center;
  bottom: -2px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  left: 0;
  position: absolute;
  right: 0;
  top: -16px;
  z-index: 2;
}
.showMorecontainer {
  align-items: center;
  display: flex;
  justify-content: center;
  width: 100%;
}
.digitalLoadMore {
  margin-bottom: 60px !important;
}
@media (max-width: 767px) {
  .digitalLoadMore {
    margin-bottom: 20px !important;
  }
}
/* stylelint-disable-next-line selector-pseudo-class-no-unknown */
:global(.plpRedesignCardWrapper) .loaderAnchor:not(.loaderAnchorNoGrid) {
  margin-bottom: 60px;
  display: grid;
  margin-left: -1px;
  margin-right: -1px;
}
@media (min-width: 1920px) {
  :global(.plpRedesignCardWrapper) .loaderAnchor:not(.loaderAnchorNoGrid) {
    grid-template-areas: '. . . . . firstRowLast';
    grid-template-columns: repeat(6, 1fr);
  }
}
@media (max-width: 1919px) and (min-width: 1600px) {
  :global(.plpRedesignCardWrapper) .loaderAnchor:not(.loaderAnchorNoGrid) {
    grid-template-areas: '. . . . firstRowLast';
    grid-template-columns: repeat(5, 1fr);
  }
}
@media (max-width: 1599px) and (min-width: 1280px) {
  :global(.plpRedesignCardWrapper) .loaderAnchor:not(.loaderAnchorNoGrid) {
    grid-template-areas: '. . . firstRowLast';
    grid-template-columns: repeat(4, 1fr);
  }
}
@media (max-width: 1279px) and (min-width: 768px) {
  :global(.plpRedesignCardWrapper) .loaderAnchor:not(.loaderAnchorNoGrid) {
    grid-template-areas: '. . firstRowLast';
    grid-template-columns: repeat(3, 1fr);
  }
}
@media (max-width: 767px) {
  :global(.plpRedesignCardWrapper) .loaderAnchor:not(.loaderAnchorNoGrid) {
    grid-template-areas: '. firstRowLast';
    grid-template-columns: repeat(2, 1fr);
  }
}
@media (max-width: 375px) {
  :global(.plpRedesignCardWrapper) .loaderAnchor:not(.loaderAnchorNoGrid) {
    grid-template-areas: '.' 'firstRowLast';
    grid-template-columns: repeat(1, 1fr);
  }
}
.qrPLPModule {
  cursor: pointer;
}
