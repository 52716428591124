.heading {
  align-items: flex-end;
  color: #21201f;
  display: flex;
  font-family: 'circular', serif;
  font-size: 24px;
  font-weight: 600;
  justify-content: space-between;
  line-height: 1.5;
  margin-bottom: 20px;
  padding: 0 10px;
  position: relative;
}
@media (max-width: 991px) {
  .heading {
    font-size: 16px;
  }
}
@media (max-width: 767px) {
  .heading {
    align-items: center;
    margin-bottom: 12px;
  }
}
.sortBy {
  justify-self: flex-end;
}
@media (max-width: 991px) {
  .sortBy {
    display: none;
  }
}
.favorites {
  display: none;
}
@media (max-width: 991px) {
  .favorites {
    display: block;
  }
}
.favorites .favoritesLink {
  display: flex;
}
.favorites .heartIcon {
  margin-right: 8px;
}
.plpPhotoPreviewBox {
  display: flex;
  flex-direction: row;
}
.multiSampleBox {
  display: flex;
  flex-direction: row;
}
@media (min-width: 992px) {
  .multiSampleBox {
    margin-right: 24px;
  }
}
.plpRedesignHeading {
  font-weight: 400;
  padding: 0 18px 0 42px;
}
@media (max-width: 991px) {
  .plpRedesignHeading {
    padding: 0 22px;
  }
}
.plpRedesignHeadingRight {
  align-items: center;
  display: flex;
  justify-content: flex-end;
}
.plpRedesignViewDrafts {
  margin-right: 8px;
}
@media (min-width: 768px) {
  .viewDraftsSpacing {
    margin-right: 24px;
  }
}
