.fieldSetContainer {
  display: inline-flex;
  gap: 12px;
  width: 100%;
}
.fieldSetContainer > div {
  flex-grow: 1;
}
.manageWeddingDateLink {
  display: inline-flex;
  margin-top: 12px;
}
