.plp-redesign-filter-buttons .dropdown {
  display: block;
}
.plp-redesign-filter-buttons .dropdown__content {
  display: none;
  position: absolute;
}
.plp-redesign-filter-buttons .dropdown--active .dropdown__content {
  display: block;
}
.plp-redesign-filter-buttons .dropdown__trigger:hover {
  text-decoration: none;
}
