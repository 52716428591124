.breadcrumbs {
  margin-top: 20px;
  /* Top padding should be exactly the skinny banner height */
  padding-top: 40px;
}
.noTopPadding {
  padding-top: 0;
}
:global(.container).fullWidth {
  max-width: 100%;
  padding-left: 42px;
  padding-right: 42px;
  width: 100%;
}
@media (max-width: 991px) {
  :global(.container).fullWidth {
    padding-left: 20px;
    padding-right: 20px;
  }
}
