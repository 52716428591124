.container {
  background-color: #f5f9fc;
  height: 100%;
  width: 100%;
}
.containerContainer {
  padding: 0 20px 20px;
  max-width: 1440px;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
}
@media (min-width: 768px) and (max-width: 991px) {
  .containerContainer {
    padding-left: 31px;
    padding-right: 31px;
  }
}
@media (min-width: 992px) {
  .containerContainer {
    padding-left: 38px;
    padding-right: 38px;
  }
}
@media (min-width: 1200px) {
  .containerContainer {
    padding-left: 62px;
    padding-right: 62px;
    padding-bottom: 60px;
  }
}
@media (min-width: 1440px) {
  .containerContainer {
    padding-left: 70px;
    padding-right: 70px;
  }
}
.content {
  text-align: center;
  width: 100%;
}
@media (min-width: 992px) {
  .content {
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
  }
}
.cake {
  width: 100%;
}
@media (min-width: 768px) and (max-width: 991px) {
  .cake {
    max-width: 480px;
  }
}
@media (min-width: 992px) {
  .cake {
    max-width: 480px;
    max-height: 480px;
    text-align: right;
  }
}
@media (min-width: 1200px) {
  .cake {
    max-width: 600px;
    max-height: 600px;
    text-align: right;
  }
}
.actions {
  align-self: flex-end;
  width: 100%;
  margin: 0 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
}
@media (min-width: 768px) {
  .actions {
    align-items: flex-start;
  }
}
@media (min-width: 1200px) {
  .actions {
    margin-left: 34px;
  }
}
.title {
  margin: 0 auto;
  margin-bottom: 30px;
  color: #21201f;
  font-size: 32px;
  line-height: 44px;
}
@media (min-width: 768px) and (max-width: 991px) {
  .title {
    margin-bottom: 40px;
    font-size: 56px;
    line-height: 68px;
  }
}
@media (min-width: 992px) {
  .title {
    margin: 0;
    margin-top: 80px;
    margin-bottom: 40px;
    font-size: 56px;
    line-height: 68px;
    text-align: left;
    max-width: 350px;
  }
}
@media (min-width: 1200px) {
  .title {
    font-size: 86px;
    max-width: 400px;
    line-height: 96px;
  }
}
.text {
  font-weight: 400;
  font-size: 20px;
  line-height: 32px;
  text-align: center;
  margin-bottom: 30px;
}
@media (min-width: 768px) and (max-width: 991px) {
  .text {
    margin: 0 auto;
    margin-bottom: 40px;
    max-width: 500px;
  }
}
@media (min-width: 992px) {
  .text {
    margin: 0;
    margin-bottom: 40px;
    text-align: left;
    max-width: 380px;
  }
}
@media (min-width: 1200px) {
  .text {
    max-width: 520px;
  }
}
.link {
  margin: 0 auto;
  margin-bottom: 20px;
}
@media (min-width: 768px) and (max-width: 991px) {
  .link {
    margin-bottom: 12px;
  }
  .link:last-of-type {
    margin-bottom: 80px;
  }
}
@media (min-width: 992px) {
  .link {
    margin: 0;
    margin-bottom: 12px;
    text-align: left;
  }
  .link:last-of-type {
    margin-bottom: 80px;
  }
}
@media (min-width: 1200px) {
  .link:last-of-type {
    margin-bottom: 120px;
  }
}
