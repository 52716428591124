.floatingWrapper {
  align-items: center;
  background-color: #ffffff;
  box-shadow: 0 6px 10px rgba(0, 0, 0, 0.2);
  color: #505050;
  bottom: -66px;
  display: flex;
  font-size: 16px;
  font-weight: 600;
  justify-content: space-between;
  left: 50%;
  margin-left: -106px;
  position: absolute;
  width: 194px;
}
.button {
  align-items: center;
  display: flex;
  justify-content: flex-start;
  line-height: 12px;
  height: 28px;
  padding: 6px 0 6px 5px;
  margin-right: 9px;
}
.button + .button {
  padding: 6px 8px 6px 4px;
  margin-left: 0;
  margin-right: 0;
}
.button:hover {
  cursor: pointer;
}
.icon {
  margin-right: 5px;
}
