.wrapper {
  align-items: center;
  display: flex;
  justify-content: space-between;
  margin-top: 32px;
  padding: 0 10px;
}
@media (max-width: 991px) {
  .wrapper {
    display: block;
    margin-top: 20px;
    padding: 0;
  }
}
.title {
  font-size: 24px;
  font-weight: 600;
  line-height: 1.5;
  max-width: 190px;
  padding-right: 12px;
}
@media (max-width: 991px) {
  .title {
    line-height: 1;
    margin-bottom: 12px;
    max-width: none;
    padding-left: 10px;
    padding-right: 10px;
  }
}
.tiles {
  align-items: flex-start;
  display: flex;
  flex-grow: 1;
  justify-content: space-between;
}
@media (max-width: 991px) {
  .tiles {
    overflow-x: auto;
    padding-bottom: 8px;
    padding-left: 10px;
    padding-right: 10px;
  }
}
.tile {
  color: #505050;
  cursor: pointer;
  display: block;
  flex: 1 1 0px;
  font-size: 16px;
  font-weight: 600;
  line-height: 1.5;
  margin-left: 32px;
  text-align: center;
}
@media (max-width: 1199px) {
  .tile {
    margin-left: 20px;
  }
}
@media (max-width: 991px) {
  .tile {
    font-size: 14px;
    margin-left: 12px;
    min-width: 100px;
  }
}
.tile:first-of-type {
  margin-left: 0;
}
.tile .image {
  background-color: #c8e3ec;
  background-position: center;
  background-size: cover;
  border-radius: 50%;
  padding-bottom: 100%;
  width: 100%;
}
.tile .label {
  margin-top: 12px;
}
.tile:hover .image {
  box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.1);
}
.tile:hover .label {
  text-decoration: underline;
}
