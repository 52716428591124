/* stylelint-disable selector-pseudo-class-no-unknown, declaration-no-important */
/* Wrapping zola-ui Uploadcare styles in a global selector inside this styled module file ensures that they work for all web-wedding use-cases (looking at you, NextJS) */
:global {
  /* stylelint-disable declaration-no-important */
  /* TODO: All changes must also be made to corresponding classes from thirdPartyTabStyles.js */
  /* (3rd party) body */
  /* ----- MENU SECTION ----- */
  /* ---------- BUTTONS ---------- */
  /* (3rd party) .welcome .big-button */
  /* ---------- FILE TAB ---------- */
  /* Our custom text elements inserted through copy */
  /*
  Little hack that allows us to place our custom `zola-file-info` element
  (defined in our copy) below the file tab's primary button.
*/
  /*
  Little hack that allows us to place our custom `zola-faq-link` element
  (defined in our copy) beneath the error tab's button.
*/
  /* (3rd party) .welcome h1 */
  /* (3rd party) .welcome h2 */
  /* ---------- MULTIPLE FILES TAB ---------- */
  /* ---------- PREVIEW TAB ---------- */
  /* ---------- ERROR/PROGRESS STATES ---------- */
  /* ---------- MAIN PANEL FOOTER (ONLY USED ON SOURCE TABS, NOT THE PREVIEW/EFFECTS TAB) ---------- */
}
:global .uploadcare--widget {
  display: none;
}
:global .uploadcare--powered-by,
:global .uploadcare--widget__button,
:global .uploadcare--widget__dragndrop-area,
:global .uploadcare--widget__progress,
:global .uploadcare--widget__text {
  display: none;
}
:global .uploadcare--dialog__container {
  background: #ffffff;
  border-radius: 4px;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.1);
}
:global .uploadcare--panel {
  font-family: 'circular', serif;
}
:global .uploadcare--menu__items,
:global .uploadcare--menu__item {
  background: #ffffff;
  color: #757575;
}
:global .uploadcare--menu__item:focus,
:global .uploadcare--menu__item:hover {
  color: #0e0e0e;
}
:global .uploadcare--menu__item::after {
  font-size: 16px;
}
:global .uploadcare--menu__item_current {
  border-right: 4px solid #0e0e0e;
  color: #0e0e0e;
}
:global .uploadcare--menu__icon.uploadcare--progress[aria-valuenow='100'] {
  background-image: url('https://d1tntvpcrzvon2.cloudfront.net/static-assets/images/logos/zola-heart-marine.svg');
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  content: '';
  display: inline-block;
  height: 32px;
  width: 32px;
}
:global .uploadcare--menu__icon.uploadcare--progress[aria-valuenow='100'] canvas {
  display: none;
}
:global .uploadcare--panel__content {
  border-left: 1px solid #b7b7b7;
}
:global .uploadcare--tab__title {
  color: #0e0e0e;
  font-size: 16px;
  font-weight: 600;
}
:global .uploadcare--button {
  cursor: pointer;
}
:global .uploadcare--button_muted:hover {
  color: #505050;
}
:global .uploadcare--button_muted:focus {
  outline: none;
}
:global .uploadcare--button_primary {
  background: #0e0e0e;
  border: 1px solid transparent;
  border-radius: 100px;
  color: #ffffff;
  font-size: 16px;
  font-weight: 600;
  line-height: 1;
  padding: 13px 16px;
  text-transform: capitalize;
}
:global .uploadcare--button_primary:hover {
  background: #4a4a4a;
  border-color: transparent;
}
:global .uploadcare--button_primary:focus {
  background: #4a4a4a;
  border-color: transparent;
  outline: 5px auto -webkit-focus-ring-color;
  outline-offset: 0;
}
:global .uploadcare--button_primary:active {
  background: #4a4a4a;
  border-color: transparent;
}
:global .uploadcare--button_primary:disabled {
  background: #b7b7b7 !important;
  border-color: transparent !important;
  color: #ffffff !important;
}
:global .uploadcare--preview__back {
  background-color: #ffffff;
  border: 1px solid #b7b7b7;
  border-radius: 4px;
  color: #0e0e0e;
  font-size: 16px;
  font-weight: 600;
  line-height: 1;
  padding: 13px 16px;
  text-transform: capitalize;
}
:global .uploadcare--preview__back:hover {
  background: #f3f3f3;
  border-color: #757575;
  color: #0e0e0e;
}
:global .uploadcare--preview__back:focus {
  background: #f3f3f3;
  border-color: #757575;
  color: #0e0e0e;
  outline: 5px auto -webkit-focus-ring-color;
  outline-offset: 0;
}
:global .uploadcare--preview__back:active {
  background: #f3f3f3;
  border-color: #757575;
  color: #0e0e0e;
}
:global .uploadcare--text_size_extra-large::before {
  content: url('https://d1tntvpcrzvon2.cloudfront.net/static-assets/images/uploader/illustrative_website.svg');
  display: block;
  text-align: center;
}
:global .uploadcare--panel__file-counter {
  display: none;
}
:global .zola-text {
  color: #505050;
  font-size: 14px;
  font-weight: 400;
  line-height: 22px;
  white-space: initial;
}
:global .zola-text a {
  font-weight: 600;
}
:global .uploadcare--tab_name_file .uploadcare--tab__action-button {
  overflow: visible;
  position: relative;
}
:global .uploadcare--tab_name_file .uploadcare--tab__action-button:focus {
  outline: none;
}
:global .uploadcare--tab_name_file .uploadcare--tab__action-button .zola-text.file-info {
  display: inline-block;
  left: 50%;
  outline-color: transparent;
  padding-top: 14px;
  pointer-events: none;
  position: absolute;
  text-transform: none;
  top: 100%;
  touch-action: none;
  transform: translateX(-50%);
  width: 300px;
}
@media (max-width: 759px) {
  :global .uploadcare--tab_name_file .uploadcare--tab__action-button .zola-text.file-info {
    top: 160px;
  }
}
:global .uploadcare--tab_name_preview .uploadcare--error {
  /* The middle text element has ONLY the uploadcare--text class */
}
:global .uploadcare--tab_name_preview .uploadcare--error .uploadcare--preview__title {
  margin-bottom: 12px;
  z-index: 1;
}
:global .uploadcare--tab_name_preview .uploadcare--error .uploadcare--text[class='uploadcare--text'] {
  margin-bottom: 20px;
  position: relative;
  z-index: 2;
}
:global .uploadcare--tab_name_preview .uploadcare--error .uploadcare--text[class='uploadcare--text'] .zola-text.faq-link {
  display: inline-block;
  left: 50%;
  padding-top: 84px;
  position: absolute;
  text-transform: none;
  top: 100%;
  transform: translateX(-50%);
  width: 280px;
}
@media (max-width: 759px) {
  :global .uploadcare--tab_name_preview .uploadcare--error .uploadcare--text[class='uploadcare--text'] .zola-text.faq-link {
    top: 140px;
  }
}
:global .uploadcare--tab_name_preview .uploadcare--error .uploadcare--preview__back {
  z-index: 3;
}
:global .uploadcare--text_size_extra-large {
  color: #21201f;
  font-size: 24px;
  font-weight: 600;
  line-height: 36px;
  margin: 0;
}
:global .uploadcare--text_muted {
  color: #505050;
  font-size: 16px;
  line-height: 24px;
}
:global .uploadcare--preview__file-name {
  color: #0e0e0e;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
}
:global .uploadcare--preview__file-name::before {
  background-image: url('https://d1tntvpcrzvon2.cloudfront.net/static-assets/images/uploader/HeartsLoader@1X.gif');
  background-size: contain;
  content: '';
  display: block;
  height: 34px;
  margin: 0 auto 20px;
  width: 114px;
}
@media screen and (min-width: 768px) {
  :global .uploadcare--preview__file-name::before {
    background-image: url('https://d1tntvpcrzvon2.cloudfront.net/static-assets/images/uploader/HeartsLoader@2X.gif');
  }
}
:global .uploadcare--panel_multiple .uploadcare--tab_name_preview .uploadcare--tab__footer {
  display: flex;
}
:global .uploadcare--panel_multiple .uploadcare--tab_name_preview .uploadcare--preview__back {
  /* Don't display the "Remove All" CTA */
  display: none;
}
:global .uploadcare--panel_multiple .uploadcare--tab_name_preview .uploadcare--preview__message {
  justify-content: left;
  padding: 0 15px 0 0;
  text-align: left;
}
:global .uploadcare--panel_multiple .uploadcare--tab_name_preview .uploadcare--preview__message:empty {
  color: #505050;
  visibility: visible;
}
:global .uploadcare--panel_multiple .uploadcare--tab_name_preview .uploadcare--preview__message:empty::after {
  content: "You're all set!";
}
:global input[type='range'].uploadcare-tab-effects--range__input {
  cursor: pointer;
}
:global input[type='range'].uploadcare-tab-effects--range__input:focus {
  outline: none;
}
:global .uploadcare--crop-sizes__item:only-child {
  display: none;
}
:global .uploadcare-tab-effects--effects .uploadcare-tab-effects--effect-button {
  color: #505050;
  fill: #505050;
  /* By default, effect label tooltips remain visible as long as button has focus. Disable. */
}
:global .uploadcare-tab-effects--effects .uploadcare-tab-effects--effect-button:hover,
:global .uploadcare-tab-effects--effects .uploadcare-tab-effects--effect-button:active,
:global .uploadcare-tab-effects--effects .uploadcare-tab-effects--effect-button:focus {
  color: #21201f;
  fill: #21201f;
}
:global .uploadcare-tab-effects--effects .uploadcare-tab-effects--effect-button.uploadcare-tab-effects--effect-button_applied {
  color: #0075ae;
  fill: #0075ae;
}
:global .uploadcare-tab-effects--effects .uploadcare-tab-effects--effect-button.uploadcare-tab-effects--effect-button_applied::before {
  background: transparent;
}
:global .uploadcare-tab-effects--effects .uploadcare-tab-effects--effect-button:focus:not(:hover)::after {
  display: none;
}
:global .uploadcare--dialog.zola-state-progress,
:global .uploadcare--dialog.zola-state-error {
  /* Disable the Submit button until all files are valid */
  /* Hide the preview list's "Success" message */
}
:global .uploadcare--dialog.zola-state-progress .uploadcare--footer .uploadcare--button_primary,
:global .uploadcare--dialog.zola-state-error .uploadcare--footer .uploadcare--button_primary {
  background: #d4d2d2;
  border-color: #d4d2d2;
  color: #ffffff;
  cursor: not-allowed;
  pointer-events: none;
  touch-action: none;
}
:global .uploadcare--dialog.zola-state-progress .uploadcare--preview__message:empty,
:global .uploadcare--dialog.zola-state-error .uploadcare--preview__message:empty {
  visibility: hidden;
}
:global .uploadcare--panel__footer {
  /* Hide the "Done" button for source tabs. We only allow users to submit the modal from the preview tab. */
  /* Place the "Show Files" button on the far right and style it like a primary CTA */
}
:global .uploadcare--panel__footer .uploadcare--panel__done {
  display: none;
}
:global .uploadcare--panel__footer .uploadcare--panel__message {
  justify-content: left;
  order: 1;
  text-align: left;
}
:global .uploadcare--panel__footer .uploadcare--panel__show-files {
  background: #0e0e0e;
  border: 1px solid transparent;
  border-radius: 100px;
  color: #ffffff;
  font-size: 16px;
  font-weight: 600;
  line-height: 1;
  padding: 13px 16px;
  text-transform: capitalize;
  order: 2;
}
:global .uploadcare--panel__footer .uploadcare--panel__show-files:hover {
  background: #4a4a4a;
  border-color: transparent;
}
:global .uploadcare--panel__footer .uploadcare--panel__show-files:focus {
  background: #4a4a4a;
  border-color: transparent;
  outline: 5px auto -webkit-focus-ring-color;
  outline-offset: 0;
}
:global .uploadcare--panel__footer .uploadcare--panel__show-files:active {
  background: #4a4a4a;
  border-color: transparent;
}
:global .uploadcare--panel__footer .uploadcare--panel__show-files:disabled {
  background: #b7b7b7 !important;
  border-color: transparent !important;
  color: #ffffff !important;
}
:global .uploadcare--dialog__close {
  color: #0e0e0e;
}
:global .uploadcare--dialog__close:hover,
:global .uploadcare--dialog__close:active,
:global .uploadcare--dialog__close:focus {
  color: #4a4a4a;
}
