/* stylelint-disable selector-pseudo-class-no-unknown */
.tagContainer {
  align-items: flex-start;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  max-width: 100%;
  min-height: 20px;
  padding-left: 16px;
}
.tagContainer :global(.filterTags-override) {
  margin: 0 12px 8px 0;
  padding: 4px 12px 4px 16px;
}
.tagContainer :global(.filterTags-override) :global(.pill-label) {
  font-size: 14px;
  font-weight: 400;
}
@media (max-width: 991px) {
  .tagContainer {
    min-height: 12px;
  }
}
@media (max-width: 767px) {
  .tagContainer {
    flex-wrap: nowrap;
    overflow-x: auto;
  }
}
.tagClearAll {
  color: #0e0e0e;
  cursor: pointer;
  font-family: 'circular', serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 20px;
  margin: 0 6px 8px 6px;
  padding: 6px 0 6px 0;
  white-space: nowrap;
  text-decoration: underline;
}
.tagClearAll:hover {
  background-color: transparent;
  text-decoration: underline;
  color: #4a4a4a;
}
@media (max-width: 767px) {
  .tagClearAll {
    margin: 0 6px 0 0;
    padding: 6px 14px 6px 0;
  }
}
