.container {
  background-color: #ffffff;
  border: 2px dashed #aaaaaa;
  width: 100%;
  border-radius: 4px;
  color: #505050;
  text-align: center;
  font-size: 16px;
  line-height: 24px;
  padding: 30px;
  font-weight: 700;
}
@media (min-width: 992px) {
  .container {
    text-align: left;
    display: flex;
    align-items: center;
    padding: 28px 28px 28px 15px;
  }
}
.tip {
  font-weight: 400;
  max-width: 190px;
  margin-bottom: 0;
  margin-left: 12px;
}
@media (max-width: 991px) {
  .tip {
    max-width: 230px;
    margin: 0 auto 30px;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .browseBtn {
    max-width: 208px;
  }
}
@media (min-width: 992px) {
  .browseBtn {
    margin-left: auto;
    flex-shrink: 0;
  }
}
.roundedBorder {
  border: 1px solid #d9d9d9;
  border-radius: 4px;
}
.iconUpload {
  display: block;
  margin: 0 auto;
  max-width: 120px;
}
@media (min-width: 992px) {
  .iconUpload {
    margin: 0;
    max-width: 64px;
  }
}
.uploadedContainer {
  display: flex;
  align-items: center;
}
@media (min-width: 992px) {
  .uploadedContainer {
    margin-bottom: 20px;
  }
}
.img {
  width: 120px;
}
.img.circle {
  border-radius: 50%;
}
.buttons {
  margin-left: 8px;
}
.imgActionCta {
  background-color: transparent;
  border: none;
  font-size: 16px;
  line-height: 24px;
  color: #505050;
  font-weight: 600;
  display: flex;
  align-items: center;
  padding: 4px 8px;
  border-radius: 4px;
}
.imgActionCta:hover {
  background-color: #eee;
}
.imgActionCta + .imgActionCta {
  margin-top: 6px;
}
.icon {
  margin-right: 5px;
}
