.productColorPicker {
  align-items: center;
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  position: relative;
}
.colorWrapper {
  border: 1px solid transparent;
  border-radius: 50%;
  display: inline-block;
  padding: 3px;
  position: relative;
}
.colorWrapper:not(:last-of-type) {
  margin-right: 4px;
}
.colorWrapper.active {
  border-color: #21201f;
}
.color {
  border: 1px solid #c0c0c0;
  border-radius: 50%;
  cursor: pointer;
  display: block;
}
.color.rainbow {
  background: radial-gradient(50% 50% at 50% 50%, #fff 0%, rgba(255, 255, 255, 0) 100%), conic-gradient(from -90deg at 50% 50%, #c0f -3.06deg, #f00 21.31deg, #f90 56.93deg, #ffe600 105.68deg, #fff500 141.31deg, #3f0 186.31deg, #0ff 236.94deg, #00f7ff 250.06deg, #000aff 293.19deg, #c0f 356.94deg, #f00 381.31deg);
}
.moreLink {
  margin-left: 4px;
}
