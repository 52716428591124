@media screen and (max-width: 767px) {
}
@media (min-width: 768px) {
}
@media (min-width: 768px) {
}
@media (max-width: 767px) {
}
@media (min-width: 768px) {
}
@media (min-width: 768px) {
}
@media (max-width: 767px) {
}
@media (max-width: 767px) {
}
.pdpButtonReset,
.pdpToggleButton {
  border: none;
  border-radius: 0;
  margin: 0;
  padding: 0;
  width: auto;
  overflow: visible;
  text-align: inherit;
  outline: none;
  background: transparent;
  color: inherit;
  font: inherit;
  line-height: normal;
  -webkit-font-smoothing: inherit;
  -moz-osx-font-smoothing: inherit;
  -webkit-appearance: none;
}
.pdpButtonReset::-moz-focus-inner {
  border: 0;
  padding: 0;
}
.pdpToggleButton {
  box-sizing: border-box;
  padding: 14px 6px;
  background-color: #ffffff;
  text-align: center;
  color: #0e0e0e;
  border: 1px solid #b7b7b7;
  border-radius: 16px;
  cursor: pointer;
  min-width: 124px;
  display: flex;
  align-items: stretch;
  flex-direction: column;
}
.pdpToggleButton:hover {
  background-color: #f3f3f3;
  color: #0e0e0e;
  border-color: #757575;
}
.pdpToggleButton.narrow {
  min-width: 0;
  padding: 14px;
}
.pdpToggleButton.active .pdpToggleButton.active:hover {
  background-color: #f3f3f3;
  border-color: #0e0e0e;
  color: #0e0e0e;
}
.pdpToggleButton.disabled,
.pdpToggleButton.disabled:hover {
  background-color: #ffffff;
  border-color: #b7b7b7;
  color: #b7b7b7;
  cursor: not-allowed;
}
.pdpToggleButtonIcon {
  color: #505050;
  font-size: 32px;
  margin: 0 auto;
  margin-bottom: 8px;
  width: 32px;
  height: 32px;
}
.pdpToggleButtonColorIcon {
  border: 1px solid #d9d9d9;
  border-radius: 100%;
  height: 32px;
  margin: 0 auto 8px;
  width: 32px;
}
.pdpToggleButtonColorIcon.rainbow {
  background: radial-gradient(50% 50% at 50% 50%, #fff 0%, rgba(255, 255, 255, 0) 100%), conic-gradient(from -90deg at 50% 50%, #c0f -3.06deg, #f00 21.31deg, #f90 56.93deg, #ffe600 105.68deg, #fff500 141.31deg, #3f0 186.31deg, #0ff 236.94deg, #00f7ff 250.06deg, #000aff 293.19deg, #c0f 356.94deg, #f00 381.31deg);
  border-color: white !important;
}
.pdpToggleButtonLabel {
  font-family: 'circular', serif;
  font-size: 16px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1;
  letter-spacing: normal;
}
.pdpToggleButtonSublabel {
  display: none;
  font-family: 'circular', serif;
  font-size: 12px;
  font-weight: 400;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: normal;
  text-align: center;
  color: #767676;
  margin-left: 4px;
}
.pdpToggleButtonSublabel.active {
  color: #21201f;
}
.pdpToggleButtonSublabel.disabled {
  color: #d9d9d9;
}
@media screen and (max-width: 768px) {
  .pdpToggleButton {
    width: 100%;
  }
  .pdpToggleButtonSublabel {
    display: inline-block;
  }
}
.pdpToggleButton.pill {
  align-items: center;
  flex-direction: row;
  justify-content: center;
  padding: 16px 8px;
}
.pdpToggleButton.pill.active {
  background-color: #f3f3f3;
  border-color: #0e0e0e;
  color: #0e0e0e;
  cursor: unset;
}
.pdpToggleButton.pill .pdpToggleButtonIcon {
  height: 20px;
  margin: 0 8px 0 0;
  width: auto;
}
.pdpToggleButton.pill .pdpToggleButtonLabel {
  line-height: 20px;
}
.pdpToggleButton.pill .pdpToggleButtonSublabel {
  display: none;
}
.pdpToggleButton.iconOnly {
  align-items: center;
  background-color: unset;
  border-color: transparent;
  justify-content: center;
  min-width: unset;
  padding: 5px;
  width: auto;
  border-radius: 4px;
}
.pdpToggleButton.iconOnly:hover {
  background-color: #f3f3f3;
  border-color: #757575;
}
.pdpToggleButton.iconOnly.active {
  background-color: #f3f3f3;
  border-color: #0e0e0e;
  cursor: unset;
}
.pdpToggleButton.iconOnly.disabled,
.pdpToggleButton.iconOnly.disabled:hover {
  background-color: #ffffff;
  color: #b7b7b7;
  cursor: not-allowed;
}
.pdpToggleButton.iconOnly .pdpToggleButtonIcon {
  height: 40px;
  margin: 0;
  width: auto;
}
.pdpToggleButton.iconOnly .pdpToggleButtonLabel,
.pdpToggleButton.iconOnly .pdpToggleButtonSublabel {
  display: none;
}
.pdpToggleButton.iconOnlyRound {
  align-items: center;
  background-color: unset;
  border-radius: 100%;
  border-color: transparent;
  justify-content: center;
  min-width: unset;
  padding: 5px;
  width: auto;
}
.pdpToggleButton.iconOnlyRound:hover {
  background-color: unset;
  border-color: #757575;
}
.pdpToggleButton.iconOnlyRound.active {
  border-color: #0e0e0e;
  cursor: unset;
}
.pdpToggleButton.iconOnlyRound .pdpToggleButtonColorIcon {
  border: 1px solid #d9d9d9;
}
.pdpToggleButton.iconOnlyRound .pdpToggleButtonColorIcon,
.pdpToggleButton.iconOnlyRound .pdpToggleButtonIcon {
  height: 40px;
  margin: 0;
  width: 40px;
}
.pdpToggleButton.iconOnlyRound .pdpToggleButtonLabel,
.pdpToggleButton.iconOnlyRound .pdpToggleButtonSublabel {
  display: none;
}
