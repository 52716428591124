@media screen and (max-width: 767px) {
}
@media (min-width: 768px) {
}
@media (min-width: 768px) {
}
@media (max-width: 767px) {
}
@media (min-width: 768px) {
}
@media (min-width: 768px) {
}
@media (max-width: 767px) {
}
@media (max-width: 767px) {
}
.bannerTipsBtnIcon {
  display: flex;
  /* stylelint-disable-next-line declaration-no-important */
  padding: 0 6px !important;
}
.bannerTipsBtn {
  color: #0e0e0e;
  cursor: pointer;
  display: inline-flex;
  flex-direction: row;
  font-family: 'circular', serif;
  font-weight: 600;
  vertical-align: middle;
}
.bannerTipsBtn:hover {
  text-decoration: underline;
}
.bannerContents {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  font-family: 'circular', serif;
  justify-content: center;
  padding: 12px 20px;
}
.bannerContainer {
  margin: 6px;
}
.bannerContainer > div {
  /* stylelint-disable-next-line declaration-no-important */
  padding: 0 !important;
}
.title {
  display: flex;
}
.alignIcon {
  border: solid;
  border-color: #0075ae;
  border-width: 1px;
  color: #0075ae;
  font-size: 0.8rem;
  padding: 6px 8px 6px 3px;
}
.editLinks {
  color: #21201f;
  padding-bottom: 12px;
}
.checklistItemHeader {
  font-weight: 600;
}
.checklistBullet {
  background-image: url(../../../../assets/images/icons/check.svg);
  background-size: 100% 100%;
  margin-right: 8px;
  max-height: 16px;
  max-width: 16px;
  min-height: 16px;
  min-width: 16px;
  position: relative;
  top: 2px;
}
.checklistItem {
  display: flex;
  flex-direction: row;
  list-style-type: none;
  padding: 12px 0;
}
.checklist {
  padding: 0;
}
.checklistContainer {
  color: #21201f;
  display: flex;
}
.box {
  display: flex;
  flex-direction: column;
  margin: 0 48px;
  padding: 24px 0;
}
