.plpRedesignFilterButtons :global .dropdown {
  display: block;
}
.plpRedesignFilterButtons :global .dropdown__content {
  display: none;
  position: absolute;
}
.plpRedesignFilterButtons :global .dropdown--active .dropdown__content {
  display: block;
}
.plpRedesignFilterButtons :global .dropdown__trigger:hover {
  text-decoration: none;
}
