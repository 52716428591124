/*---------------------------------------------------------------------------*/
/*---------------------------------------------------------------------------*/
/*
 * Adding a new Font? STOP - READ THIS
 * Add them as well in that file => pages/styles/global/cardFonts.less (used by nextjs)
 * PLUS, upload them manually to the /static-assets/fonts/ S3 bucket
 */
@font-face {
  font-family: 'Amira Madison Script';
  font-weight: 400;
  font-style: normal;
  src: url('../../assets/fonts/Amira-Madison-Script.otf');
}
@font-face {
  font-family: 'Avalanche';
  font-weight: 400;
  font-style: normal;
  src: url('../../assets/fonts/Avalanche.ttf');
}
@font-face {
  font-family: 'Butterskotch';
  font-weight: 300;
  font-style: normal;
  src: url('../../assets/fonts/Butterskotch-Light.otf');
}
@font-face {
  font-family: 'Butterskotch';
  font-weight: 400;
  font-style: normal;
  src: url('../../assets/fonts/Butterskotch-Regular.otf');
}
@font-face {
  font-family: 'Butterskotch';
  font-weight: 700;
  font-style: normal;
  src: url('../../assets/fonts/Butterskotch-Bold.otf');
}
@font-face {
  font-family: 'HallelujahSerif';
  font-weight: 500;
  font-style: normal;
  src: url('../../assets/fonts/HallelujahSerif.ttf');
}
@font-face {
  font-family: 'Kindred';
  font-weight: 400;
  font-style: normal;
  src: url('../../assets/fonts/Kindred.otf');
}
@font-face {
  font-family: 'Kindred';
  font-weight: 700;
  font-style: normal;
  src: url('../../assets/fonts/Kindred-Bold.otf');
}
@font-face {
  font-family: 'Kindred Rounded';
  font-weight: 400;
  font-style: normal;
  src: url('../../assets/fonts/KindredRounded.otf');
}
@font-face {
  font-family: 'Kindred Rounded';
  font-weight: 300;
  font-style: normal;
  src: url('../../assets/fonts/KindredRounded-Light.otf');
}
@font-face {
  font-family: 'Kindred Rounded';
  font-weight: 700;
  font-style: normal;
  src: url('../../assets/fonts/KindredRounded-Bold.otf');
}
@font-face {
  font-family: 'Kinfolk';
  font-weight: 400;
  font-style: normal;
  src: url('../../assets/fonts/Kinfolk-Regular.otf');
}
@font-face {
  font-family: 'Macadamia Smooth';
  font-weight: 400;
  font-style: normal;
  src: url('../../assets/fonts/MacadamiaSmooth-Regular.otf');
}
@font-face {
  font-family: 'Macadamia Textured';
  font-weight: 400;
  font-style: normal;
  src: url('../../assets/fonts/MacadamiaTextured-Regular.otf');
}
@font-face {
  font-family: 'Mikela';
  font-weight: 300;
  font-style: normal;
  src: url('../../assets/fonts/Mikela-Light.otf');
}
@font-face {
  font-family: 'Mikela';
  font-weight: 400;
  font-style: normal;
  src: url('../../assets/fonts/Mikela.otf');
}
@font-face {
  font-family: 'Mikela';
  font-weight: 700;
  font-style: normal;
  src: url('../../assets/fonts/Mikela-Bold.otf');
}
@font-face {
  font-family: 'Mojito';
  font-weight: 400;
  font-style: normal;
  src: url('../../assets/fonts/Mojito-Regular.otf');
}
@font-face {
  font-family: 'Mojito Textured';
  font-weight: 400;
  font-style: normal;
  src: url('../../assets/fonts/MojitoTextured.otf');
}
@font-face {
  font-family: 'Mojito Textured';
  font-weight: 400;
  font-style: italic;
  src: url('../../assets/fonts/MojitoTextured-Oblique.otf');
}
@font-face {
  font-family: 'Oxford';
  font-weight: 400;
  font-style: normal;
  src: url('../../assets/fonts/Oxford-Regular.ttf');
}
@font-face {
  font-family: 'Oxford';
  font-weight: 300;
  font-style: normal;
  src: url('../../assets/fonts/Oxford-Light.ttf');
}
@font-face {
  font-family: 'Oxford';
  font-weight: 700;
  font-style: normal;
  src: url('../../assets/fonts/Oxford-Bold.ttf');
}
@font-face {
  font-family: 'Sauvage';
  font-weight: 400;
  font-style: normal;
  src: url('../../assets/fonts/Sauvage-Regular.otf');
}
@font-face {
  font-family: 'circular';
  src: url('https://d1tntvpcrzvon2.cloudfront.net/static-assets/fonts/CircularXXWeb-Regular.woff2') format('woff2'), url('https://d1tntvpcrzvon2.cloudfront.net/static-assets/fonts/CircularXXWeb-Regular.woff') format('woff'), url('https://d1tntvpcrzvon2.cloudfront.net/static-assets/fonts/CircularXXWeb-Regular.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
  font-stretch: normal;
  font-display: swap;
}
@font-face {
  font-family: 'circular';
  src: url('https://d1tntvpcrzvon2.cloudfront.net/static-assets/fonts/CircularXXWeb-Medium.woff2') format('woff2'), url('https://d1tntvpcrzvon2.cloudfront.net/static-assets/fonts/CircularXXWeb-Medium.woff') format('woff'), url('https://d1tntvpcrzvon2.cloudfront.net/static-assets/fonts/CircularXXWeb-Medium.ttf') format('truetype');
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
  font-display: swap;
}
@font-face {
  font-family: 'circular';
  src: url('https://d1tntvpcrzvon2.cloudfront.net/static-assets/fonts/CircularXXWeb-MediumItalic.woff2') format('woff2'), url('https://d1tntvpcrzvon2.cloudfront.net/static-assets/fonts/CircularXXWeb-MediumItalic.woff') format('woff'), url('https://d1tntvpcrzvon2.cloudfront.net/static-assets/fonts/CircularXXWeb-MediumItalic.ttf') format('truetype');
  font-weight: 400;
  font-style: italic;
  font-stretch: normal;
  font-display: swap;
}
@font-face {
  font-family: 'Adora Bouton';
  font-weight: 400;
  src: url('../../assets/fonts/Adora-Bouton.otf');
}
@font-face {
  font-family: 'Aston Script';
  font-weight: 400;
  src: url('../../assets/fonts/AstonScript-Regular.otf');
}
@font-face {
  font-family: 'Alex Brush';
  font-weight: 400;
  src: url('../../assets/fonts/AlexBrush-Regular.ttf');
}
@font-face {
  font-family: 'Aleo';
  font-weight: 300;
  src: url('../../assets/fonts/Aleo-Light.eot');
  src: url('../../assets/fonts/Aleo-Light.eot?#iefix') format('embedded-opentype'), url('../../assets/fonts/Aleo-Light.otf') format('truetype');
}
@font-face {
  font-family: 'Aleo';
  font-weight: 300;
  font-style: italic;
  src: url('../../assets/fonts/Aleo-LightItalic.eot');
  src: url('../../assets/fonts/Aleo-LightItalic.eot?#iefix') format('embedded-opentype'), url('../../assets/fonts/Aleo-LightItalic.ttf') format('truetype');
}
@font-face {
  font-family: 'Aleo';
  font-weight: 400;
  src: url('../../assets/fonts/Aleo-Regular.eot');
  src: url('../../assets/fonts/Aleo-Regular.eot?#iefix') format('embedded-opentype'), url('../../assets/fonts/Aleo-Regular.otf') format('truetype');
}
@font-face {
  font-family: 'Aleo';
  font-weight: 400;
  font-style: italic;
  src: url('../../assets/fonts/Aleo-Italic.eot');
  src: url('../../assets/fonts/Aleo-Italic.eot?#iefix') format('embedded-opentype'), url('../../assets/fonts/Aleo-Italic.otf') format('truetype');
}
@font-face {
  font-family: 'Aleo';
  font-weight: 700;
  src: url('../../assets/fonts/Aleo-Bold.eot');
  src: url('../../assets/fonts/Aleo-Bold.eot?#iefix') format('embedded-opentype'), url('../../assets/fonts/Aleo-Bold.otf') format('truetype');
}
@font-face {
  font-family: 'Aleo';
  font-weight: 700;
  font-style: italic;
  src: url('../../assets/fonts/Aleo-BoldItalic.eot');
  src: url('../../assets/fonts/Aleo-BoldItalic.eot?#iefix') format('embedded-opentype'), url('../../assets/fonts/Aleo-BoldItalic.ttf') format('truetype');
}
@font-face {
  font-family: 'Alexander Lettering';
  font-weight: 400;
  font-style: normal;
  src: url('../../assets/fonts/Alexander-Lettering.eot');
  src: url('../../assets/fonts/Alexander-Lettering.eot?#iefix') format('embedded-opentype'), url('../../assets/fonts/Alexander-Lettering.woff') format('woff'), url('../../assets/fonts/Alexander-Lettering.woff2') format('woff2'), url('../../assets/fonts/Alexander-Lettering.ttf') format('truetype');
}
@font-face {
  font-family: 'Alfa Slab One';
  font-weight: 400;
  src: url('../../assets/fonts/AlfaSlabOne-Regular.ttf');
}
@font-face {
  font-family: 'Allura';
  font-weight: 400;
  src: url('../../assets/fonts/Allura-Regular.ttf');
}
@font-face {
  font-family: 'Alpharush';
  src: url('../../assets/fonts/Alpharush.eot');
  src: url('../../assets/fonts/Alpharush.eot?#iefix') format('embedded-opentype'), url('../../assets/fonts/Alpharush.woff2') format('woff2'), url('../../assets/fonts/Alpharush.woff') format('woff'), url('../../assets/fonts/Alpharush.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: 'Amaryllis';
  src: url('../../assets/fonts/Amaryllis.eot');
  src: url('../../assets/fonts/Amaryllis.eot?#iefix') format('embedded-opentype'), url('../../assets/fonts/Amaryllis.woff2') format('woff2'), url('../../assets/fonts/Amaryllis.woff') format('woff'), url('../../assets/fonts/Amaryllis.otf') format('opentype'), url('../../assets/fonts/Amaryllis.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: 'Archane';
  src: url('../../assets/fonts/Archane.eot');
  src: url('../../assets/fonts/Archane.eot?#iefix') format('embedded-opentype'), url('../../assets/fonts/Archane.woff2') format('woff2'), url('../../assets/fonts/Archane.woff') format('woff'), url('../../assets/fonts/Archane.otf') format('opentype'), url('../../assets/fonts/Archane.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: 'Argentinian Nights';
  font-weight: 400;
  src: url('../../assets/fonts/ArgentinianNights.ttf');
}
@font-face {
  font-family: 'Arusher Sans';
  src: url('../../assets/fonts/Arusher-Sans.eot');
  src: url('../../assets/fonts/Arusher-Sans.eot?#iefix') format('embedded-opentype'), url('../../assets/fonts/Arusher-Sans.woff2') format('woff2'), url('../../assets/fonts/Arusher-Sans.woff') format('woff'), url('../../assets/fonts/Arusher-Sans.otf') format('opentype'), url('../../assets/fonts/Arusher-Sans.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: 'Arusher Script';
  src: url('../../assets/fonts/Arusher-Script.eot');
  src: url('../../assets/fonts/Arusher-Script.eot?#iefix') format('embedded-opentype'), url('../../assets/fonts/Arusher-Script.woff2') format('woff2'), url('../../assets/fonts/Arusher-Script.woff') format('woff'), url('../../assets/fonts/Arusher-Script.otf') format('opentype'), url('../../assets/fonts/Arusher-Script.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: 'Arvo';
  font-weight: 400;
  src: url('../../assets/fonts/Arvo-Regular.ttf');
}
@font-face {
  font-family: 'Arvo';
  font-weight: 400;
  font-style: italic;
  src: url('../../assets/fonts/Arvo-Italic.ttf');
}
@font-face {
  font-family: 'Arvo';
  font-weight: 700;
  src: url('../../assets/fonts/Arvo-Bold.ttf');
}
@font-face {
  font-family: 'Arvo';
  font-weight: 700;
  font-style: italic;
  src: url('../../assets/fonts/Arvo-BoldItalic.ttf');
}
@font-face {
  font-family: 'Astoria Sans';
  font-weight: 300;
  src: url('../../assets/fonts/AstoriaSans-Light.otf');
}
@font-face {
  font-family: 'Bad Script';
  font-weight: 400;
  src: url('../../assets/fonts/BadScript-Regular.ttf');
}
@font-face {
  font-family: 'Bellanaisa';
  font-style: normal;
  font-weight: 700;
  src: url('../../assets/fonts/Bellanaisa.ttf'), url('../../assets/fonts/Bellanaisa.eot') format('eot'), url('../../assets/fonts/Bellanaisa.woff') format('woff'), url('../../assets/fonts/Bellanaisa.woff2') format('woff2');
}
@font-face {
  font-family: 'Bellanaisa';
  font-style: normal;
  font-weight: 400;
  src: url('../../assets/fonts/Bellanaisa.ttf'), url('../../assets/fonts/Bellanaisa.eot') format('eot'), url('../../assets/fonts/Bellanaisa.woff') format('woff'), url('../../assets/fonts/Bellanaisa.woff2') format('woff2');
}
@font-face {
  font-family: 'Ball-Pen';
  font-weight: 400;
  src: url('../../assets/fonts/Ball-Pen-Regular.ttf');
}
@font-face {
  font-family: 'Ball-Pen-Extra';
  font-weight: 400;
  src: url('../../assets/fonts/Ball-Pen-Extra.ttf');
}
@font-face {
  font-family: 'Bagnard';
  src: url('../../assets/fonts/Bagnard-Regular.woff2') format('woff2'), url('../../assets/fonts/Bagnard-Regular.woff') format('woff'), url('../../assets/fonts/Bagnard-Regular.otf') format('opentype'), url('../../assets/fonts/Bagnard-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'Barlow';
  font-weight: 100;
  src: url('../../assets/fonts/Barlow-Thin.ttf');
}
@font-face {
  font-family: 'Barlow';
  font-weight: 100;
  font-style: italic;
  src: url('../../assets/fonts/Barlow-ThinItalic.ttf');
}
@font-face {
  font-family: 'Barlow';
  font-weight: 200;
  src: url('../../assets/fonts/Barlow-ExtraLight.ttf');
}
@font-face {
  font-family: 'Barlow';
  font-weight: 200;
  font-style: italic;
  src: url('../../assets/fonts/Barlow-ExtraLightItalic.ttf');
}
@font-face {
  font-family: 'Barlow';
  font-weight: 300;
  src: url('../../assets/fonts/Barlow-Light.ttf');
}
@font-face {
  font-family: 'Barlow';
  font-weight: 300;
  font-style: italic;
  src: url('../../assets/fonts/Barlow-LightItalic.ttf');
}
@font-face {
  font-family: 'Barlow';
  font-weight: 400;
  src: url('../../assets/fonts/Barlow-Regular.ttf');
}
@font-face {
  font-family: 'Barlow';
  font-weight: 400;
  font-style: italic;
  src: url('../../assets/fonts/Barlow-Italic.ttf');
}
@font-face {
  font-family: 'Barlow';
  font-weight: 500;
  src: url('../../assets/fonts/Barlow-Medium.ttf');
}
@font-face {
  font-family: 'Barlow';
  font-weight: 500;
  font-style: italic;
  src: url('../../assets/fonts/Barlow-MediumItalic.ttf');
}
@font-face {
  font-family: 'Barlow';
  font-weight: 600;
  src: url('../../assets/fonts/Barlow-SemiBold.ttf');
}
@font-face {
  font-family: 'Barlow';
  font-weight: 600;
  font-style: italic;
  src: url('../../assets/fonts/Barlow-SemiBoldItalic.ttf');
}
@font-face {
  font-family: 'Barlow';
  font-weight: 700;
  src: url('../../assets/fonts/Barlow-Bold.ttf');
}
@font-face {
  font-family: 'Barlow';
  font-weight: 700;
  font-style: italic;
  src: url('../../assets/fonts/Barlow-BoldItalic.ttf');
}
@font-face {
  font-family: 'Barlow';
  font-weight: 800;
  src: url('../../assets/fonts/Barlow-ExtraBold.ttf');
}
@font-face {
  font-family: 'Barlow';
  font-weight: 800;
  font-style: italic;
  src: url('../../assets/fonts/Barlow-ExtraBoldItalic.ttf');
}
@font-face {
  font-family: 'Barlow';
  font-weight: 900;
  src: url('../../assets/fonts/Barlow-Black.ttf');
}
@font-face {
  font-family: 'Barlow';
  font-weight: 900;
  font-style: italic;
  src: url('../../assets/fonts/Barlow-BlackItalic.ttf');
}
@font-face {
  font-family: 'Barlow Condensed';
  font-weight: 100;
  src: url('../../assets/fonts/BarlowCondensed-Thin.ttf');
}
@font-face {
  font-family: 'Barlow Condensed';
  font-weight: 100;
  font-style: italic;
  src: url('../../assets/fonts/BarlowCondensed-ThinItalic.ttf');
}
@font-face {
  font-family: 'Barlow Condensed';
  font-weight: 200;
  src: url('../../assets/fonts/BarlowCondensed-ExtraLight.ttf');
}
@font-face {
  font-family: 'Barlow Condensed';
  font-weight: 200;
  font-style: italic;
  src: url('../../assets/fonts/BarlowCondensed-ExtraLightItalic.ttf');
}
@font-face {
  font-family: 'Barlow Condensed';
  font-weight: 300;
  src: url('../../assets/fonts/BarlowCondensed-Light.ttf');
}
@font-face {
  font-family: 'Barlow Condensed';
  font-weight: 300;
  font-style: italic;
  src: url('../../assets/fonts/BarlowCondensed-LightItalic.ttf');
}
@font-face {
  font-family: 'Barlow Condensed';
  font-weight: 400;
  src: url('../../assets/fonts/BarlowCondensed-Regular.ttf');
}
@font-face {
  font-family: 'Barlow Condensed';
  font-weight: 400;
  font-style: italic;
  src: url('../../assets/fonts/BarlowCondensed-Italic.ttf');
}
@font-face {
  font-family: 'Barlow Condensed';
  font-weight: 500;
  src: url('../../assets/fonts/BarlowCondensed-Medium.ttf');
}
@font-face {
  font-family: 'Barlow Condensed';
  font-weight: 500;
  font-style: italic;
  src: url('../../assets/fonts/BarlowCondensed-MediumItalic.ttf');
}
@font-face {
  font-family: 'Barlow Condensed';
  font-weight: 600;
  src: url('../../assets/fonts/BarlowCondensed-SemiBold.ttf');
}
@font-face {
  font-family: 'Barlow Condensed';
  font-weight: 600;
  font-style: italic;
  src: url('../../assets/fonts/BarlowCondensed-SemiBoldItalic.ttf');
}
@font-face {
  font-family: 'Barlow Condensed';
  font-weight: 700;
  src: url('../../assets/fonts/BarlowCondensed-Bold.ttf');
}
@font-face {
  font-family: 'Barlow Condensed';
  font-weight: 700;
  font-style: italic;
  src: url('../../assets/fonts/BarlowCondensed-BoldItalic.ttf');
}
@font-face {
  font-family: 'Barlow Condensed';
  font-weight: 800;
  src: url('../../assets/fonts/BarlowCondensed-ExtraBold.ttf');
}
@font-face {
  font-family: 'Barlow Condensed';
  font-weight: 800;
  font-style: italic;
  src: url('../../assets/fonts/BarlowCondensed-ExtraBoldItalic.ttf');
}
@font-face {
  font-family: 'Barlow Condensed';
  font-weight: 900;
  src: url('../../assets/fonts/BarlowCondensed-Black.ttf');
}
@font-face {
  font-family: 'Barlow Condensed';
  font-weight: 900;
  font-style: italic;
  src: url('../../assets/fonts/BarlowCondensed-BlackItalic.ttf');
}
@font-face {
  font-family: 'Barlow Semi Condensed';
  font-weight: 100;
  src: url('../../assets/fonts/BarlowSemiCondensed-Thin.ttf');
}
@font-face {
  font-family: 'Barlow Semi Condensed';
  font-weight: 100;
  font-style: italic;
  src: url('../../assets/fonts/BarlowSemiCondensed-ThinItalic.ttf');
}
@font-face {
  font-family: 'Barlow Semi Condensed';
  font-weight: 200;
  src: url('../../assets/fonts/BarlowSemiCondensed-ExtraLight.ttf');
}
@font-face {
  font-family: 'Barlow Semi Condensed';
  font-weight: 200;
  font-style: italic;
  src: url('../../assets/fonts/BarlowSemiCondensed-ExtraLightItalic.ttf');
}
@font-face {
  font-family: 'Barlow Semi Condensed';
  font-weight: 300;
  src: url('../../assets/fonts/BarlowSemiCondensed-Light.ttf');
}
@font-face {
  font-family: 'Barlow Semi Condensed';
  font-weight: 300;
  font-style: italic;
  src: url('../../assets/fonts/BarlowSemiCondensed-LightItalic.ttf');
}
@font-face {
  font-family: 'Barlow Semi Condensed';
  font-weight: 400;
  src: url('../../assets/fonts/BarlowSemiCondensed-Regular.ttf');
}
@font-face {
  font-family: 'Barlow Semi Condensed';
  font-weight: 400;
  font-style: italic;
  src: url('../../assets/fonts/BarlowSemiCondensed-Italic.ttf');
}
@font-face {
  font-family: 'Barlow Semi Condensed';
  font-weight: 500;
  src: url('../../assets/fonts/BarlowSemiCondensed-Medium.ttf');
}
@font-face {
  font-family: 'Barlow Semi Condensed';
  font-weight: 500;
  font-style: italic;
  src: url('../../assets/fonts/BarlowSemiCondensed-MediumItalic.ttf');
}
@font-face {
  font-family: 'Barlow Semi Condensed';
  font-weight: 600;
  src: url('../../assets/fonts/BarlowSemiCondensed-SemiBold.ttf');
}
@font-face {
  font-family: 'Barlow Semi Condensed';
  font-weight: 600;
  font-style: italic;
  src: url('../../assets/fonts/BarlowSemiCondensed-SemiBoldItalic.ttf');
}
@font-face {
  font-family: 'Barlow Semi Condensed';
  font-weight: 700;
  src: url('../../assets/fonts/BarlowSemiCondensed-Bold.ttf');
}
@font-face {
  font-family: 'Barlow Semi Condensed';
  font-weight: 700;
  font-style: italic;
  src: url('../../assets/fonts/BarlowSemiCondensed-BoldItalic.ttf');
}
@font-face {
  font-family: 'Barlow Semi Condensed';
  font-weight: 800;
  src: url('../../assets/fonts/BarlowSemiCondensed-ExtraBold.ttf');
}
@font-face {
  font-family: 'Barlow Semi Condensed';
  font-weight: 800;
  font-style: italic;
  src: url('../../assets/fonts/BarlowSemiCondensed-ExtraBoldItalic.ttf');
}
@font-face {
  font-family: 'Barlow Semi Condensed';
  font-weight: 900;
  src: url('../../assets/fonts/BarlowSemiCondensed-Black.ttf');
}
@font-face {
  font-family: 'Barlow Semi Condensed';
  font-weight: 900;
  font-style: italic;
  src: url('../../assets/fonts/BarlowSemiCondensed-BlackItalic.ttf');
}
@font-face {
  font-family: 'Bentham';
  font-weight: 400;
  src: url('../../assets/fonts/Bentham-Regular.ttf');
}
@font-face {
  font-family: 'Billion Calligraphy';
  src: url('../../assets/fonts/Billion-Calligraphy-Regular.woff2') format('woff2'), url('../../assets/fonts/Billion-Calligraphy-Regular.woff') format('woff'), url('../../assets/fonts/Billion-Calligraphy-Regular.otf') format('opentype'), url('../../assets/fonts/Billion-Calligraphy-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'Breezeblocks';
  src: url('../../assets/fonts/Breezeblocks.eot');
  src: url('../../assets/fonts/Breezeblocks.eot?#iefix') format('embedded-opentype'), url('../../assets/fonts/Breezeblocks.woff2') format('woff2'), url('../../assets/fonts/Breezeblocks.woff') format('woff'), url('../../assets/fonts/Breezeblocks.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: 'Borest';
  font-weight: 400;
  src: url('../../assets/fonts/Borest-Regular.otf');
}
@font-face {
  font-family: 'Bowlby One SC';
  font-weight: 400;
  src: url('../../assets/fonts/BowlbyOneSC-Regular.ttf');
}
@font-face {
  font-family: 'Bungee';
  font-weight: 400;
  src: url('../../assets/fonts/Bungee-Regular.ttf');
}
@font-face {
  font-family: 'Caslon Graphique D';
  font-weight: 400;
  font-style: normal;
  src: url('../../assets/fonts/CaslonGraD.eot');
  src: url('../../assets/fonts/CaslonGraD.eot?#iefix') format('embedded-opentype'), url('../../assets/fonts/CaslonGraD.woff2') format('woff2'), url('../../assets/fonts/CaslonGraD.woff') format('woff'), url('../../assets/fonts/CaslonGraD.ttf') format('truetype');
}
@font-face {
  font-family: 'Cardo';
  font-weight: 400;
  src: url('../../assets/fonts/Cardo-Regular.ttf');
}
@font-face {
  font-family: 'Cardo';
  font-weight: 400;
  font-style: italic;
  src: url('../../assets/fonts/Cardo-Italic.ttf');
}
@font-face {
  font-family: 'Cardo';
  font-weight: 700;
  src: url('../../assets/fonts/Cardo-Bold.ttf');
}
@font-face {
  font-family: 'Clicker Script';
  font-weight: 400;
  src: url('../../assets/fonts/ClickerScript-Regular.ttf');
}
@font-face {
  font-family: 'Chronos Serif';
  font-weight: 400;
  font-style: normal;
  src: url('../../assets/fonts/ChronosSerif-Filled.otf'), url('../../assets/fonts/ChronosSerif-Filled.ttf') format('truetype'), url('../../assets/fonts/ChronosSerif-Filled.eot') format('eot'), url('../../assets/fonts/ChronosSerif-Filled.woff') format('woff'), url('../../assets/fonts/ChronosSerif-Filled.woff2') format('woff2');
}
@font-face {
  font-family: 'Coldiac';
  src: url('../../assets/fonts/Coldiac-Regular.woff2') format('woff2'), url('../../assets/fonts/Coldiac-Regular.woff') format('woff'), url('../../assets/fonts/Coldiac-Regular.otf') format('opentype'), url('../../assets/fonts/Coldiac-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'Coldiac';
  src: url('../../assets/fonts/Coldiac-Italic.woff2') format('woff2'), url('../../assets/fonts/Coldiac-Italic.woff') format('woff'), url('../../assets/fonts/Coldiac-Italic.otf') format('opentype'), url('../../assets/fonts/Coldiac-Italic.ttf') format('truetype');
  font-weight: normal;
  font-style: italic;
}
@font-face {
  font-family: 'Cookie';
  font-weight: 400;
  src: url('../../assets/fonts/Cookie-Regular.ttf');
}
@font-face {
  font-family: 'Cormorant Garamond';
  font-weight: 400;
  src: url('../../assets/fonts/CormorantGaramond-Regular.ttf');
}
@font-face {
  font-family: 'Cormorant Garamond';
  font-weight: 400;
  font-style: italic;
  src: url('../../assets/fonts/CormorantGaramond-Italic.ttf');
}
@font-face {
  font-family: 'Cormorant Garamond';
  font-weight: 700;
  src: url('../../assets/fonts/CormorantGaramond-Bold.ttf');
}
@font-face {
  font-family: 'Cormorant Garamond';
  font-weight: 700;
  font-style: italic;
  src: url('../../assets/fonts/CormorantGaramond-BoldItalic.ttf');
}
@font-face {
  font-family: 'Cormorant Garamond';
  font-weight: 300;
  src: url('../../assets/fonts/CormorantGaramond-Light.ttf');
}
@font-face {
  font-family: 'Cormorant Garamond';
  font-weight: 300;
  font-style: italic;
  src: url('../../assets/fonts/CormorantGaramond-LightItalic.ttf');
}
@font-face {
  font-family: 'Cormorant Garamond';
  font-weight: 500;
  src: url('../../assets/fonts/CormorantGaramond-Medium.ttf');
}
@font-face {
  font-family: 'Cormorant Garamond';
  font-weight: 500;
  font-style: italic;
  src: url('../../assets/fonts/CormorantGaramond-MediumItalic.ttf');
}
@font-face {
  font-family: 'Cormorant Garamond';
  font-weight: 600;
  src: url('../../assets/fonts/CormorantGaramond-SemiBold.ttf');
}
@font-face {
  font-family: 'Cormorant Garamond';
  font-weight: 600;
  font-style: italic;
  src: url('../../assets/fonts/CormorantGaramond-SemiBoldItalic.ttf');
}
@font-face {
  font-family: 'Cormorant Infant';
  font-weight: 300;
  src: url('../../assets/fonts/CormorantInfant-Light.ttf');
}
@font-face {
  font-family: 'Cormorant Infant';
  font-weight: 300;
  font-style: italic;
  src: url('../../assets/fonts/CormorantInfant-LightItalic.ttf');
}
@font-face {
  font-family: 'Cormorant Infant';
  font-weight: 400;
  src: url('../../assets/fonts/CormorantInfant-Regular.ttf');
}
@font-face {
  font-family: 'Cormorant Infant';
  font-weight: 400;
  font-style: italic;
  src: url('../../assets/fonts/CormorantInfant-Italic.ttf');
}
@font-face {
  font-family: 'Cormorant Infant';
  font-weight: 500;
  src: url('../../assets/fonts/CormorantInfant-Medium.ttf');
}
@font-face {
  font-family: 'Cormorant Infant';
  font-weight: 500;
  font-style: italic;
  src: url('../../assets/fonts/CormorantInfant-MediumItalic.ttf');
}
@font-face {
  font-family: 'Cormorant Infant';
  font-weight: 600;
  src: url('../../assets/fonts/CormorantInfant-SemiBold.ttf');
}
@font-face {
  font-family: 'Cormorant Infant';
  font-weight: 600;
  font-style: italic;
  src: url('../../assets/fonts/CormorantInfant-SemiBoldItalic.ttf');
}
@font-face {
  font-family: 'Cormorant Infant';
  font-weight: 700;
  src: url('../../assets/fonts/CormorantInfant-Bold.ttf');
}
@font-face {
  font-family: 'Cormorant Infant';
  font-weight: 700;
  font-style: italic;
  src: url('../../assets/fonts/CormorantInfant-BoldItalic.ttf');
}
@font-face {
  font-family: 'Cormorant Upright';
  font-weight: 300;
  src: url('../../assets/fonts/CormorantUpright-Light.ttf');
}
@font-face {
  font-family: 'Cormorant Upright';
  font-weight: 400;
  src: url('../../assets/fonts/CormorantUpright-Regular.ttf');
}
@font-face {
  font-family: 'Cormorant Upright';
  font-weight: 500;
  src: url('../../assets/fonts/CormorantUpright-Medium.ttf');
}
@font-face {
  font-family: 'Cormorant Upright';
  font-weight: 600;
  src: url('../../assets/fonts/CormorantUpright-SemiBold.ttf');
}
@font-face {
  font-family: 'Cormorant Upright';
  font-weight: 700;
  src: url('../../assets/fonts/CormorantUpright-Bold.ttf');
}
@font-face {
  font-family: 'Crimson Text';
  font-weight: 400;
  src: url('../../assets/fonts/CrimsonText-Regular.ttf');
}
@font-face {
  font-family: 'Crimson Text';
  font-weight: 400;
  font-style: italic;
  src: url('../../assets/fonts/CrimsonText-Italic.ttf');
}
@font-face {
  font-family: 'Crimson Text';
  font-weight: 600;
  src: url('../../assets/fonts/CrimsonText-SemiBold.ttf');
}
@font-face {
  font-family: 'Crimson Text';
  font-weight: 600;
  font-style: italic;
  src: url('../../assets/fonts/CrimsonText-SemiBoldItalic.ttf');
}
@font-face {
  font-family: 'Crimson Text';
  font-weight: 700;
  src: url('../../assets/fonts/CrimsonText-Bold.ttf');
}
@font-face {
  font-family: 'Crimson Text';
  font-weight: 700;
  font-style: italic;
  src: url('../../assets/fonts/CrimsonText-BoldItalic.ttf');
}
@font-face {
  font-family: 'Cutive';
  font-weight: 400;
  src: url('../../assets/fonts/Cutive-Regular.ttf');
}
@font-face {
  font-family: 'Cutive Mono';
  font-weight: 400;
  src: url('../../assets/fonts/CutiveMono-Regular.woff2') format('woff2'), url('../../assets/fonts/CutiveMono-Regular.woff') format('woff'), url('../../assets/fonts/CutiveMono-Regular.ttf') format('truetype');
}
@font-face {
  font-family: 'Damion';
  font-weight: 400;
  src: url('../../assets/fonts/Damion-Regular.ttf');
}
@font-face {
  font-family: 'Duke Charming';
  src: url('../../assets/fonts/Duke-Charming.eot');
  src: url('../../assets/fonts/Duke-Charming.eot?#iefix') format('embedded-opentype'), url('../../assets/fonts/Duke-Charming.woff2') format('woff2'), url('../../assets/fonts/Duke-Charming.woff') format('woff'), url('../../assets/fonts/Duke-Charming.svg') format('svg'), url('../../assets/fonts/Duke-Charming.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: 'EB Garamond';
  font-weight: 400;
  src: url('../../assets/fonts/EBGaramond-Regular.ttf');
}
@font-face {
  font-family: 'EB Garamond';
  font-weight: 400;
  font-style: italic;
  src: url('../../assets/fonts/EBGaramond-Italic.ttf');
}
@font-face {
  font-family: 'EB Garamond';
  font-weight: 500;
  src: url('../../assets/fonts/EBGaramond-Medium.ttf');
}
@font-face {
  font-family: 'EB Garamond';
  font-weight: 500;
  font-style: italic;
  src: url('../../assets/fonts/EBGaramond-MediumItalic.ttf');
}
@font-face {
  font-family: 'EB Garamond';
  font-weight: 600;
  src: url('../../assets/fonts/EBGaramond-SemiBold.ttf');
}
@font-face {
  font-family: 'EB Garamond';
  font-weight: 600;
  font-style: italic;
  src: url('../../assets/fonts/EBGaramond-SemiBoldItalic.ttf');
}
@font-face {
  font-family: 'EB Garamond';
  font-weight: 700;
  src: url('../../assets/fonts/EBGaramond-Bold.ttf');
}
@font-face {
  font-family: 'EB Garamond';
  font-weight: 700;
  font-style: italic;
  src: url('../../assets/fonts/EBGaramond-BoldItalic.ttf');
}
@font-face {
  font-family: 'EB Garamond';
  font-weight: 800;
  src: url('../../assets/fonts/EBGaramond-ExtraBold.ttf');
}
@font-face {
  font-family: 'EB Garamond';
  font-weight: 800;
  font-style: italic;
  src: url('../../assets/fonts/EBGaramond-ExtraBoldItalic.ttf');
}
@font-face {
  font-family: 'Euphoria Script';
  font-weight: 400;
  src: url('../../assets/fonts/EuphoriaScript-Regular.ttf');
}
@font-face {
  font-family: 'Floral Hearts';
  font-weight: 400;
  src: url('../../assets/fonts/FloralHearts.ttf');
}
@font-face {
  font-family: 'Gatwick';
  src: url('../../assets/fonts//Gatwick-Bold.eot');
  src: url('../../assets/fonts//Gatwick-Bold.eot?#iefix') format('embedded-opentype'), url('../../assets/fonts//Gatwick-Bold.woff2') format('woff2'), url('../../assets/fonts//Gatwick-Bold.woff') format('woff'), url('../../assets/fonts//Gatwick-Bold.otf') format('opentype'), url('../../assets/fonts//Gatwick-Bold.ttf') format('truetype');
  font-weight: 700;
  font-style: normal;
}
@font-face {
  font-family: 'GeorgiaBallpark Script';
  src: url('../../assets/fonts/GeorgiaBallpark-Script.eot');
  src: url('../../assets/fonts/GeorgiaBallpark-Script.eot?#iefix') format('embedded-opentype'), url('../../assets/fonts/GeorgiaBallpark-Script.woff2') format('woff2'), url('../../assets/fonts/GeorgiaBallpark-Script.woff') format('woff'), url('../../assets/fonts/GeorgiaBallpark-Script.otf') format('opentype'), url('../../assets/fonts/GeorgiaBallpark-Script.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: 'GeorgiaBallpark Serif';
  src: url('../../assets/fonts/GeorgiaBallpark-Serif.eot');
  src: url('../../assets/fonts/GeorgiaBallpark-Serif.eot?#iefix') format('embedded-opentype'), url('../../assets/fonts/GeorgiaBallpark-Serif.woff2') format('woff2'), url('../../assets/fonts/GeorgiaBallpark-Serif.woff') format('woff'), url('../../assets/fonts/GeorgiaBallpark-Serif.otf') format('opentype'), url('../../assets/fonts/GeorgiaBallpark-Serif.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: 'Gorgeous Serif Font';
  src: url('../../assets/fonts/Gorgeous.eot');
  src: url('../../assets/fonts/Gorgeous.eot?#iefix') format('embedded-opentype'), url('../../assets/fonts/Gorgeous.woff2') format('woff2'), url('../../assets/fonts/Gorgeous.woff') format('woff'), url('../../assets/fonts/Gorgeous.otf') format('opentype'), url('../../assets/fonts/Gorgeous.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: 'Goudy National';
  font-weight: 400;
  font-style: italic;
  src: url('../../assets/fonts/GoudyNational-Italic.ttf') format('truetype'), url('../../assets/fonts/GoudyNational-Italic.eot') format('eot'), url('../../assets/fonts/GoudyNational-Italic.woff') format('woff');
}
@font-face {
  font-family: 'Goudy National';
  font-weight: 400;
  src: url('../../assets/fonts/GoudyNational-Regular.ttf'), url('../../assets/fonts/GoudyNational-Regular.eot') format('eot'), url('../../assets/fonts/GoudyNational-Regular.woff') format('woff');
}
@font-face {
  font-family: 'Hatton';
  font-style: normal;
  font-weight: 400;
  src: url('../../assets/fonts/Hatton-Regular.otf'), url('../../assets/fonts/Hatton-Regular.ttf') format('truetype'), url('../../assets/fonts/Hatton-Regular.eot') format('eot'), url('../../assets/fonts/Hatton-Regular.woff') format('woff'), url('../../assets/fonts/Hatton-Regular.woff2') format('woff2');
}
@font-face {
  font-family: 'Hatton';
  font-style: normal;
  font-weight: 600;
  src: url('../../assets/fonts/Hatton-Semibold.otf'), url('../../assets/fonts/Hatton-Semibold.ttf') format('truetype'), url('../../assets/fonts/Hatton-Semibold.eot') format('eot'), url('../../assets/fonts/Hatton-Semibold.woff') format('woff'), url('../../assets/fonts/Hatton-Semibold.woff2') format('woff2');
}
@font-face {
  font-family: 'Homemade Apple';
  font-weight: 400;
  src: url('../../assets/fonts/HomemadeApple-Regular.ttf');
}
@font-face {
  font-family: 'IM Fell Double Pica';
  font-weight: 400;
  src: url('../../assets/fonts/IMFeDPrm28P.ttf');
}
@font-face {
  font-family: 'IM Fell Double Pica';
  font-weight: 400;
  font-style: italic;
  src: url('../../assets/fonts/IMFeDPit28P.ttf');
}
@font-face {
  font-family: 'Josefin Sans';
  font-weight: 100;
  src: url('../../assets/fonts/JosefinSans-Thin.ttf');
}
@font-face {
  font-family: 'Josefin Sans';
  font-weight: 100;
  font-style: italic;
  src: url('../../assets/fonts/JosefinSans-ThinItalic.ttf');
}
@font-face {
  font-family: 'Josefin Sans';
  font-weight: 300;
  src: url('../../assets/fonts/JosefinSans-Light.ttf');
}
@font-face {
  font-family: 'Josefin Sans';
  font-weight: 300;
  font-style: italic;
  src: url('../../assets/fonts/JosefinSans-LightItalic.ttf');
}
@font-face {
  font-family: 'Josefin Sans';
  font-weight: 400;
  src: url('../../assets/fonts/JosefinSans-Regular.ttf');
}
@font-face {
  font-family: 'Josefin Sans';
  font-weight: 400;
  font-style: italic;
  src: url('../../assets/fonts/JosefinSans-Italic.ttf');
}
@font-face {
  font-family: 'Josefin Sans';
  font-weight: 600;
  src: url('../../assets/fonts/JosefinSans-SemiBold.ttf');
}
@font-face {
  font-family: 'Josefin Sans';
  font-weight: 600;
  font-style: italic;
  src: url('../../assets/fonts/JosefinSans-SemiBoldItalic.ttf');
}
@font-face {
  font-family: 'Josefin Sans';
  font-weight: 700;
  src: url('../../assets/fonts/JosefinSans-Bold.ttf');
}
@font-face {
  font-family: 'Josefin Sans';
  font-weight: 700;
  font-style: italic;
  src: url('../../assets/fonts/JosefinSans-BoldItalic.ttf');
}
@font-face {
  font-family: 'Josefin Slab';
  font-weight: 100;
  src: url('../../assets/fonts/JosefinSlab-Thin.ttf');
}
@font-face {
  font-family: 'Josefin Slab';
  font-weight: 100;
  font-style: italic;
  src: url('../../assets/fonts/JosefinSlab-ThinItalic.ttf');
}
@font-face {
  font-family: 'Josefin Slab';
  font-weight: 300;
  src: url('../../assets/fonts/JosefinSlab-Light.ttf');
}
@font-face {
  font-family: 'Josefin Slab';
  font-weight: 300;
  font-style: italic;
  src: url('../../assets/fonts/JosefinSlab-LightItalic.ttf');
}
@font-face {
  font-family: 'Josefin Slab';
  font-weight: 400;
  src: url('../../assets/fonts/JosefinSlab-Regular.ttf');
}
@font-face {
  font-family: 'Josefin Slab';
  font-weight: 400;
  font-style: italic;
  src: url('../../assets/fonts/JosefinSlab-Italic.ttf');
}
@font-face {
  font-family: 'Josefin Slab';
  font-weight: 600;
  src: url('../../assets/fonts/JosefinSlab-SemiBold.ttf');
}
@font-face {
  font-family: 'Josefin Slab';
  font-weight: 600;
  font-style: italic;
  src: url('../../assets/fonts/JosefinSlab-SemiBoldItalic.ttf');
}
@font-face {
  font-family: 'Josefin Slab';
  font-weight: 700;
  src: url('../../assets/fonts/JosefinSlab-Bold.ttf');
}
@font-face {
  font-family: 'Josefin Slab';
  font-weight: 700;
  font-style: italic;
  src: url('../../assets/fonts/JosefinSlab-BoldItalic.ttf');
}
@font-face {
  font-family: 'Karla';
  font-weight: 400;
  src: url('../../assets/fonts/Karla-Regular.ttf');
}
@font-face {
  font-family: 'Karla';
  font-weight: 400;
  font-style: italic;
  src: url('../../assets/fonts/Karla-Italic.ttf');
}
@font-face {
  font-family: 'Karla';
  font-weight: 700;
  src: url('../../assets/fonts/Karla-Bold.ttf');
}
@font-face {
  font-family: 'Karla';
  font-weight: 700;
  font-style: italic;
  src: url('../../assets/fonts/Karla-BoldItalic.ttf');
}
@font-face {
  font-family: 'Katty Lynch';
  src: url('../../assets/fonts/Katty-Lynch.eot');
  src: url('../../assets/fonts/Katty-Lynch.eot?#iefix') format('embedded-opentype'), url('../../assets/fonts/Katty-Lynch.woff2') format('woff2'), url('../../assets/fonts/Katty-Lynch.woff') format('woff'), url('../../assets/fonts/Katty-Lynch.otf') format('opentype'), url('../../assets/fonts/Katty-Lynch.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: 'Katty Lynch Serif';
  src: url('../../assets/fonts/Katty-Lynch-Serif.eot');
  src: url('../../assets/fonts/Katty-Lynch-Serif.eot?#iefix') format('embedded-opentype'), url('../../assets/fonts/Katty-Lynch-Serif.woff2') format('woff2'), url('../../assets/fonts/Katty-Lynch-Serif.woff') format('woff'), url('../../assets/fonts/Katty-Lynch-Serif.otf') format('opentype'), url('../../assets/fonts/Katty-Lynch-Serif.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: 'Kristi';
  font-weight: 400;
  src: url('../../assets/fonts/Kristi-Regular.ttf');
}
@font-face {
  font-family: 'Libre Baskerville';
  font-weight: 400;
  src: url('../../assets/fonts/LibreBaskerville-Regular.ttf');
}
@font-face {
  font-family: 'Libre Baskerville';
  font-weight: 400;
  font-style: italic;
  src: url('../../assets/fonts/LibreBaskerville-Italic.ttf');
}
@font-face {
  font-family: 'Libre Baskerville';
  font-weight: 700;
  src: url('../../assets/fonts/LibreBaskerville-Bold.ttf');
}
@font-face {
  font-family: 'Limelight';
  font-weight: 400;
  src: url('../../assets/fonts/Limelight-Regular.ttf');
}
@font-face {
  font-family: 'Lora';
  font-weight: 400;
  src: url('../../assets/fonts/Lora-Regular.ttf');
}
@font-face {
  font-family: 'Lora';
  font-weight: 400;
  font-style: italic;
  src: url('../../assets/fonts/Lora-Italic.ttf');
}
@font-face {
  font-family: 'Lora';
  font-weight: 700;
  src: url('../../assets/fonts/Lora-Bold.ttf');
}
@font-face {
  font-family: 'Lora';
  font-weight: 700;
  font-style: italic;
  src: url('../../assets/fonts/Lora-BoldItalic.ttf');
}
@font-face {
  font-family: 'Marcellus';
  font-weight: 400;
  src: url('../../assets/fonts/Marcellus-Regular.ttf');
}
@font-face {
  font-family: 'Marcellus SC';
  font-weight: 400;
  src: url('../../assets/fonts/Marcellus-SC-Regular.ttf');
}
@font-face {
  font-family: 'Memento Mori';
  font-weight: 400;
  src: url('../../assets/fonts/MementoMori-Regular.ttf');
}
@font-face {
  font-family: 'Memories';
  font-weight: 400;
  font-style: normal;
  src: url('../../assets/fonts/Memories.eot');
  src: url('../../assets/fonts/Memories.eot?#iefix') format('embedded-opentype'), url('../../assets/fonts/Memories.woff') format('woff'), url('../../assets/fonts/Memories.woff2') format('woff2'), url('../../assets/fonts/Memories.otf') format('opentype'), url('../../assets/fonts/Memories.ttf') format('truetype');
}
@font-face {
  font-family: 'Memories Line';
  font-weight: 400;
  font-style: normal;
  src: url('../../assets/fonts/Memories-Line.eot');
  src: url('../../assets/fonts/Memories-Line.eot?#iefix') format('embedded-opentype'), url('../../assets/fonts/Memories-Line.woff') format('woff'), url('../../assets/fonts/Memories-Line.woff2') format('woff2'), url('../../assets/fonts/Memories-Line.otf') format('opentype'), url('../../assets/fonts/Memories-Line.ttf') format('truetype');
}
@font-face {
  font-family: 'Mereoleona';
  font-weight: 400;
  font-style: normal;
  src: url('../../assets/fonts/Mereoleona.eot');
  src: url('../../assets/fonts/Mereoleona.eot?#iefix') format('embedded-opentype'), url('../../assets/fonts/Mereoleona.woff') format('woff'), url('../../assets/fonts/Mereoleona.woff2') format('woff2'), url('../../assets/fonts/Mereoleona.otf') format('opentype'), url('../../assets/fonts/Mereoleona.ttf') format('truetype');
}
@font-face {
  font-family: 'Mereoleona Script';
  font-weight: 400;
  font-style: normal;
  src: url('../../assets/fonts/Mereoleona-Script.eot');
  src: url('../../assets/fonts/Mereoleona-Script.eot?#iefix') format('embedded-opentype'), url('../../assets/fonts/Mereoleona-Script.woff') format('woff'), url('../../assets/fonts/Mereoleona-Script.woff2') format('woff2'), url('../../assets/fonts/Mereoleona-Script.ttf') format('truetype');
}
@font-face {
  font-family: 'Mereoleona Script Alt';
  font-weight: 400;
  font-style: normal;
  src: url('../../assets/fonts/Mereoleona-Script-Alt.eot');
  src: url('../../assets/fonts/Mereoleona-Script-Alt.eot?#iefix') format('embedded-opentype'), url('../../assets/fonts/Mereoleona-Script-Alt.woff') format('woff'), url('../../assets/fonts/Mereoleona-Script-Alt.woff2') format('woff2'), url('../../assets/fonts/Mereoleona-Script-Alt.ttf') format('truetype');
}
@font-face {
  font-family: 'Microbrew One 3D';
  font-weight: 400;
  src: url('../../assets/fonts/Microbrew-One-3D-Regular.otf');
}
@font-face {
  font-family: 'Microbrew One Inline';
  font-weight: 400;
  src: url('../../assets/fonts/Microbrew-One-Inline-Regular.otf');
}
@font-face {
  font-family: 'Microbrew One';
  font-weight: 400;
  src: url('../../assets/fonts/Microbrew-One-Regular.otf');
}
@font-face {
  font-family: 'Microbrew Two';
  font-weight: 400;
  src: url('../../assets/fonts/Microbrew-Two-Regular.otf');
}
@font-face {
  font-family: 'Microbrew Two 3D';
  font-weight: 400;
  src: url('../../assets/fonts/Microbrew-Two-3D-Regular.otf');
}
@font-face {
  font-family: 'Microbrew Three 3D';
  font-weight: 400;
  src: url('../../assets/fonts/Microbrew-Three-3D-Regular.otf');
}
@font-face {
  font-family: 'Microbrew Three Inline';
  font-weight: 400;
  src: url('../../assets/fonts/Microbrew-Three-Inline-Regular.otf');
}
@font-face {
  font-family: 'Microbrew Three';
  font-weight: 400;
  src: url('../../assets/fonts/Microbrew-Three-Regular.otf');
}
@font-face {
  font-family: 'Mindfully';
  font-weight: 400;
  src: url('../../assets/fonts/Mindfully_Regular.ttf');
}
@font-face {
  font-family: 'Mindfully';
  font-weight: 400;
  font-style: italic;
  src: url('../../assets/fonts/Mindfully_Regular_Italic.ttf');
}
@font-face {
  font-family: 'Mindfully Alternate';
  font-weight: 400;
  src: url('../../assets/fonts/Mindfully-Alternate.ttf');
}
@font-face {
  font-family: 'Mindfully Alternate';
  font-weight: 400;
  font-style: italic;
  src: url('../../assets/fonts/Mindfully-Alternate-Italic.ttf');
}
@font-face {
  font-family: 'Mon Voir';
  src: url('../../assets/fonts/MonVoir-Regular.woff2') format('woff2'), url('../../assets/fonts/MonVoir-Regular.woff') format('woff'), url('../../assets/fonts/MonVoir-Regular.otf') format('opentype'), url('../../assets/fonts/MonVoir-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'Monsieur La Doulaise';
  font-weight: 400;
  src: url('../../assets/fonts/MonsieurLaDoulaise-Regular.ttf');
}
@font-face {
  font-family: 'Montserrat';
  font-weight: 100;
  src: url('../../assets/fonts/Montserrat-Thin.ttf');
}
@font-face {
  font-family: 'Montserrat';
  font-weight: 100;
  font-style: italic;
  src: url('../../assets/fonts/Montserrat-ThinItalic.ttf');
}
@font-face {
  font-family: 'Montserrat';
  font-weight: 200;
  src: url('../../assets/fonts/Montserrat-ExtraLight.ttf');
}
@font-face {
  font-family: 'Montserrat';
  font-weight: 200;
  font-style: italic;
  src: url('../../assets/fonts/Montserrat-ExtraLightItalic.ttf');
}
@font-face {
  font-family: 'Montserrat';
  font-weight: 300;
  src: url('../../assets/fonts/Montserrat-Light.ttf');
}
@font-face {
  font-family: 'Montserrat';
  font-weight: 300;
  font-style: italic;
  src: url('../../assets/fonts/Montserrat-LightItalic.ttf');
}
@font-face {
  font-family: 'Montserrat';
  font-weight: 400;
  src: url('../../assets/fonts/Montserrat-Regular.ttf');
}
@font-face {
  font-family: 'Montserrat';
  font-weight: 400;
  font-style: italic;
  src: url('../../assets/fonts/Montserrat-Italic.ttf');
}
@font-face {
  font-family: 'Montserrat';
  font-weight: 500;
  src: url('../../assets/fonts/Montserrat-Medium.ttf');
}
@font-face {
  font-family: 'Montserrat';
  font-weight: 500;
  font-style: italic;
  src: url('../../assets/fonts/Montserrat-MediumItalic.ttf');
}
@font-face {
  font-family: 'Montserrat';
  font-weight: 600;
  src: url('../../assets/fonts/Montserrat-SemiBold.ttf');
}
@font-face {
  font-family: 'Montserrat';
  font-weight: 600;
  font-style: italic;
  src: url('../../assets/fonts/Montserrat-SemiBoldItalic.ttf');
}
@font-face {
  font-family: 'Montserrat';
  font-weight: 700;
  src: url('../../assets/fonts/Montserrat-Bold.ttf');
}
@font-face {
  font-family: 'Montserrat';
  font-weight: 700;
  font-style: italic;
  src: url('../../assets/fonts/Montserrat-BoldItalic.ttf');
}
@font-face {
  font-family: 'Montserrat';
  font-weight: 800;
  src: url('../../assets/fonts/Montserrat-ExtraBold.ttf');
}
@font-face {
  font-family: 'Montserrat';
  font-weight: 800;
  font-style: italic;
  src: url('../../assets/fonts/Montserrat-ExtraBoldItalic.ttf');
}
@font-face {
  font-family: 'Montserrat';
  font-weight: 900;
  src: url('../../assets/fonts/Montserrat-Black.ttf');
}
@font-face {
  font-family: 'Montserrat';
  font-weight: 900;
  font-style: italic;
  src: url('../../assets/fonts/Montserrat-BlackItalic.ttf');
}
@font-face {
  font-family: 'Mrs Eaves Roman Small Caps';
  font-weight: 400;
  font-variant: small-caps;
  src: url('../../assets/fonts/MrsEaves-SmallCaps-Regular.otf');
}
@font-face {
  font-family: 'Mrs Eaves Roman All Small Caps';
  font-weight: 400;
  font-variant: all-small-caps;
  src: url('../../assets/fonts/MrsEaves-AllSmallCaps-Regular.otf');
}
@font-face {
  font-family: 'Ms Jollie';
  src: url('../../assets/fonts/Ms-Jollie-Regular.woff2') format('woff2'), url('../../assets/fonts/Ms-Jollie-Regular.woff') format('woff'), url('../../assets/fonts/Ms-Jollie-Regular.otf') format('opentype'), url('../../assets/fonts/Ms-Jollie-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'Muli';
  font-weight: 200;
  src: url('../../assets/fonts/Muli-ExtraLight.ttf');
}
@font-face {
  font-family: 'Muli';
  font-weight: 200;
  font-style: italic;
  src: url('../../assets/fonts/Muli-ExtraLightItalic.ttf');
}
@font-face {
  font-family: 'Muli';
  font-weight: 300;
  src: url('../../assets/fonts/Muli-Light.ttf');
}
@font-face {
  font-family: 'Muli';
  font-weight: 300;
  font-style: italic;
  src: url('../../assets/fonts/Muli-LightItalic.ttf');
}
@font-face {
  font-family: 'Muli';
  font-weight: 400;
  src: url('../../assets/fonts/Muli-Regular.ttf');
}
@font-face {
  font-family: 'Muli';
  font-weight: 400;
  font-style: italic;
  src: url('../../assets/fonts/Muli-Italic.ttf');
}
@font-face {
  font-family: 'Muli';
  font-weight: 600;
  src: url('../../assets/fonts/Muli-SemiBold.ttf');
}
@font-face {
  font-family: 'Muli';
  font-weight: 600;
  font-style: italic;
  src: url('../../assets/fonts/Muli-SemiBoldItalic.ttf');
}
@font-face {
  font-family: 'Muli';
  font-weight: 700;
  src: url('../../assets/fonts/Muli-Bold.ttf');
}
@font-face {
  font-family: 'Muli';
  font-weight: 700;
  font-style: italic;
  src: url('../../assets/fonts/Muli-BoldItalic.ttf');
}
@font-face {
  font-family: 'Muli';
  font-weight: 800;
  src: url('../../assets/fonts/Muli-ExtraBold.ttf');
}
@font-face {
  font-family: 'Muli';
  font-weight: 800;
  font-style: italic;
  src: url('../../assets/fonts/Muli-ExtraBoldItalic.ttf');
}
@font-face {
  font-family: 'Muli';
  font-weight: 900;
  src: url('../../assets/fonts/Muli-Black.ttf');
}
@font-face {
  font-family: 'Muli';
  font-weight: 900;
  font-style: italic;
  src: url('../../assets/fonts/Muli-BlackItalic.ttf');
}
@font-face {
  font-family: 'Nanum Myeongjo';
  font-weight: 400;
  src: url('../../assets/fonts/NanumMyeongjo-Regular.ttf');
}
@font-face {
  font-family: 'Nanum Myeongjo';
  font-weight: 700;
  src: url('../../assets/fonts/NanumMyeongjo-Bold.ttf');
}
@font-face {
  font-family: 'Nanum Myeongjo';
  font-weight: 800;
  src: url('../../assets/fonts/NanumMyeongjo-ExtraBold.ttf');
}
@font-face {
  font-family: 'Noirden Bold (TT)';
  src: url('../../assets/fonts//Noirden-Bold.eot');
  src: url('../../assets/fonts//Noirden-Bold.eot?#iefix') format('embedded-opentype'), url('../../assets/fonts//Noirden-Bold.woff2') format('woff2'), url('../../assets/fonts//Noirden-Bold.woff') format('woff'), url('../../assets/fonts//Noirden-Bold.otf') format('opentype'), url('../../assets/fonts//Noirden-Bold.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: 'Noirden Bold oblique (TT)';
  src: url('../../assets/fonts//Noirden-Bold-oblique.eot');
  src: url('../../assets/fonts//Noirden-Bold-oblique.eot?#iefix') format('embedded-opentype'), url('../../assets/fonts//Noirden-Bold-oblique.woff2') format('woff2'), url('../../assets/fonts//Noirden-Bold-oblique.woff') format('woff'), url('../../assets/fonts//Noirden-Bold-oblique.otf') format('opentype'), url('../../assets/fonts//Noirden-Bold-oblique.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: 'Noirden Extra Light (TT)';
  src: url('../../assets/fonts//Noirden-Extra-Light.eot');
  src: url('../../assets/fonts//Noirden-Extra-Light.eot?#iefix') format('embedded-opentype'), url('../../assets/fonts//Noirden-Extra-Light.woff2') format('woff2'), url('../../assets/fonts//Noirden-Extra-Light.woff') format('woff'), url('../../assets/fonts//Noirden-Extra-Light.otf') format('opentype'), url('../../assets/fonts//Noirden-Extra-Light.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: 'Noirden Extra Light Oblique (TT)';
  src: url('../../assets/fonts//Noirden-Extra-Light-oblique.eot');
  src: url('../../assets/fonts//Noirden-Extra-Light-oblique.eot?#iefix') format('embedded-opentype'), url('../../assets/fonts//Noirden-Extra-Light-oblique.woff2') format('woff2'), url('../../assets/fonts//Noirden-Extra-Light-oblique.woff') format('woff'), url('../../assets/fonts//Noirden-Extra-Light-oblique.otf') format('opentype'), url('../../assets/fonts//Noirden-Extra-Light-oblique.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: 'Noirden Light (TT)';
  src: url('../../assets/fonts//Noirden-Light.eot');
  src: url('../../assets/fonts//Noirden-Light.eot?#iefix') format('embedded-opentype'), url('../../assets/fonts//Noirden-Light.woff2') format('woff2'), url('../../assets/fonts//Noirden-Light.woff') format('woff'), url('../../assets/fonts//Noirden-Light.otf') format('opentype'), url('../../assets/fonts//Noirden-Light.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: 'Noirden Light Oblique (TT)';
  src: url('../../assets/fonts//Noirden-Light-oblique.eot');
  src: url('../../assets/fonts//Noirden-Light-oblique.eot?#iefix') format('embedded-opentype'), url('../../assets/fonts//Noirden-Light-oblique.woff2') format('woff2'), url('../../assets/fonts//Noirden-Light-oblique.woff') format('woff'), url('../../assets/fonts//Noirden-Light-oblique.otf') format('opentype'), url('../../assets/fonts//Noirden-Light-oblique.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: 'Noirden Regular (TT)';
  src: url('../../assets/fonts//Noirden-Regular.eot');
  src: url('../../assets/fonts//Noirden-Regular.eot?#iefix') format('embedded-opentype'), url('../../assets/fonts//Noirden-Regular.woff2') format('woff2'), url('../../assets/fonts//Noirden-Regular.woff') format('woff'), url('../../assets/fonts//Noirden-Regular.otf') format('opentype'), url('../../assets/fonts//Noirden-Regular.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: 'Noirden Regular Oblique (TT)';
  src: url('../../assets/fonts//Noirden-Regular-oblique.eot');
  src: url('../../assets/fonts//Noirden-Regular-oblique.eot?#iefix') format('embedded-opentype'), url('../../assets/fonts//Noirden-Regular-oblique.woff2') format('woff2'), url('../../assets/fonts//Noirden-Regular-oblique.woff') format('woff'), url('../../assets/fonts//Noirden-Regular-oblique.otf') format('opentype'), url('../../assets/fonts//Noirden-Regular-oblique.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: 'Noirden Semi Bold Oblique (TT)';
  src: url('../../assets/fonts//Noirden-SemiBold-oblique.eot');
  src: url('../../assets/fonts//Noirden-SemiBold-oblique.eot?#iefix') format('embedded-opentype'), url('../../assets/fonts//Noirden-SemiBold-oblique.woff2') format('woff2'), url('../../assets/fonts//Noirden-SemiBold-oblique.woff') format('woff'), url('../../assets/fonts//Noirden-SemiBold-oblique.otf') format('opentype'), url('../../assets/fonts//Noirden-SemiBold-oblique.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: 'Noirden Semi Bold (TT)';
  src: url('../../assets/fonts//Noirden-SemiBold.eot');
  src: url('../../assets/fonts//Noirden-SemiBold.eot?#iefix') format('embedded-opentype'), url('../../assets/fonts//Noirden-SemiBold.woff2') format('woff2'), url('../../assets/fonts//Noirden-SemiBold.woff') format('woff'), url('../../assets/fonts//Noirden-SemiBold.otf') format('opentype'), url('../../assets/fonts//Noirden-SemiBold.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: 'Noirden Thin Oblique (TT)';
  src: url('../../assets/fonts//Noirden-Thin-oblique.eot');
  src: url('../../assets/fonts//Noirden-Thin-oblique.eot?#iefix') format('embedded-opentype'), url('../../assets/fonts//Noirden-Thin-oblique.woff2') format('woff2'), url('../../assets/fonts//Noirden-Thin-oblique.woff') format('woff'), url('../../assets/fonts//Noirden-Thin-oblique.otf') format('opentype'), url('../../assets/fonts//Noirden-Thin-oblique.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: 'Noirden Thin (TT)';
  src: url('../../assets/fonts//Noirden-Thin.eot');
  src: url('../../assets/fonts//Noirden-Thin.eot?#iefix') format('embedded-opentype'), url('../../assets/fonts//Noirden-Thin.woff2') format('woff2'), url('../../assets/fonts//Noirden-Thin.woff') format('woff'), url('../../assets/fonts//Noirden-Thin.otf') format('opentype'), url('../../assets/fonts//Noirden-Thin.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: 'Norfolk Narrow';
  font-weight: 400;
  font-style: normal;
  src: url('../../assets/fonts/Norfolk-Narrow.eot');
  src: url('../../assets/fonts/Norfolk-Narrow.eot?#iefix') format('embedded-opentype'), url('../../assets/fonts/Norfolk-Narrow.woff') format('woff'), url('../../assets/fonts/Norfolk-Narrow.woff2') format('woff2'), url('../../assets/fonts/Norfolk-Narrow.otf') format('opentype'), url('../../assets/fonts/Norfolk-Narrow.ttf') format('truetype');
}
@font-face {
  font-family: 'Norfolk Serif';
  font-weight: 400;
  font-style: normal;
  src: url('../../assets/fonts/Norfolk-Serif.eot');
  src: url('../../assets/fonts/Norfolk-Serif.eot?#iefix') format('embedded-opentype'), url('../../assets/fonts/Norfolk-Serif.woff') format('woff'), url('../../assets/fonts/Norfolk-Serif.woff2') format('woff2'), url('../../assets/fonts/Norfolk-Serif.otf') format('opentype'), url('../../assets/fonts/Norfolk-Serif.ttf') format('truetype');
}
@font-face {
  font-family: 'Nunito Sans ExtraLight';
  font-weight: 200;
  font-style: normal;
  src: url('../../assets/fonts/NunitoSans-ExtraLight.ttf');
  src: url('../../assets/fonts/NunitoSans-ExtraLight.eot') format('embedded-opentype'), url('../../assets/fonts/NunitoSans-ExtraLight.woff2') format('woff2'), url('../../assets/fonts/NunitoSans-ExtraLight.woff') format('woff');
}
@font-face {
  font-family: 'Nunito Sans ExtraLight';
  font-weight: 200;
  font-style: italic;
  src: url('../../assets/fonts/NunitoSans-ExtraLightItalic.ttf');
  src: url('../../assets/fonts/NunitoSans-ExtraLightItalic.eot') format('embedded-opentype'), url('../../assets/fonts/NunitoSans-ExtraLightItalic.woff2') format('woff2'), url('../../assets/fonts/NunitoSans-ExtraLightItalic.woff') format('woff');
}
@font-face {
  font-family: 'Nunito Sans Light';
  font-weight: 300;
  font-style: normal;
  src: url('../../assets/fonts/NunitoSans-Light.ttf');
  src: url('../../assets/fonts/NunitoSans-Light.eot') format('embedded-opentype'), url('../../assets/fonts/NunitoSans-Light.woff2') format('woff2'), url('../../assets/fonts/NunitoSans-Light.woff') format('woff');
}
@font-face {
  font-family: 'Nunito Sans Light';
  font-weight: 300;
  font-style: italic;
  src: url('../../assets/fonts/NunitoSans-LightItalic.ttf');
  src: url('../../assets/fonts/NunitoSans-LightItalic.eot') format('embedded-opentype'), url('../../assets/fonts/NunitoSans-LightItalic.woff2') format('woff2'), url('../../assets/fonts/NunitoSans-LightItalic.woff') format('woff');
}
@font-face {
  font-family: 'Nunito Sans';
  font-weight: 400;
  font-style: normal;
  src: url('../../assets/fonts/NunitoSans-Regular.ttf');
  src: url('../../assets/fonts/NunitoSans-Regular.eot') format('embedded-opentype'), url('../../assets/fonts/NunitoSans-Regular.woff2') format('woff2'), url('../../assets/fonts/NunitoSans-Regular.woff') format('woff');
}
@font-face {
  font-family: 'Nunito Sans';
  font-weight: 400;
  font-style: italic;
  src: url('../../assets/fonts/NunitoSans-Italic.ttf');
  src: url('../../assets/fonts/NunitoSans-Italic.eot') format('embedded-opentype'), url('../../assets/fonts/NunitoSans-Italic.woff2') format('woff2'), url('../../assets/fonts/NunitoSans-Italic.woff') format('woff');
}
@font-face {
  font-family: 'Nunito Sans SemiBold';
  font-weight: 600;
  font-style: normal;
  src: url('../../assets/fonts/NunitoSans-SemiBold.ttf');
  src: url('../../assets/fonts/NunitoSans-SemiBold.eot') format('embedded-opentype'), url('../../assets/fonts/NunitoSans-SemiBold.woff2') format('woff2'), url('../../assets/fonts/NunitoSans-SemiBold.woff') format('woff');
}
@font-face {
  font-family: 'Nunito Sans SemiBold';
  font-weight: 600;
  font-style: italic;
  src: url('../../assets/fonts/NunitoSans-SemiBoldItalic.ttf');
  src: url('../../assets/fonts/NunitoSans-SemiBoldItalic.eot') format('embedded-opentype'), url('../../assets/fonts/NunitoSans-SemiBoldItalic.woff2') format('woff2'), url('../../assets/fonts/NunitoSans-SemiBoldItalic.woff') format('woff');
}
@font-face {
  font-family: 'Nunito Sans';
  font-weight: 700;
  font-style: normal;
  src: url('../../assets/fonts/NunitoSans-Bold.ttf');
  src: url('../../assets/fonts/NunitoSans-Bold.eot') format('embedded-opentype'), url('../../assets/fonts/NunitoSans-Bold.woff2') format('woff2'), url('../../assets/fonts/NunitoSans-Bold.woff') format('woff');
}
@font-face {
  font-family: 'Nunito Sans';
  font-weight: 700;
  font-style: italic;
  src: url('../../assets/fonts/NunitoSans-BoldItalic.ttf');
  src: url('../../assets/fonts/NunitoSans-BoldItalic.eot') format('embedded-opentype'), url('../../assets/fonts/NunitoSans-BoldItalic.woff2') format('woff2'), url('../../assets/fonts/NunitoSans-BoldItalic.woff') format('woff');
}
@font-face {
  font-family: 'Nunito Sans ExtraBold';
  font-weight: 800;
  font-style: normal;
  src: url('../../assets/fonts/NunitoSans-ExtraBold.ttf');
  src: url('../../assets/fonts/NunitoSans-ExtraBold.eot') format('embedded-opentype'), url('../../assets/fonts/NunitoSans-ExtraBold.woff2') format('woff2'), url('../../assets/fonts/NunitoSans-ExtraBold.woff') format('woff');
}
@font-face {
  font-family: 'Nunito Sans ExtraBold';
  font-weight: 800;
  font-style: italic;
  src: url('../../assets/fonts/NunitoSans-ExtraBoldItalic.ttf');
  src: url('../../assets/fonts/NunitoSans-ExtraBoldItalic.eot') format('embedded-opentype'), url('../../assets/fonts/NunitoSans-ExtraBoldItalic.woff2') format('woff2'), url('../../assets/fonts/NunitoSans-ExtraBoldItalic.woff') format('woff');
}
@font-face {
  font-family: 'Nunito Sans Black';
  font-weight: 900;
  font-style: normal;
  src: url('../../assets/fonts/NunitoSans-Black.ttf');
  src: url('../../assets/fonts/NunitoSans-Black.eot') format('embedded-opentype'), url('../../assets/fonts/NunitoSans-Black.woff2') format('woff2'), url('../../assets/fonts/NunitoSans-Black.woff') format('woff');
}
@font-face {
  font-family: 'Nunito Sans Black';
  font-weight: 900;
  font-style: italic;
  src: url('../../assets/fonts/NunitoSans-BlackItalic.ttf');
  src: url('../../assets/fonts/NunitoSans-BlackItalic.eot') format('embedded-opentype'), url('../../assets/fonts/NunitoSans-BlackItalic.woff2') format('woff2'), url('../../assets/fonts/NunitoSans-BlackItalic.woff') format('woff');
}
@font-face {
  font-family: 'Old Standard TT';
  font-weight: 400;
  font-style: italic;
  src: url('../../assets/fonts/OldStandard-Italic.ttf');
}
@font-face {
  font-family: 'Old Standard TT';
  font-weight: 700;
  font-style: normal;
  src: url('../../assets/fonts/OldStandard-Bold.ttf');
}
@font-face {
  font-family: 'Old Standard TT';
  font-weight: 400;
  font-style: normal;
  src: url('../../assets/fonts/OldStandard-Regular.ttf');
}
@font-face {
  font-family: 'Oliva Serif Font';
  src: url('../../assets/fonts/Oliva-Serif-Font.eot');
  src: url('../../assets/fonts/Oliva-Serif-Font.eot?#iefix') format('embedded-opentype'), url('../../assets/fonts/Oliva-Serif-Font.woff2') format('woff2'), url('../../assets/fonts/Oliva-Serif-Font.woff') format('woff'), url('../../assets/fonts/Oliva-Serif-Font.svg') format('svg'), url('../../assets/fonts/Oliva-Serif-Font.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: 'Oliva Serif Alts 1';
  src: url('../../assets/fonts/Oliva-Serif-Alts-1.eot');
  src: url('../../assets/fonts/Oliva-Serif-Alts-1.eot?#iefix') format('embedded-opentype'), url('../../assets/fonts/Oliva-Serif-Alts-1.woff2') format('woff2'), url('../../assets/fonts/Oliva-Serif-Alts-1.woff') format('woff'), url('../../assets/fonts/Oliva-Serif-Alts-1.otf') format('opentype'), url('../../assets/fonts/Oliva-Serif-Alts-1.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: 'Oliva Serif Alts 2';
  src: url('../../assets/fonts/Oliva-Serif-Alts-2.eot');
  src: url('../../assets/fonts/Oliva-Serif-Alts-2.eot?#iefix') format('embedded-opentype'), url('../../assets/fonts/Oliva-Serif-Alts-2.woff2') format('woff2'), url('../../assets/fonts/Oliva-Serif-Alts-2.woff') format('woff'), url('../../assets/fonts/Oliva-Serif-Alts-2.otf') format('opentype'), url('../../assets/fonts/Oliva-Serif-Alts-2.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: 'Open Sans';
  font-weight: 300;
  src: url('../../assets/fonts/OpenSans-Light.ttf');
}
@font-face {
  font-family: 'Open Sans';
  font-weight: 300;
  font-style: italic;
  src: url('../../assets/fonts/OpenSans-LightItalic.ttf');
}
@font-face {
  font-family: 'Open Sans';
  font-weight: 400;
  src: url('../../assets/fonts/OpenSans-Regular.ttf');
}
@font-face {
  font-family: 'Open Sans';
  font-weight: 400;
  font-style: italic;
  src: url('../../assets/fonts/OpenSans-Italic.ttf');
}
@font-face {
  font-family: 'Open Sans';
  font-weight: 600;
  src: url('../../assets/fonts/OpenSans-SemiBold.ttf');
}
@font-face {
  font-family: 'Open Sans';
  font-weight: 600;
  font-style: italic;
  src: url('../../assets/fonts/OpenSans-SemiBoldItalic.ttf');
}
@font-face {
  font-family: 'Open Sans';
  font-weight: 700;
  src: url('../../assets/fonts/OpenSans-Bold.ttf');
}
@font-face {
  font-family: 'Open Sans';
  font-weight: 700;
  font-style: italic;
  src: url('../../assets/fonts/OpenSans-BoldItalic.ttf');
}
@font-face {
  font-family: 'Open Sans';
  font-weight: 800;
  src: url('../../assets/fonts/OpenSans-ExtraBold.ttf');
}
@font-face {
  font-family: 'Open Sans';
  font-weight: 800;
  font-style: italic;
  src: url('../../assets/fonts/OpenSans-ExtraBoldItalic.ttf');
}
@font-face {
  font-family: 'Optima LT Pro';
  font-weight: 700;
  src: url('../../assets/fonts/OptimaLTPro-Bold.otf');
}
@font-face {
  font-family: 'Optima LT Pro';
  font-weight: 400;
  src: url('../../assets/fonts/OptimaLTPro-Regular.otf');
}
@font-face {
  font-family: 'Parisienne';
  font-weight: 400;
  src: url('../../assets/fonts/Parisienne-Regular.ttf');
}
@font-face {
  font-family: 'Petit Formal Script';
  font-weight: 400;
  src: url('../../assets/fonts/PetitFormalScript-Regular.ttf');
}
@font-face {
  font-family: 'Pinyon Script';
  font-weight: 400;
  src: url('../../assets/fonts/PinyonScript-Regular.ttf');
}
@font-face {
  font-family: 'Playfair Display';
  font-weight: 400;
  src: url('../../assets/fonts/PlayfairDisplay-Regular.ttf');
}
@font-face {
  font-family: 'Playfair Display';
  font-weight: 400;
  font-style: italic;
  src: url('../../assets/fonts/PlayfairDisplay-Italic.ttf');
}
@font-face {
  font-family: 'Playfair Display';
  font-weight: 700;
  src: url('../../assets/fonts/PlayfairDisplay-Bold.ttf');
}
@font-face {
  font-family: 'Playfair Display';
  font-weight: 700;
  font-style: italic;
  src: url('../../assets/fonts/PlayfairDisplay-BoldItalic.ttf');
}
@font-face {
  font-family: 'Playfair Display';
  font-weight: 900;
  src: url('../../assets/fonts/PlayfairDisplay-Black.ttf');
}
@font-face {
  font-family: 'Playfair Display';
  font-weight: 900;
  font-style: italic;
  src: url('../../assets/fonts/PlayfairDisplay-BlackItalic.ttf');
}
@font-face {
  font-family: 'Poiret One';
  font-weight: 400;
  src: url('../../assets/fonts/PoiretOne-Regular.ttf');
}
@font-face {
  font-family: 'Playball';
  font-weight: 400;
  src: url('../../assets/fonts/Playball-Regular.ttf');
}
@font-face {
  font-family: 'Poppins';
  font-weight: 100;
  src: url('../../assets/fonts/Poppins-Thin.ttf');
}
@font-face {
  font-family: 'Poppins';
  font-weight: 100;
  font-style: italic;
  src: url('../../assets/fonts/Poppins-ThinItalic.ttf');
}
@font-face {
  font-family: 'Poppins';
  font-weight: 200;
  src: url('../../assets/fonts/Poppins-ExtraLight.ttf');
}
@font-face {
  font-family: 'Poppins';
  font-weight: 200;
  font-style: italic;
  src: url('../../assets/fonts/Poppins-ExtraLightItalic.ttf');
}
@font-face {
  font-family: 'Poppins';
  font-weight: 300;
  src: url('../../assets/fonts/Poppins-Light.ttf');
}
@font-face {
  font-family: 'Poppins';
  font-weight: 300;
  font-style: italic;
  src: url('../../assets/fonts/Poppins-LightItalic.ttf');
}
@font-face {
  font-family: 'Poppins';
  font-weight: 400;
  src: url('../../assets/fonts/Poppins-Regular.ttf');
}
@font-face {
  font-family: 'Poppins';
  font-weight: 400;
  font-style: italic;
  src: url('../../assets/fonts/Poppins-Italic.ttf');
}
@font-face {
  font-family: 'Poppins';
  font-weight: 500;
  src: url('../../assets/fonts/Poppins-Medium.ttf');
}
@font-face {
  font-family: 'Poppins';
  font-weight: 500;
  font-style: italic;
  src: url('../../assets/fonts/Poppins-MediumItalic.ttf');
}
@font-face {
  font-family: 'Poppins';
  font-weight: 600;
  src: url('../../assets/fonts/Poppins-SemiBold.ttf');
}
@font-face {
  font-family: 'Poppins';
  font-weight: 600;
  font-style: italic;
  src: url('../../assets/fonts/Poppins-SemiBoldItalic.ttf');
}
@font-face {
  font-family: 'Poppins';
  font-weight: 700;
  src: url('../../assets/fonts/Poppins-Bold.ttf');
}
@font-face {
  font-family: 'Poppins';
  font-weight: 700;
  font-style: italic;
  src: url('../../assets/fonts/Poppins-BoldItalic.ttf');
}
@font-face {
  font-family: 'Poppins';
  font-weight: 800;
  src: url('../../assets/fonts/Poppins-ExtraBold.ttf');
}
@font-face {
  font-family: 'Poppins';
  font-weight: 800;
  font-style: italic;
  src: url('../../assets/fonts/Poppins-ExtraBoldItalic.ttf');
}
@font-face {
  font-family: 'Poppins';
  font-weight: 900;
  src: url('../../assets/fonts/Poppins-Black.ttf');
}
@font-face {
  font-family: 'Poppins';
  font-weight: 900;
  font-style: italic;
  src: url('../../assets/fonts/Poppins-BlackItalic.ttf');
}
@font-face {
  font-family: 'Potregi';
  src: url('../../assets/fonts/Potregi.eot');
  src: url('../../assets/fonts/Potregi.eot?#iefix') format('embedded-opentype'), url('../../assets/fonts/Potregi.woff2') format('woff2'), url('../../assets/fonts/Potregi.woff') format('woff'), url('../../assets/fonts/Potregi.otf') format('opentype'), url('../../assets/fonts/Potregi.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: 'Prata';
  font-weight: 400;
  src: url('../../assets/fonts/Prata-Regular.ttf');
}
@font-face {
  font-family: 'Proxima Nova';
  font-weight: 300;
  src: url('../../assets/fonts/ProximaNova-Light.otf'), url('../../assets/fonts/ProximaNova-Light.eot') format('embedded-opentype'), url('../../assets/fonts/ProximaNova-Light.woff2') format('woff2'), url('../../assets/fonts/ProximaNova-Light.woff') format('woff'), url('../../assets/fonts/ProximaNova-Light.ttf') format('truetype');
}
@font-face {
  font-family: 'Proxima Nova';
  font-weight: 300;
  font-style: italic;
  src: url('../../assets/fonts/ProximaNova-LightItalic.otf'), url('../../assets/fonts/ProximaNova-LightItalic.eot') format('embedded-opentype'), url('../../assets/fonts/ProximaNova-LightItalic.woff2') format('woff2'), url('../../assets/fonts/ProximaNova-LightItalic.woff') format('woff'), url('../../assets/fonts/ProximaNova-LightItalic.ttf') format('truetype');
}
@font-face {
  font-family: 'Proxima Nova';
  font-weight: 400;
  src: url('../../assets/fonts/ProximaNova-Regular.otf'), url('../../assets/fonts/ProximaNova-Regular.eot') format('embedded-opentype'), url('../../assets/fonts/ProximaNova-Regular.woff2') format('woff2'), url('../../assets/fonts/ProximaNova-Regular.woff') format('woff'), url('../../assets/fonts/ProximaNova-Regular.ttf') format('truetype');
}
@font-face {
  font-family: 'Proxima Nova';
  font-weight: 400;
  font-style: italic;
  src: url('../../assets/fonts/ProximaNova-RegularItalic.otf'), url('../../assets/fonts/ProximaNova-RegularItalic.eot') format('embedded-opentype'), url('../../assets/fonts/ProximaNova-RegularItalic.woff2') format('woff2'), url('../../assets/fonts/ProximaNova-RegularItalic.woff') format('woff'), url('../../assets/fonts/ProximaNova-RegularItalic.ttf') format('truetype');
}
@font-face {
  font-family: 'Proxima Nova';
  font-weight: 600;
  src: url('../../assets/fonts/ProximaNova-Semibold.otf'), url('../../assets/fonts/ProximaNova-Semibold.eot') format('embedded-opentype'), url('../../assets/fonts/ProximaNova-Semibold.woff2') format('woff2'), url('../../assets/fonts/ProximaNova-Semibold.woff') format('woff'), url('../../assets/fonts/ProximaNova-Semibold.ttf') format('truetype');
}
@font-face {
  font-family: 'Proxima Nova';
  font-weight: 600;
  font-style: italic;
  src: url('../../assets/fonts/ProximaNova-SemiboldItalic.otf'), url('../../assets/fonts/ProximaNova-SemiboldItalic.eot') format('embedded-opentype'), url('../../assets/fonts/ProximaNova-SemiboldItalic.woff2') format('woff2'), url('../../assets/fonts/ProximaNova-SemiboldItalic.woff') format('woff'), url('../../assets/fonts/ProximaNova-SemiboldItalic.ttf') format('truetype');
}
@font-face {
  font-family: 'Proxima Nova';
  font-weight: 700;
  src: url('../../assets/fonts/ProximaNova-Bold.otf'), url('../../assets/fonts/ProximaNova-Bold.eot') format('embedded-opentype'), url('../../assets/fonts/ProximaNova-Bold.woff2') format('woff2'), url('../../assets/fonts/ProximaNova-Bold.woff') format('woff'), url('../../assets/fonts/ProximaNova-Bold.ttf') format('truetype');
}
@font-face {
  font-family: 'Proxima Nova';
  font-weight: 700;
  font-style: italic;
  src: url('../../assets/fonts/ProximaNova-BoldIt.otf'), url('../../assets/fonts/ProximaNova-BoldIt.eot') format('embedded-opentype'), url('../../assets/fonts/ProximaNova-BoldIt.woff2') format('woff2'), url('../../assets/fonts/ProximaNova-BoldIt.woff') format('woff'), url('../../assets/fonts/ProximaNova-BoldIt.ttf') format('truetype');
}
@font-face {
  font-family: 'Proxima Nova';
  font-weight: 800;
  src: url('../../assets/fonts/ProximaNova-Extrabold.otf'), url('../../assets/fonts/ProximaNova-Extrabold.eot') format('embedded-opentype'), url('../../assets/fonts/ProximaNova-Extrabold.woff2') format('woff2'), url('../../assets/fonts/ProximaNova-Extrabold.woff') format('woff'), url('../../assets/fonts/ProximaNova-Extrabold.ttf') format('truetype');
}
@font-face {
  font-family: 'Proxima Nova';
  font-weight: 900;
  src: url('../../assets/fonts/ProximaNova-Black.otf'), url('../../assets/fonts/ProximaNova-Black.eot') format('embedded-opentype'), url('../../assets/fonts/ProximaNova-Black.woff2') format('woff2'), url('../../assets/fonts/ProximaNova-Black.woff') format('woff'), url('../../assets/fonts/ProximaNova-Black.ttf') format('truetype');
}
@font-face {
  font-family: 'Quarzo';
  font-weight: 400;
  src: url('../../assets/fonts/Quarzo-Regular.otf');
}
@font-face {
  font-family: 'Quattrocento';
  font-weight: 400;
  src: url('../../assets/fonts/Quattrocento-Regular.ttf');
}
@font-face {
  font-family: 'Quattrocento';
  font-weight: 700;
  src: url('../../assets/fonts/Quattrocento-Bold.ttf');
}
@font-face {
  font-family: 'Quattrocento Sans';
  font-weight: 400;
  src: url('../../assets/fonts/QuattrocentoSans-Regular.ttf');
}
@font-face {
  font-family: 'Quattrocento Sans';
  font-weight: 400;
  font-style: italic;
  src: url('../../assets/fonts/QuattrocentoSans-Italic.ttf');
}
@font-face {
  font-family: 'Quattrocento Sans';
  font-weight: 700;
  src: url('../../assets/fonts/QuattrocentoSans-Bold.ttf');
}
@font-face {
  font-family: 'Quattrocento Sans';
  font-weight: 700;
  font-style: italic;
  src: url('../../assets/fonts/QuattrocentoSans-BoldItalic.ttf');
}
@font-face {
  font-family: 'Questrial';
  font-weight: 400;
  src: url('../../assets/fonts/Questrial-Regular.ttf');
}
@font-face {
  font-family: 'Quicksand';
  font-weight: 300;
  src: url('../../assets/fonts/Quicksand-Light.ttf');
}
@font-face {
  font-family: 'Quicksand';
  font-weight: 400;
  src: url('../../assets/fonts/Quicksand-Regular.ttf');
}
@font-face {
  font-family: 'Quicksand';
  font-weight: 500;
  src: url('../../assets/fonts/Quicksand-Medium.ttf');
}
@font-face {
  font-family: 'Quicksand';
  font-weight: 700;
  src: url('../../assets/fonts/Quicksand-Bold.ttf');
}
@font-face {
  font-family: 'Quiche Flare';
  font-weight: 500;
  font-style: italic;
  src: url('../../assets/fonts/Quiche-Flare-Medium-Italic.eot');
  src: url('../../assets/fonts/Quiche-Flare-Medium-Italic.eot?#iefix') format('embedded-opentype'), url('../../assets/fonts/Quiche-Flare-Medium-Italic.woff') format('woff'), url('../../assets/fonts/Quiche-Flare-Medium-Italic.woff2') format('woff2'), url('../../assets/fonts/Quiche-Flare-Medium-Italic.otf') format('opentype'), url('../../assets/fonts/Quiche-Flare-Medium-Italic.ttf') format('truetype');
}
@font-face {
  font-family: 'Quiche Flare';
  font-weight: 100;
  font-style: italic;
  src: url('../../assets/fonts/Quiche-Flare-Thin-Italic.eot');
  src: url('../../assets/fonts/Quiche-Flare-Thin-Italic.eot?#iefix') format('embedded-opentype'), url('../../assets/fonts/Quiche-Flare-Thin-Italic.woff') format('woff'), url('../../assets/fonts/Quiche-Flare-Thin-Italic.woff2') format('woff2'), url('../../assets/fonts/Quiche-Flare-Thin-Italic.otf') format('opentype'), url('../../assets/fonts/Quiche-Flare-Thin-Italic.ttf') format('truetype');
}
@font-face {
  font-family: 'Quiche Flare';
  font-weight: 800;
  font-style: italic;
  src: url('../../assets/fonts/Quiche-Flare-ExtraBold-Italic.eot');
  src: url('../../assets/fonts/Quiche-Flare-ExtraBold-Italic.eot?#iefix') format('embedded-opentype'), url('../../assets/fonts/Quiche-Flare-ExtraBold-Italic.woff') format('woff'), url('../../assets/fonts/Quiche-Flare-ExtraBold-Italic.woff2') format('woff2'), url('../../assets/fonts/Quiche-Flare-ExtraBold-Italic.otf') format('opentype'), url('../../assets/fonts/Quiche-Flare-ExtraBold-Italic.ttf') format('truetype');
}
@font-face {
  font-family: 'Quiche Flare';
  font-weight: 700;
  font-style: italic;
  src: url('../../assets/fonts/Quiche-Flare-Bold-Italic.eot');
  src: url('../../assets/fonts/Quiche-Flare-Bold-Italic.eot?#iefix') format('embedded-opentype'), url('../../assets/fonts/Quiche-Flare-Bold-Italic.woff') format('woff'), url('../../assets/fonts/Quiche-Flare-Bold-Italic.woff2') format('woff2'), url('../../assets/fonts/Quiche-Flare-Bold-Italic.otf') format('opentype'), url('../../assets/fonts/Quiche-Flare-Bold-Italic.ttf') format('truetype');
}
@font-face {
  font-family: 'Quiche Flare';
  font-weight: 900;
  font-style: italic;
  src: url('../../assets/fonts/Quiche-Flare-Black-Italic.eot');
  src: url('../../assets/fonts/Quiche-Flare-Black-Italic.eot?#iefix') format('embedded-opentype'), url('../../assets/fonts/Quiche-Flare-Black-Italic.woff') format('woff'), url('../../assets/fonts/Quiche-Flare-Black-Italic.woff2') format('woff2'), url('../../assets/fonts/Quiche-Flare-Black-Italic.otf') format('opentype'), url('../../assets/fonts/Quiche-Flare-Black-Italic.ttf') format('truetype');
}
@font-face {
  font-family: 'Quiche Flare';
  font-weight: 400;
  font-style: italic;
  src: url('../../assets/fonts/Quiche-Flare-Italic.eot');
  src: url('../../assets/fonts/Quiche-Flare-Italic.eot?#iefix') format('embedded-opentype'), url('../../assets/fonts/Quiche-Flare-Italic.woff') format('woff'), url('../../assets/fonts/Quiche-Flare-Italic.woff2') format('woff2'), url('../../assets/fonts/Quiche-Flare-Italic.otf') format('opentype'), url('../../assets/fonts/Quiche-Flare-Italic.ttf') format('truetype');
}
@font-face {
  font-family: 'Quiche Flare';
  font-weight: 300;
  font-style: italic;
  src: url('../../assets/fonts/Quiche-Flare-Light-Italic.eot');
  src: url('../../assets/fonts/Quiche-Flare-Light-Italic.eot?#iefix') format('embedded-opentype'), url('../../assets/fonts/Quiche-Flare-Light-Italic.woff') format('woff'), url('../../assets/fonts/Quiche-Flare-Light-Italic.woff2') format('woff2'), url('../../assets/fonts/Quiche-Flare-Light-Italic.otf') format('opentype'), url('../../assets/fonts/Quiche-Flare-Light-Italic.ttf') format('truetype');
}
@font-face {
  font-family: 'Quiche Flare';
  font-weight: 500;
  font-style: normal;
  src: url('../../assets/fonts/Quiche-Flare-Medium.eot');
  src: url('../../assets/fonts/Quiche-Flare-Medium.eot?#iefix') format('embedded-opentype'), url('../../assets/fonts/Quiche-Flare-Medium.woff') format('woff'), url('../../assets/fonts/Quiche-Flare-Medium.woff2') format('woff2'), url('../../assets/fonts/Quiche-Flare-Medium.otf') format('opentype'), url('../../assets/fonts/Quiche-Flare-Medium.ttf') format('truetype');
}
@font-face {
  font-family: 'Quiche Flare';
  font-weight: 100;
  font-style: normal;
  src: url('../../assets/fonts/Quiche-Flare-Thin.eot');
  src: url('../../assets/fonts/Quiche-Flare-Thin.eot?#iefix') format('embedded-opentype'), url('../../assets/fonts/Quiche-Flare-Thin.woff') format('woff'), url('../../assets/fonts/Quiche-Flare-Thin.woff2') format('woff2'), url('../../assets/fonts/Quiche-Flare-Thin.otf') format('opentype'), url('../../assets/fonts/Quiche-Flare-Thin.ttf') format('truetype');
}
@font-face {
  font-family: 'Quiche Flare';
  font-weight: 300;
  font-style: normal;
  src: url('../../assets/fonts/Quiche-Flare-Light.eot');
  src: url('../../assets/fonts/Quiche-Flare-Light.eot?#iefix') format('embedded-opentype'), url('../../assets/fonts/Quiche-Flare-Light.woff') format('woff'), url('../../assets/fonts/Quiche-Flare-Light.woff2') format('woff2'), url('../../assets/fonts/Quiche-Flare-Light.otf') format('opentype'), url('../../assets/fonts/Quiche-Flare-Light.ttf') format('truetype');
}
@font-face {
  font-family: 'Quiche Flare';
  font-weight: 400;
  font-style: normal;
  src: url('../../assets/fonts/Quiche-Flare-Regular.eot');
  src: url('../../assets/fonts/Quiche-Flare-Regular.eot?#iefix') format('embedded-opentype'), url('../../assets/fonts/Quiche-Flare-Regular.woff') format('woff'), url('../../assets/fonts/Quiche-Flare-Regular.woff2') format('woff2'), url('../../assets/fonts/Quiche-Flare-Regular.otf') format('opentype'), url('../../assets/fonts/Quiche-Flare-Regular.ttf') format('truetype');
}
@font-face {
  font-family: 'Quiche Flare';
  font-weight: 900;
  font-style: normal;
  src: url('../../assets/fonts/Quiche-Flare-Black.eot');
  src: url('../../assets/fonts/Quiche-Flare-Black.eot?#iefix') format('embedded-opentype'), url('../../assets/fonts/Quiche-Flare-Black.woff') format('woff'), url('../../assets/fonts/Quiche-Flare-Black.woff2') format('woff2'), url('../../assets/fonts/Quiche-Flare-Black.otf') format('opentype'), url('../../assets/fonts/Quiche-Flare-Black.ttf') format('truetype');
}
@font-face {
  font-family: 'Quiche Flare';
  font-weight: 800;
  font-style: normal;
  src: url('../../assets/fonts/Quiche-Flare-ExtraBold.eot');
  src: url('../../assets/fonts/Quiche-Flare-ExtraBold.eot?#iefix') format('embedded-opentype'), url('../../assets/fonts/Quiche-Flare-ExtraBold.woff') format('woff'), url('../../assets/fonts/Quiche-Flare-ExtraBold.woff2') format('woff2'), url('../../assets/fonts/Quiche-Flare-ExtraBold.otf') format('opentype'), url('../../assets/fonts/Quiche-Flare-ExtraBold.ttf') format('truetype');
}
@font-face {
  font-family: 'Quiche Flare';
  font-weight: 700;
  font-style: normal;
  src: url('../../assets/fonts/Quiche-Flare-Bold.eot');
  src: url('../../assets/fonts/Quiche-Flare-Bold.eot?#iefix') format('embedded-opentype'), url('../../assets/fonts/Quiche-Flare-Bold.woff') format('woff'), url('../../assets/fonts/Quiche-Flare-Bold.woff2') format('woff2'), url('../../assets/fonts/Quiche-Flare-Bold.otf') format('opentype'), url('../../assets/fonts/Quiche-Flare-Bold.ttf') format('truetype');
}
@font-face {
  font-family: 'Raleway';
  font-weight: 100;
  src: url('../../assets/fonts/Raleway-Thin.ttf');
}
@font-face {
  font-family: 'Raleway';
  font-weight: 100;
  font-style: italic;
  src: url('../../assets/fonts/Raleway-ThinItalic.ttf');
}
@font-face {
  font-family: 'Raleway';
  font-weight: 200;
  src: url('../../assets/fonts/Raleway-ExtraLight.ttf');
}
@font-face {
  font-family: 'Raleway';
  font-weight: 200;
  font-style: italic;
  src: url('../../assets/fonts/Raleway-ExtraLightItalic.ttf');
}
@font-face {
  font-family: 'Raleway';
  font-weight: 300;
  src: url('../../assets/fonts/Raleway-Light.ttf');
}
@font-face {
  font-family: 'Raleway';
  font-weight: 300;
  font-style: italic;
  src: url('../../assets/fonts/Raleway-LightItalic.ttf');
}
@font-face {
  font-family: 'Raleway';
  font-weight: 400;
  src: url('../../assets/fonts/Raleway-Regular.ttf');
}
@font-face {
  font-family: 'Raleway';
  font-weight: 400;
  font-style: italic;
  src: url('../../assets/fonts/Raleway-Italic.ttf');
}
@font-face {
  font-family: 'Raleway';
  font-weight: 500;
  src: url('../../assets/fonts/Raleway-Medium.ttf');
}
@font-face {
  font-family: 'Raleway';
  font-weight: 500;
  font-style: italic;
  src: url('../../assets/fonts/Raleway-MediumItalic.ttf');
}
@font-face {
  font-family: 'Raleway';
  font-weight: 600;
  src: url('../../assets/fonts/Raleway-SemiBold.ttf');
}
@font-face {
  font-family: 'Raleway';
  font-weight: 600;
  font-style: italic;
  src: url('../../assets/fonts/Raleway-SemiBoldItalic.ttf');
}
@font-face {
  font-family: 'Raleway';
  font-weight: 700;
  src: url('../../assets/fonts/Raleway-Bold.ttf');
}
@font-face {
  font-family: 'Raleway';
  font-weight: 700;
  font-style: italic;
  src: url('../../assets/fonts/Raleway-BoldItalic.ttf');
}
@font-face {
  font-family: 'Raleway';
  font-weight: 800;
  src: url('../../assets/fonts/Raleway-ExtraBold.ttf');
}
@font-face {
  font-family: 'Raleway';
  font-weight: 800;
  font-style: italic;
  src: url('../../assets/fonts/Raleway-ExtraBoldItalic.ttf');
}
@font-face {
  font-family: 'Raleway';
  font-weight: 900;
  src: url('../../assets/fonts/Raleway-Black.ttf');
}
@font-face {
  font-family: 'Raleway';
  font-weight: 900;
  font-style: italic;
  src: url('../../assets/fonts/Raleway-BlackItalic.ttf');
}
@font-face {
  font-family: 'Real one specific';
  src: url('../../assets/fonts/Real-One-Specific.eot');
  src: url('../../assets/fonts/Real-One-Specific.eot?#iefix') format('embedded-opentype'), url('../../assets/fonts/Real-One-Specific.woff2') format('woff2'), url('../../assets/fonts/Real-One-Specific.woff') format('woff'), url('../../assets/fonts/Real-One-Specific.otf') format('opentype'), url('../../assets/fonts/Real-One-Specific.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: 'Real one specific script';
  src: url('../../assets/fonts/Real-One-Specific-script.eot');
  src: url('../../assets/fonts/Real-One-Specific-script.eot?#iefix') format('embedded-opentype'), url('../../assets/fonts/Real-One-Specific-script.woff2') format('woff2'), url('../../assets/fonts/Real-One-Specific-script.woff') format('woff'), url('../../assets/fonts/Real-One-Specific-script.otf') format('opentype'), url('../../assets/fonts/Real-One-Specific-script.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: 'Reylight';
  src: url('../../assets/fonts/Reylight-Regular.woff2') format('woff2'), url('../../assets/fonts/Reylight-Regular.woff') format('woff'), url('../../assets/fonts/Reylight-Regular.otf') format('opentype'), url('../../assets/fonts/Reylight-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'Roberts Humter';
  src: url('../../assets/fonts/Roberts-Humter-Regular.woff2') format('woff2'), url('../../assets/fonts/Roberts-Humter-Regular.woff') format('woff'), url('../../assets/fonts/Roberts-Humter-Regular.otf') format('opentype'), url('../../assets/fonts/Roberts-Humter-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'Roboto';
  font-weight: 100;
  src: url('../../assets/fonts/Roboto-Thin.ttf');
}
@font-face {
  font-family: 'Roboto';
  font-weight: 100;
  font-style: italic;
  src: url('../../assets/fonts/Roboto-ThinItalic.ttf');
}
@font-face {
  font-family: 'Roboto';
  font-weight: 300;
  src: url('../../assets/fonts/Roboto-Light.ttf');
}
@font-face {
  font-family: 'Roboto';
  font-weight: 300;
  font-style: italic;
  src: url('../../assets/fonts/Roboto-LightItalic.ttf');
}
@font-face {
  font-family: 'Roboto';
  font-weight: 400;
  src: url('../../assets/fonts/Roboto-Regular.ttf');
}
@font-face {
  font-family: 'Roboto';
  font-weight: 400;
  font-style: italic;
  src: url('../../assets/fonts/Roboto-Italic.ttf');
}
@font-face {
  font-family: 'Roboto';
  font-weight: 500;
  src: url('../../assets/fonts/Roboto-Medium.ttf');
}
@font-face {
  font-family: 'Roboto';
  font-weight: 500;
  font-style: italic;
  src: url('../../assets/fonts/Roboto-MediumItalic.ttf');
}
@font-face {
  font-family: 'Roboto';
  font-weight: 700;
  src: url('../../assets/fonts/Roboto-Bold.ttf');
}
@font-face {
  font-family: 'Roboto';
  font-weight: 700;
  font-style: italic;
  src: url('../../assets/fonts/Roboto-BoldItalic.ttf');
}
@font-face {
  font-family: 'Roboto';
  font-weight: 900;
  src: url('../../assets/fonts/Roboto-Black.ttf');
}
@font-face {
  font-family: 'Roboto';
  font-weight: 900;
  font-style: italic;
  src: url('../../assets/fonts/Roboto-BlackItalic.ttf');
}
@font-face {
  font-family: 'Roboto Condensed';
  font-weight: 300;
  src: url('../../assets/fonts/RobotoCondensed-Light.ttf');
}
@font-face {
  font-family: 'Roboto Condensed';
  font-weight: 300;
  font-style: italic;
  src: url('../../assets/fonts/RobotoCondensed-LightItalic.ttf');
}
@font-face {
  font-family: 'Roboto Condensed';
  font-weight: 400;
  src: url('../../assets/fonts/RobotoCondensed-Regular.ttf');
}
@font-face {
  font-family: 'Roboto Condensed';
  font-weight: 400;
  font-style: italic;
  src: url('../../assets/fonts/RobotoCondensed-Italic.ttf');
}
@font-face {
  font-family: 'Roboto Condensed';
  font-weight: 700;
  src: url('../../assets/fonts/RobotoCondensed-Bold.ttf');
}
@font-face {
  font-family: 'Roboto Condensed';
  font-weight: 700;
  font-style: italic;
  src: url('../../assets/fonts/RobotoCondensed-BoldItalic.ttf');
}
@font-face {
  font-family: 'Roboto Mono';
  font-weight: 100;
  src: url('../../assets/fonts/RobotoMono-Thin.ttf');
}
@font-face {
  font-family: 'Roboto Mono';
  font-weight: 100;
  font-style: italic;
  src: url('../../assets/fonts/RobotoMono-ThinItalic.ttf');
}
@font-face {
  font-family: 'Roboto Mono';
  font-weight: 300;
  src: url('../../assets/fonts/RobotoMono-Light.ttf');
}
@font-face {
  font-family: 'Roboto Mono';
  font-weight: 300;
  font-style: italic;
  src: url('../../assets/fonts/RobotoMono-LightItalic.ttf');
}
@font-face {
  font-family: 'Roboto Mono';
  font-weight: 400;
  src: url('../../assets/fonts/RobotoMono-Regular.ttf');
}
@font-face {
  font-family: 'Roboto Mono';
  font-weight: 400;
  font-style: italic;
  src: url('../../assets/fonts/RobotoMono-Italic.ttf');
}
@font-face {
  font-family: 'Roboto Mono';
  font-weight: 500;
  src: url('../../assets/fonts/RobotoMono-Medium.ttf');
}
@font-face {
  font-family: 'Roboto Mono';
  font-weight: 500;
  font-style: italic;
  src: url('../../assets/fonts/RobotoMono-MediumItalic.ttf');
}
@font-face {
  font-family: 'Roboto Mono';
  font-weight: 700;
  src: url('../../assets/fonts/RobotoMono-Bold.ttf');
}
@font-face {
  font-family: 'Roboto Mono';
  font-weight: 700;
  font-style: italic;
  src: url('../../assets/fonts/RobotoMono-BoldItalic.ttf');
}
@font-face {
  font-family: 'Roboto Slab';
  font-weight: 100;
  src: url('../../assets/fonts/RobotoSlab-Thin.ttf');
}
@font-face {
  font-family: 'Roboto Slab';
  font-weight: 300;
  src: url('../../assets/fonts/RobotoSlab-Light.ttf');
}
@font-face {
  font-family: 'Roboto Slab';
  font-weight: 400;
  src: url('../../assets/fonts/RobotoSlab-Regular.ttf');
}
@font-face {
  font-family: 'Roboto Slab';
  font-weight: 700;
  src: url('../../assets/fonts/RobotoSlab-Bold.ttf');
}
@font-face {
  font-family: 'Rollo';
  src: url('../../assets/fonts/Rollo.eot');
  src: url('../../assets/fonts/Rollo.eot?#iefix') format('embedded-opentype'), url('../../assets/fonts/Rollo.woff2') format('woff2'), url('../../assets/fonts/Rollo.woff') format('woff'), url('../../assets/fonts/Rollo.otf') format('opentype'), url('../../assets/fonts/Rollo.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: 'Romantic Couple';
  font-weight: 400;
  font-style: normal;
  src: url('../../assets/fonts/Romantic-Couple.eot');
  src: url('../../assets/fonts/Romantic-Couple.eot?#iefix') format('embedded-opentype'), url('../../assets/fonts/Romantic-Couple.woff') format('woff'), url('../../assets/fonts/Romantic-Couple.woff2') format('woff2'), url('../../assets/fonts/Romantic-Couple.ttf') format('truetype');
}
@font-face {
  font-family: 'Romantic Couple Script';
  font-weight: 400;
  font-style: normal;
  src: url('../../assets/fonts/Romantic-Couple-Script.eot');
  src: url('../../assets/fonts/Romantic-Couple-Script.eot?#iefix') format('embedded-opentype'), url('../../assets/fonts/Romantic-Couple-Script.woff') format('woff'), url('../../assets/fonts/Romantic-Couple-Script.woff2') format('woff2'), url('../../assets/fonts/Romantic-Couple-Script.ttf') format('truetype');
}
@font-face {
  font-family: 'Rouge Script';
  font-weight: 400;
  src: url('../../assets/fonts/RougeScript-Regular.ttf');
}
@font-face {
  font-family: 'Rozha One';
  font-weight: 400;
  src: url('../../assets/fonts/RozhaOne-Regular.ttf');
}
@font-face {
  font-family: 'Rushfor';
  src: url('../../assets/fonts/Rushfor.eot');
  src: url('../../assets/fonts/Rushfor.eot?#iefix') format('embedded-opentype'), url('../../assets/fonts/Rushfor.woff2') format('woff2'), url('../../assets/fonts/Rushfor.woff') format('woff'), url('../../assets/fonts/Rushfor.otf') format('opentype'), url('../../assets/fonts/Rushfor.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: 'Rye';
  font-weight: 400;
  src: url('../../assets/fonts/Rye-Regular.ttf');
}
@font-face {
  font-family: 'Salma Alianda Script';
  font-weight: 400;
  src: url('../../assets/fonts/SalmaAliandaScript-Regular.woff') format('woff'), url('../../assets/fonts/SalmaAliandaScript-Regular.eot') format('eot'), url('../../assets/fonts/SalmaAliandaScript-Regular.ttf') format('truetype');
}
@font-face {
  font-family: 'Sancreek';
  font-weight: 400;
  src: url('../../assets/fonts/Sancreek-Regular.ttf');
}
@font-face {
  font-family: 'Santana';
  src: url('../../assets/fonts/Santana.eot');
  src: url('../../assets/fonts/Santana.eot?#iefix') format('embedded-opentype'), url('../../assets/fonts/Santana.otf') format("opentype"), url('../../assets/fonts/Santana.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: 'Savannah (TT)';
  src: url('../../assets/fonts/savannah-regular.woff2') format('woff2'), url('../../assets/fonts/savannah-regular.woff') format('woff'), url('../../assets/fonts/savannah-regular.otf') format('opentype'), url('../../assets/fonts/savannah-regular.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: 'Savannah Alt';
  src: url('../../assets/fonts/Savannah-Alt-Regular.woff2') format('woff2'), url('../../assets/fonts/Savannah-Alt-Regular.woff') format('woff'), url('../../assets/fonts/Savannah-Alt-Regular.otf') format('opentype'), url('../../assets/fonts/Savannah-Alt-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'Serendipity';
  font-weight: 400;
  src: url('../../assets/fonts/Serendipity.eot');
  src: url('../../assets/fonts/Serendipity.eot?#iefix') format('embedded-opentype'), url('../../assets/fonts/Serendipity.ttf') format('truetype');
}
@font-face {
  font-family: 'Serendipity One';
  font-weight: 400;
  src: url('../../assets/fonts/SerendipityOne-Regular.ttf');
}
@font-face {
  font-family: 'Serendipity Wide One';
  font-weight: 400;
  src: url('../../assets/fonts/SerendipityOneWide-Regular.ttf');
}
@font-face {
  font-family: 'Serendipity Two';
  font-weight: 400;
  src: url('../../assets/fonts/SerendipityTwo-Regular.ttf');
}
@font-face {
  font-family: 'Serendipity Wide Two';
  font-weight: 400;
  src: url('../../assets/fonts/SerendipityTwoWide-Regular.ttf');
}
@font-face {
  font-family: 'Serendipity Three';
  font-weight: 400;
  src: url('../../assets/fonts/SerendipityThree-Regular.ttf');
}
@font-face {
  font-family: 'Serendipity Wide Three';
  font-weight: 400;
  src: url('../../assets/fonts/SerendipityThreeWide-Regular.ttf');
}
@font-face {
  font-family: 'Serendipity Ligatures';
  font-weight: 400;
  src: url('../../assets/fonts/SerendipityLigatures-Regular.ttf');
}
@font-face {
  font-family: 'Serendipity Wide Ligatures';
  font-weight: 400;
  src: url('../../assets/fonts/SerendipityLigaturesWide-Regular.ttf');
}
@font-face {
  font-family: 'Serendipity Wide';
  font-weight: 400;
  src: url('../../assets/fonts/Serendipity_Wide.woff') format('woff'), url('../../assets/fonts/Serendipity-Wide.ttf');
}
@font-face {
  font-family: 'Serenity';
  font-weight: 400;
  src: url('../../assets/fonts/Serenity.ttf');
}
@font-face {
  font-family: 'Serenity';
  font-weight: 700;
  src: url('../../assets/fonts/SerenityBold.ttf');
}
@font-face {
  font-family: 'Smokum';
  font-weight: 400;
  src: url('../../assets/fonts/Smokum-Regular.ttf');
}
@font-face {
  font-family: 'Sorts Mill Goudy';
  font-weight: 400;
  src: url('../../assets/fonts/SortsMillGoudy-Regular.ttf');
}
@font-face {
  font-family: 'Sorts Mill Goudy';
  font-weight: 400;
  font-style: italic;
  src: url('../../assets/fonts/SortsMillGoudy-Italic.ttf');
}
@font-face {
  font-family: 'Source Sans Pro';
  src: url('../../assets/fonts/SourceSansPro-ExtraLight.woff2') format('woff2'), url('../../assets/fonts/SourceSansPro-ExtraLight.woff') format('woff'), url('../../assets/fonts/SourceSansPro-ExtraLight.ttf') format('truetype');
  font-weight: 200;
  font-style: normal;
}
@font-face {
  font-family: 'Source Sans Pro';
  src: url('../../assets/fonts/SourceSansPro-ExtraLightItalic.woff2') format('woff2'), url('../../assets/fonts/SourceSansPro-ExtraLightItalic.woff') format('woff'), url('../../assets/fonts/SourceSansPro-ExtraLightItalic.ttf') format('truetype');
  font-weight: 200;
  font-style: italic;
}
@font-face {
  font-family: 'Source Sans Pro';
  src: url('../../assets/fonts/SourceSansPro-Light.woff2') format('woff2'), url('../../assets/fonts/SourceSansPro-Light.woff') format('woff'), url('../../assets/fonts/SourceSansPro-Light.ttf') format('truetype');
  font-weight: 300;
  font-style: normal;
}
@font-face {
  font-family: 'Source Sans Pro';
  src: url('../../assets/fonts/SourceSansPro-LightItalic.woff2') format('woff2'), url('../../assets/fonts/SourceSansPro-LightItalic.woff') format('woff'), url('../../assets/fonts/SourceSansPro-LightItalic.ttf') format('truetype');
  font-weight: 300;
  font-style: italic;
}
@font-face {
  font-family: 'Source Sans Pro';
  src: url('../../assets/fonts/SourceSansPro-Regular.woff2') format('woff2'), url('../../assets/fonts/SourceSansPro-Regular.woff') format('woff'), url('../../assets/fonts/SourceSansPro-Regular.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: 'Source Sans Pro';
  src: url('../../assets/fonts/SourceSansPro-RegularItalic.woff2') format('woff2'), url('../../assets/fonts/SourceSansPro-RegularItalic.woff') format('woff'), url('../../assets/fonts/SourceSansPro-RegularItalic.ttf') format('truetype');
  font-weight: 400;
  font-style: italic;
}
@font-face {
  font-family: 'Source Sans Pro';
  src: url('../../assets/fonts/SourceSansPro-SemiBold.woff2') format('woff2'), url('../../assets/fonts/SourceSansPro-SemiBold.woff') format('woff'), url('../../assets/fonts/SourceSansPro-SemiBold.ttf') format('truetype');
  font-weight: 600;
  font-style: normal;
}
@font-face {
  font-family: 'Source Sans Pro';
  src: url('../../assets/fonts/SourceSansPro-SemiBoldItalic.woff2') format('woff2'), url('../../assets/fonts/SourceSansPro-SemiBoldItalic.woff') format('woff'), url('../../assets/fonts/SourceSansPro-SemiBoldItalic.ttf') format('truetype');
  font-weight: 600;
  font-style: italic;
}
@font-face {
  font-family: 'Source Sans Pro';
  src: url('../../assets/fonts/SourceSansPro-Bold.woff2') format('woff2'), url('../../assets/fonts/SourceSansPro-Bold.woff') format('woff'), url('../../assets/fonts/SourceSansPro-Bold.ttf') format('truetype');
  font-weight: 700;
  font-style: normal;
}
@font-face {
  font-family: 'Source Sans Pro';
  src: url('../../assets/fonts/SourceSansPro-BoldItalic.woff2') format('woff2'), url('../../assets/fonts/SourceSansPro-BoldItalic.woff') format('woff'), url('../../assets/fonts/SourceSansPro-BoldItalic.ttf') format('truetype');
  font-weight: 700;
  font-style: italic;
}
@font-face {
  font-family: 'Source Sans Pro';
  src: url('../../assets/fonts/SourceSansPro-Black.woff2') format('woff2'), url('../../assets/fonts/SourceSansPro-Black.woff') format('woff'), url('../../assets/fonts/SourceSansPro-Black.ttf') format('truetype');
  font-weight: 900;
  font-style: normal;
}
@font-face {
  font-family: 'Source Sans Pro';
  src: url('../../assets/fonts/SourceSansPro-BlackItalic.woff2') format('woff2'), url('../../assets/fonts/SourceSansPro-BlackItalic.woff') format('woff'), url('../../assets/fonts/SourceSansPro-BlackItalic.ttf') format('truetype');
  font-weight: 900;
  font-style: italic;
}
@font-face {
  font-family: 'Stint Ultra Condensed';
  font-weight: 400;
  src: url('../../assets/fonts/StintUltraCondensed-Regular.ttf');
}
@font-face {
  font-family: 'Style Clubs';
  src: url('../../assets/fonts//Style-Clubs-Serif.eot');
  src: url('../../assets/fonts//Style-Clubs-Serif.eot?#iefix') format('embedded-opentype'), url('../../assets/fonts//Style-Clubs-Serif.woff2') format('woff2'), url('../../assets/fonts//Style-Clubs-Serif.woff') format('woff'), url('../../assets/fonts//Style-Clubs-Serif.otf') format('opentype'), url('../../assets/fonts//Style-Clubs-Serif.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: 'Style Clubs';
  src: url('../../assets/fonts//Style-Clubs-Serif-Italic.eot');
  src: url('../../assets/fonts//Style-Clubs-Serif-Italic.eot?#iefix') format('embedded-opentype'), url('../../assets/fonts//Style-Clubs-Serif-Italic.woff2') format('woff2'), url('../../assets/fonts//Style-Clubs-Serif-Italic.woff') format('woff'), url('../../assets/fonts//Style-Clubs-Serif-Italic.svg') format('svg'), url('../../assets/fonts//Style-Clubs-Serif-Italic.ttf') format('truetype');
  font-weight: 400;
  font-style: italic;
}
@font-face {
  font-family: 'Stylish Calligraphy';
  font-weight: 400;
  src: url('../../assets/fonts/StylishCalligraphy.ttf');
}
@font-face {
  font-family: 'Tangerine';
  font-weight: 400;
  src: url('../../assets/fonts/Tangerine-Regular.ttf');
}
@font-face {
  font-family: 'Tangerine';
  font-weight: 700;
  src: url('../../assets/fonts/Tangerine-Bold.ttf');
}
@font-face {
  font-family: 'Thirsk Carved';
  src: url('../../assets/fonts/Thirsk-Carved-Font-by-TTF.eot');
  src: url('../../assets/fonts/Thirsk-Carved-Font-by-TTF.eot?#iefix') format('embedded-opentype'), url('../../assets/fonts/Thirsk-Carved-Font-by-TTF.woff2') format('woff2'), url('../../assets/fonts/Thirsk-Carved-Font-by-TTF.woff') format('woff'), url('../../assets/fonts/Thirsk-Carved-Font-by-TTF.otf') format('opentype'), url('../../assets/fonts/Thirsk-Carved-Font-by-TTF.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: 'Thirsk Carved';
  src: url('../../assets/fonts/Thirsk-Carved-Italic-by-TTF.eot');
  src: url('../../assets/fonts/Thirsk-Carved-Italic-by-TTF.eot?#iefix') format('embedded-opentype'), url('../../assets/fonts/Thirsk-Carved-Italic-by-TTF.woff2') format('woff2'), url('../../assets/fonts/Thirsk-Carved-Italic-by-TTF.woff') format('woff'), url('../../assets/fonts/Thirsk-Carved-Italic-by-TTF.otf') format('opentype'), url('../../assets/fonts/Thirsk-Carved-Italic-by-TTF.ttf') format('truetype');
  font-weight: 400;
  font-style: italic;
}
@font-face {
  font-family: 'Trend Sans One';
  font-weight: 400;
  src: url('../../assets/fonts/TrendSans-One-Regular.otf');
}
@font-face {
  font-family: 'Trend Sans Five';
  font-weight: 400;
  src: url('../../assets/fonts/TrendSans-Five-Regular.otf');
}
@font-face {
  font-family: 'Ultra';
  font-weight: 400;
  src: url('../../assets/fonts/Ultra-Regular.ttf');
}
@font-face {
  font-family: 'Unna';
  font-weight: 400;
  src: url('../../assets/fonts/Unna-Regular.ttf');
}
@font-face {
  font-family: 'Unna';
  font-weight: 400;
  font-style: italic;
  src: url('../../assets/fonts/Unna-Italic.ttf');
}
@font-face {
  font-family: 'Unna';
  font-weight: 700;
  src: url('../../assets/fonts/Unna-Bold.ttf');
}
@font-face {
  font-family: 'Unna';
  font-weight: 700;
  font-style: italic;
  src: url('../../assets/fonts/Unna-BoldItalic.ttf');
}
@font-face {
  font-family: 'vLetter Dakota2';
  font-weight: 400;
  src: url('../../assets/fonts/Unna-Bold.ttf');
}
@font-face {
  font-family: 'Voces';
  font-weight: 400;
  src: url('../../assets/fonts/Voces-Regular.ttf');
}
@font-face {
  font-family: 'Wallace';
  src: url('../../assets/fonts/Wallace.eot');
  src: url('../../assets/fonts/Wallace.eot?#iefix') format('embedded-opentype'), url('../../assets/fonts/Wallace.woff2') format('woff2'), url('../../assets/fonts/Wallace.woff') format('woff'), url('../../assets/fonts/Wallace.otf') format('opentype'), url('../../assets/fonts/Wallace.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: 'Weisston';
  src: url('../../assets/fonts/Weisston-Script.eot');
  src: url('../../assets/fonts/Weisston-Script.eot?#iefix') format('embedded-opentype'), url('../../assets/fonts/Weisston-Script.woff2') format('woff2'), url('../../assets/fonts/Weisston-Script.woff') format('woff'), url('../../assets/fonts/Weisston-Script.otf') format('opentype'), url('../../assets/fonts/Weisston-Script.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: 'Wondar Quason';
  src: url('../../assets/fonts/Wondar-Quason.eot');
  src: url('../../assets/fonts/Wondar-Quason.eot?#iefix') format('embedded-opentype'), url('../../assets/fonts/Wondar-Quason.woff2') format('woff2'), url('../../assets/fonts/Wondar-Quason.woff') format('woff'), url('../../assets/fonts/Wondar-Quason.otf') format('opentype'), url('../../assets/fonts/Wondar-Quason.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: 'Work Sans';
  font-weight: 100;
  src: url('../../assets/fonts/vLetterDakota2-Regular.otf');
}
@font-face {
  font-family: 'Work Sans';
  font-weight: 200;
  src: url('../../assets/fonts/WorkSans-ExtraLight.ttf');
}
@font-face {
  font-family: 'Work Sans';
  font-weight: 300;
  src: url('../../assets/fonts/WorkSans-Light.ttf');
}
@font-face {
  font-family: 'Work Sans';
  font-weight: 400;
  src: url('../../assets/fonts/WorkSans-Regular.ttf');
}
@font-face {
  font-family: 'Work Sans';
  font-weight: 500;
  src: url('../../assets/fonts/WorkSans-Medium.ttf');
}
@font-face {
  font-family: 'Work Sans';
  font-weight: 600;
  src: url('../../assets/fonts/WorkSans-SemiBold.ttf');
}
@font-face {
  font-family: 'Work Sans';
  font-weight: 700;
  src: url('../../assets/fonts/WorkSans-Bold.ttf');
}
@font-face {
  font-family: 'Work Sans';
  font-weight: 800;
  src: url('../../assets/fonts/WorkSans-ExtraBold.ttf');
}
@font-face {
  font-family: 'Work Sans';
  font-weight: 900;
  src: url('../../assets/fonts/WorkSans-Black.ttf');
}
@font-face {
  font-family: 'Yellowtail';
  font-weight: 400;
  src: url('../../assets/fonts/Yellowtail-Regular.ttf');
}
@font-face {
  font-family: 'Zilla Slab';
  font-weight: 300;
  src: url('../../assets/fonts/ZillaSlab-Light.eot');
  src: url('../../assets/fonts/ZillaSlab-Light.eot?#iefix') format('embedded-opentype'), url('../../assets/fonts/ZillaSlab-Light.ttf') format('truetype');
}
@font-face {
  font-family: 'Zilla Slab';
  font-weight: 300;
  font-style: italic;
  src: url('../../assets/fonts/ZillaSlab-LightItalic.eot');
  src: url('../../assets/fonts/ZillaSlab-LightItalic.eot?#iefix') format('embedded-opentype'), url('../../assets/fonts/ZillaSlab-LightItalic.ttf') format('truetype');
}
@font-face {
  font-family: 'Zilla Slab';
  font-weight: 400;
  src: url('../../assets/fonts/ZillaSlab-Regular.eot');
  src: url('../../assets/fonts/ZillaSlab-Regular.eot?#iefix') format('embedded-opentype'), url('../../assets/fonts/ZillaSlab-Regular.ttf') format('truetype');
}
@font-face {
  font-family: 'Zilla Slab';
  font-weight: 400;
  font-style: italic;
  src: url('../../assets/fonts/ZillaSlab-Italic.eot');
  src: url('../../assets/fonts/ZillaSlab-Italic.eot?#iefix') format('embedded-opentype'), url('../../assets/fonts/ZillaSlab-Italic.ttf') format('truetype');
}
@font-face {
  font-family: 'Zilla Slab';
  font-weight: 500;
  src: url('../../assets/fonts/ZillaSlab-Medium.eot');
  src: url('../../assets/fonts/ZillaSlab-Medium.eot?#iefix') format('embedded-opentype'), url('../../assets/fonts/ZillaSlab-Medium.ttf') format('truetype');
}
@font-face {
  font-family: 'Zilla Slab';
  font-weight: 500;
  font-style: italic;
  src: url('../../assets/fonts/ZillaSlab-MediumItalic.eot');
  src: url('../../assets/fonts/ZillaSlab-MediumItalic.eot?#iefix') format('embedded-opentype'), url('../../assets/fonts/ZillaSlab-MediumItalic.ttf') format('truetype');
}
@font-face {
  font-family: 'Zilla Slab';
  font-weight: 600;
  src: url('../../assets/fonts/ZillaSlab-SemiBold.eot');
  src: url('../../assets/fonts/ZillaSlab-SemiBold.eot?#iefix') format('embedded-opentype'), url('../../assets/fonts/ZillaSlab-SemiBold.ttf') format('truetype');
}
@font-face {
  font-family: 'Zilla Slab';
  font-weight: 600;
  font-style: italic;
  src: url('../../assets/fonts/ZillaSlab-SemiBoldItalic.eot');
  src: url('../../assets/fonts/ZillaSlab-SemiBoldItalic.eot?#iefix') format('embedded-opentype'), url('../../assets/fonts/ZillaSlab-SemiBoldItalic.ttf') format('truetype');
}
@font-face {
  font-family: 'Zilla Slab';
  font-weight: 700;
  src: url('../../assets/fonts/ZillaSlab-Bold.eot');
  src: url('../../assets/fonts/ZillaSlab-Bold.eot?#iefix') format('embedded-opentype'), url('../../assets/fonts/ZillaSlab-Bold.ttf') format('truetype');
}
@font-face {
  font-family: 'Zilla Slab';
  font-weight: 700;
  font-style: italic;
  src: url('../../assets/fonts/ZillaSlab-BoldItalic.eot');
  src: url('../../assets/fonts/ZillaSlab-BoldItalic.eot?#iefix') format('embedded-opentype'), url('../../assets/fonts/ZillaSlab-BoldItalic.ttf') format('truetype');
}
