.container {
  display: flex;
  flex-direction: column;
  padding: 40px;
}
.title {
  font-family: 'circular', serif;
  font-size: 24px;
  font-weight: 600;
  line-height: 1.2em;
  margin-top: 32px;
}
.subtitle {
  color: #505050;
  font-family: 'circular', serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 1.5em;
  margin: 12px 0 32px;
}
.body {
  border-top: 1px solid #d9d9d9;
}
.footer {
  padding-top: 40px;
  text-align: right;
}
