.mobileListingNavigation {
  align-items: stretch;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  -webkit-overflow-scrolling: touch;
  overflow-x: auto;
  overflow-y: hidden;
  padding: 0 10px 12px;
}
.navigationItem {
  align-items: center;
  background-color: #ffffff;
  border: 2px solid #d9d9d9;
  border-radius: 4px;
  display: flex;
  flex: 1 0 auto;
  flex-direction: row;
  height: 44px;
  justify-content: center;
  padding: 12px 14px;
  position: relative;
}
.navigationItem:not(:first-of-type) {
  margin-left: 5px;
}
.navigationItem:not(:last-of-type) {
  margin-right: 5px;
}
.navigationItem > span {
  color: #505050;
  font-size: 16px;
  font-weight: 400;
  line-height: 1;
}
.navigationItemActive {
  background-color: #ebf4f9;
  border-color: #0075ae;
}
.navigationItemActive > span {
  color: #21201f;
  font-weight: 400;
}
.newTag {
  margin-left: 4px;
}
.navigationItemIcon {
  display: inline-block;
  margin: 0 5px 1px 0;
  width: 18px;
}
