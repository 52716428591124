/**
 * @prettier
 */
@media screen and (max-width: 767px) {
}
@media (min-width: 768px) {
}
@media (min-width: 768px) {
}
@media (max-width: 767px) {
}
@media (min-width: 768px) {
}
@media (min-width: 768px) {
}
@media (max-width: 767px) {
}
@media (max-width: 767px) {
}
.largerWhiteBorder {
  background-color: #fff;
  border-radius: 4px;
  box-shadow: none;
  margin-bottom: 12px;
  padding: 25px;
}
.productName {
  margin-right: 8px;
}
.productNameSection {
  align-items: center;
  display: inline-flex;
  flex-wrap: wrap;
  justify-content: flex-start;
}
.selectedSample {
  border: 2px solid #0075ae;
}
:global(.card-listing .listing-detail) :local .singleSampleLinkBox {
  visibility: hidden;
}
@media (max-width: 991px) {
  :global(.card-listing .listing-detail) :local .singleSampleLinkBox {
    visibility: visible;
  }
}
:global(.card-listing .listing-detail):hover :local .singleSampleLinkBox {
  visibility: visible;
}
:global(.card-listing .listing-detail):hover .dropShadow {
  box-shadow: 0 6px 10px rgba(0, 0, 0, 0.2);
}
:global(.card-listing .listing-detail):hover .productName {
  color: #0075ae !important;
}
:local :global(.card-listing .listing-detail).disabledSampleTile {
  opacity: 0.5;
}
:local :global(.card-listing .listing-detail).leftAlign {
  text-align: left;
}
:local :global(.card-listing .listing-detail).leftAlign a {
  text-decoration: none;
}
:local :global(.card-listing .listing-detail).leftAlign :global(.collaborator-link) {
  text-decoration: none;
}
:local :global(.card-listing .listing-detail).leftAlign :global(.collaborator-link):hover {
  text-decoration: none;
}
:local :global(.card-listing .listing-detail).leftAlign :global(.details .product-name) {
  font-size: 16px;
  font-weight: 400;
}
:local :global(.card-listing .listing-detail).leftAlign .dropShadow {
  box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.1);
}
.tag {
  margin: 10px 0;
}
.tagCollaborator {
  font-weight: 400;
  text-transform: none;
}
.tileLinkBox {
  position: relative;
}
.multiSampleCheckbox {
  margin: 20px;
  position: absolute;
  top: 0;
}
.iconWrapper {
  width: 44px;
  height: 26px;
  display: flex;
}
