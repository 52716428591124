/* stylelint-disable function-calc-no-invalid */
.modalContainer {
  min-width: 100%;
  overflow: hidden;
}
.modalTitle {
  color: #21201f;
  font-family: 'circular', serif;
  font-size: 20px;
  font-weight: 400;
  line-height: 23px;
  margin-bottom: 8px;
  margin-top: 16px;
  text-align: center;
}
.modalPicture {
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 200px;
  width: 100%;
}
.modalDescription {
  color: #505050;
  font-family: 'circular', serif;
  font-size: 16px;
  line-height: 24px;
  padding: 20px;
}
.modalAction {
  padding: 0 20px 20px 20px;
  text-align: right;
}
.btnImage {
  align-items: center;
  background-color: #fff;
  border: 2px solid #d9d9d9;
  border-radius: 4px;
  box-sizing: border-box;
  color: #505050;
  display: flex;
  flex-basis: 0;
  flex-direction: column;
  flex-grow: 1;
  font-family: 'circular', serif;
  font-size: 16px;
  justify-content: center;
  line-height: 20px;
  padding: 12px;
  text-align: center;
}
.btnImage:first-of-type {
  margin-right: 16px;
}
.btnImage:hover {
  background-color: #fcfcfc;
}
.btnImageActive {
  background-color: #ebf4f9;
  border: 2px solid #0075ae;
  color: #21201f;
}
.btnImageActive:hover {
  background-color: #e1ebf1;
}
.btnImageLabel {
  font-weight: 400;
  margin-top: 6px;
}
.btnImageLabelActive {
  font-weight: 400;
}
.btnImageIcon {
  font-size: 32px;
  height: 32px;
  margin: 0 auto;
  margin-bottom: 8px;
  width: 32px;
}
@media (max-width: 991px) {
  .modalActionButton {
    width: 100% !important;
  }
}
.closeBtn {
  background-color: transparent !important;
  border-color: transparent !important;
  box-shadow: none !important;
}
.closeBtn:hover {
  background-color: transparent !important;
}
.closeBtn:focus {
  outline: 0;
}
@media (min-width: 768px) {
  .closeBtn {
    position: absolute;
    right: 0;
  }
}
.drawerHeader {
  color: #21201f;
  display: block;
  flex-grow: 1;
  font-family: 'circular', serif;
  font-size: 20px;
  font-weight: 400;
  left: 0;
  line-height: 23px;
  text-align: center;
}
.tempSideDrawer .sideDrawerHeaderContainer {
  background-color: #ffffff;
  border-bottom: 1px solid #d9d9d9;
  height: 64px;
  position: fixed;
  width: 420px;
  z-index: inherit;
}
@media (max-width: 767px) {
  .tempSideDrawer .sideDrawerHeaderContainer {
    background-color: #FCFCFC;
    height: 54px;
    max-width: 100%;
  }
}
.tempSideDrawer .sideDrawerHeaderContainer.standard {
  align-items: center;
  display: flex;
  justify-content: space-evenly;
  text-align: center;
}
.tempSideDrawer .sideDrawerHeaderContainer :global(.zola-ui-icon-close) {
  color: #767676;
  font-size: 20px;
  left: auto;
  position: absolute;
  right: 12px;
}
.tempSideDrawer .sideDrawerHeaderContainer :global(.zola-ui-icon-close):hover {
  cursor: pointer;
}
.tempSideDrawer .sideDrawerHeaderContainer :global(.side-drawer-header) {
  display: inline-block;
  text-align: left;
}
@keyframes slideIn {
  0% {
    width: 0;
  }
  100% {
    width: 420px;
  }
  @media (max-width: 767px) {
    100% {
      width: 100%;
    }
  }
}
@keyframes slideOut {
  0% {
    width: 420px;
  }
  @media (max-width: 767px) {
    0% {
      width: 100%;
    }
  }
  100% {
    width: 0;
  }
}
.tempSideDrawer .sideDrawerContainer .sideDrawerBackgroundOverlay {
  background-color: #21201f;
  height: 100%;
  opacity: 0.5;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 999;
}
.tempSideDrawer .sideDrawerContainer .sideDrawerBody {
  background-color: #ffffff;
  display: none;
  height: 100%;
  position: fixed;
  right: 0;
  top: 0;
  width: 0;
  z-index: 1000;
}
.tempSideDrawer .sideDrawerContainer .sideDrawerBody .sideDrawerContent {
  animation-duration: 0s;
  height: 100%;
  margin-top: 60px;
  overflow-y: scroll;
  width: 420px;
}
@media (max-width: 767px) {
  .tempSideDrawer .sideDrawerContainer .sideDrawerBody .sideDrawerContent {
    max-width: 100%;
    width: 100%;
  }
}
.tempSideDrawer .sideDrawerContainer .sideDrawerBody.sideDrawerOpening {
  animation-duration: 0.2s;
  animation-fill-mode: forwards;
  animation-name: slideIn;
  display: block;
  transition-timing-function: ease-in-out;
  width: 420px;
}
@media (max-width: 767px) {
  .tempSideDrawer .sideDrawerContainer .sideDrawerBody.sideDrawerOpening {
    animation-duration: 0s;
    max-width: 100%;
    width: 100%;
  }
}
.tempSideDrawer .sideDrawerContainer .sideDrawerBody.sideDrawerClosing {
  animation-duration: 0.2s;
  animation-fill-mode: forwards;
  animation-name: slideOut;
  display: block;
  transition-timing-function: ease-in-out;
  width: 420px;
}
@media (max-width: 767px) {
  .tempSideDrawer .sideDrawerContainer .sideDrawerBody.sideDrawerClosing {
    display: none;
  }
}
.shapesIcon {
  background-color: unset;
  box-shadow: none;
  height: 40px;
  justify-content: center;
  min-width: unset;
  padding: 5px;
  width: 34px;
}
.shapesIcon:hover {
  background-color: unset;
  border-radius: 4px;
  box-shadow: inset 0 0 0 2px #d9d9d9;
}
.shapesIconActive {
  background-color: unset;
  border-radius: 4px;
  box-shadow: inset 0 0 0 2px #505050;
  cursor: unset;
  height: 40px;
  justify-content: center;
  min-width: unset;
  padding: 5px;
  width: 34px;
}
.shapesIconWrap {
  background-color: #fff;
  border: none;
  display: block;
  margin: 0 auto;
}
.shapesOption {
  float: left;
  margin-bottom: 10px;
  padding: 8px;
  width: 33.33%;
}
