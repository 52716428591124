/* stylelint-disable declaration-no-important */
/* stylelint-disable function-calc-no-invalid */
.modalContainer {
  min-width: 100vw;
  overflow-x: hidden;
  overflow-y: scroll;
}
.modalHeader {
  align-items: center;
  background-color: #fcfcfc;
  border-bottom: 1px solid #d9d9d9;
  display: flex;
  height: 54px;
  justify-content: space-evenly;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 1;
}
.modalTitle {
  color: #21201f;
  display: block;
  flex-grow: 1;
  font-family: 'circular', serif;
  font-size: 20px;
  font-weight: 400;
  left: 0;
  line-height: 23px;
  text-align: center;
}
.modalPicture {
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 200px;
  width: 100%;
}
.modalDescription {
  color: #505050;
  font-family: 'circular', serif;
  font-size: 16px;
  line-height: 24px;
  margin-bottom: 92px;
  margin-top: 54px;
  padding: 20px;
}
@media (max-width: 991px) {
  .modalDescription {
    padding-top: 28px;
  }
}
.modalAction {
  background-color: #ffffff;
  bottom: 0;
  box-shadow: 0 -1px 6px rgba(0, 0, 0, 0.1);
  padding: 20px 20px 20px 20px;
  position: absolute;
  text-align: center;
  width: 100%;
}
.applyBtn {
  min-width: 100%;
}
.clearBtn {
  background-color: transparent !important;
  border-color: transparent !important;
  box-shadow: none !important;
  color: #505050 !important;
}
.clearBtn:hover {
  background-color: #eee !important;
}
.clearBtn:focus {
  outline: 0;
}
.clearBtnDisabled {
  background-color: transparent !important;
  color: #d9d9d9 !important;
}
.clearBtnDisabled:hover {
  background-color: transparent !important;
}
.closeBtn {
  background-color: transparent !important;
  border-color: transparent !important;
  box-shadow: none !important;
}
.closeBtn:hover {
  background-color: transparent !important;
}
.closeBtn:focus {
  outline: 0;
}
@media (min-width: 768px) and (max-width: 991px) {
  .plpRedesignModalContainer {
    margin-top: -100px;
    min-width: 0;
  }
}
